import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';



const Container = styled.div`
display:flex;
width:90%;
position:relative;
display:flex;
justify-content:space-between;
padding-left:20px;
border-radius: 5px;
background:white;
justify-content:space-between;
// border: 1.6px solid #a6a6a6;
border: 1px solid #a6a6a6;
height:52px;
font-size: clamp(0.8rem, 0.29vw + 0.66rem, 1rem);
&:hover {
    cursor:pointer
}
& .container-code-with-flag{
    display:flex;
    gap:10px;
    justify-content:center;
    max-width:58px;
    flex:1;
}
& .arrow{
    width:18px;
    display: flex;
    align-items: center;
    justify-content:end;
}
@media(max-width:400px){
    padding-left:5px;
    & svg{
        width:18px
    }
}

`
function CustomInput({returnedCode,style}) {
    const [flag ,setFlag] = useState(<svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path fill="#D52B1E" d="M4 5C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h6V5H4zm28 0h-6v26h6c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"/><path fill="#EEE" d="M10 5h16v26H10z"/><path fill="#D52B1E" d="M18.615 22.113c1.198.139 2.272.264 3.469.401l-.305-1.002c-.049-.176.021-.368.159-.476l3.479-2.834-.72-.339c-.317-.113-.23-.292-.115-.722l.531-1.936-2.021.427c-.197.03-.328-.095-.358-.215l-.261-.911-1.598 1.794c-.227.288-.687.288-.544-.376l.683-3.634-.917.475c-.257.144-.514.168-.657-.089l-1.265-2.366v.059-.059l-1.265 2.366c-.144.257-.401.233-.658.089l-.916-.475.683 3.634c.144.664-.317.664-.544.376l-1.598-1.793-.26.911c-.03.12-.162.245-.359.215l-2.021-.427.531 1.936c.113.43.201.609-.116.722l-.72.339 3.479 2.834c.138.107.208.3.158.476l-.305 1.002 3.47-.401c.106 0 .176.059.175.181l-.214 3.704h.956l-.213-3.704c.002-.123.071-.182.177-.182z"/></svg>)
    const [code ,setCode] = useState("+1")
    const [isDropdown,setIsDropdown] = useState(false)
    const [view,setView] = useState(false)
    useEffect(()=>{
        returnedCode("+1")
    },[])
    const ref_div = useRef(null)
    const data= [{
        code:"+963",
        label:"Syria",
        flag:<svg width="22"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path fill="#EEE" d="M0 13h36v10H0z"/><path fill="#CE1126" d="M32 5H4C1.791 5 0 6.791 0 9v4h36V9c0-2.209-1.791-4-4-4z"/><path fill="#141414" d="M32 31H4c-2.209 0-4-1.791-4-4v-4h36v4c0 2.209-1.791 4-4 4z"/><path d="M9.177 18.332l-.633 1.947 1.656-1.203 1.656 1.203-.633-1.947 1.656-1.202h-2.047l-.632-1.947-.632 1.947H7.521zm15.6 0l-.633 1.947 1.656-1.203 1.656 1.203-.633-1.947 1.656-1.202h-2.047l-.632-1.947-.632 1.947h-2.047z" fill="#007A3D"/></svg>
    },{
        code:"+1",
        label:"Canada",
        flag:<svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path fill="#D52B1E" d="M4 5C1.791 5 0 6.791 0 9v18c0 2.209 1.791 4 4 4h6V5H4zm28 0h-6v26h6c2.209 0 4-1.791 4-4V9c0-2.209-1.791-4-4-4z"/><path fill="#EEE" d="M10 5h16v26H10z"/><path fill="#D52B1E" d="M18.615 22.113c1.198.139 2.272.264 3.469.401l-.305-1.002c-.049-.176.021-.368.159-.476l3.479-2.834-.72-.339c-.317-.113-.23-.292-.115-.722l.531-1.936-2.021.427c-.197.03-.328-.095-.358-.215l-.261-.911-1.598 1.794c-.227.288-.687.288-.544-.376l.683-3.634-.917.475c-.257.144-.514.168-.657-.089l-1.265-2.366v.059-.059l-1.265 2.366c-.144.257-.401.233-.658.089l-.916-.475.683 3.634c.144.664-.317.664-.544.376l-1.598-1.793-.26.911c-.03.12-.162.245-.359.215l-2.021-.427.531 1.936c.113.43.201.609-.116.722l-.72.339 3.479 2.834c.138.107.208.3.158.476l-.305 1.002 3.47-.401c.106 0 .176.059.175.181l-.214 3.704h.956l-.213-3.704c.002-.123.071-.182.177-.182z"/></svg>
     }]
     useEffect(()=>{
        // to Close menu when click outside
        function handleClickOutside(event) {
            if (ref_div.current && !ref_div.current.contains(event.target)) {
                setView(false)
                setIsDropdown(false)
        
            }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            };
     })
    return ( <>
         <Container style={style} onClick={()=>{
            setIsDropdown(!isDropdown)
            setView(!view)}}>
                <Box className="container-code-with-flag" >
                    <Box sx={{display:"flex",alignItems:"center"}} >
                        {flag}
                    </Box>
                    <Box sx={{display:"flex",alignItems:"center"}} >
                        {code}
                    </Box>

                </Box>
                
                <Box className="arrow" >
                    {isDropdown?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon/>}
                </Box>
                {/* <Box sx={{position:"absolute",right:"5px",top:"50%",transform:"translateY(-50%)"}} >
                    {isDropdown?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon/>}
                </Box> */}
                {view && <Paper ref={ref_div} sx={{ width: "fit-content",minWidth:"100%",position:"absolute",top:"52px",left:"0",right:0,zIndex:"34"}}>
                    <MenuList>
                        {data.map(e=>{
                            return  <MenuItem key={`Code_Phone_${e.code}`} onClick={()=>{
                                setView(!view)
                                setFlag(e.flag)
                                setCode(e.code)
                                returnedCode(e.code)
                                }}>
                            <ListItemIcon>
                            {e.flag}
                            </ListItemIcon>
                            <ListItemText>{e.label}</ListItemText>
                            <Typography sx={{marginLeft:"10px"}} color="text.secondary">
                            {e.code}
                            </Typography>
                        </MenuItem>
                        })}
                    
                    
                    </MenuList>
                    </Paper>}
        </Container>
        
    
    
    </>);
}

export default CustomInput;