import { NavLink } from "react-router-dom";
import styled , {keyframes}  from "styled-components";



export const ListSection = styled.ul`
    width:100%;
    height:100%;
    display:flex;
    gap:25px;
    // justify-content:space-between;
    align-items:center;
    list-style:none;

    @media (min-width:992px) and (max-width:1085px){
        width:75%;
        gap:10px;
    }
    @media (min-width:1085px){
        width:80%;
    }
    @media (max-width:992px){
        display: none;
    };
`
export const ListSectionCollapse = styled.ul`

    display:none;

    @media (min-width:1085px){
        width:70%;
    }
    @media (max-width:992px){
        width:70%;
        margin:auto;
        display: flex;
        flex-direction: column;
        gap:5px;
        line-height:unset;
        //  width:100%;
    };
`




export const Icon = styled.span`
    margin-right: 10px;
    display:flex;
    // position: relative;
    // top:5px    
    
`
export const ButtonSection = styled.div`
    width:28%;
    height:100%;
    display:flex;
    padding-right:15px;
    justify-content:end;
    align-items:center;
    @media (max-width:992px){
        width:50%;
        font-width:16px;
        padding-right:0px;

    }
    @media (min-width:993px) and (max-width:1085px){
        width:25%;

    }
  
    @media (min-width:1085px){
        width:20%;
    }
   
`
export const DivDrower = styled.div`
   min-width:300px;

`
export const Title = styled.h2`
color:white;
font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
position:relative;
text-transform:capitalize
`
export const LinkPages=styled(NavLink)`
color:unset;
background:unset;
width:100%;

`

export const ItemListSectionBase = styled.span`
& .scroll{
    color:black !important;
};
color:white;
position: relative;
cursor:pointer;
justify-content:space-between;
align-items:center;
height:100%;
font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
transition:0.2s;
display:none;
width:50%;
@media(max-width:992px){
display:flex;

// justify-content:center;
}
@media(max-width:350px){
font-size:12px;
font-weight:${props => props.theme.typography.Montserrat_Bold_base.weight};
font-family:${props => props.theme.typography.Montserrat_Bold_base.fontFamily};
}
@media(min-width:351px) and (max-width:768px){
font-size:${props => props.theme.typography.Montserrat_Bold_base.fontSize};
font-weight:${props => props.theme.typography.Montserrat_Bold_base.weight};
font-family:${props => props.theme.typography.Montserrat_Bold_base.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Bold_base.lineHeight};
}
`


export const ItemListSection = styled.li`
& .scroll{
    color:black !important;
    background:unset !important;
}
color: white ;
position: relative;
cursor:pointer;
display:flex;
justify-content:space-between;
align-items:center;
height:100%;
background:${props=>props.theme.colors.main.default};
font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
font-weight:600;
font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
${LinkPages}.active{
font-weight:900;
}

&:hover::after{
width:30px;

}

@media (max-width:992px){
font-size:14px;  
color:black;
padding:10px 0;
border-bottom:2px solid #ebdbdb;
justify-content:start;
background:white;
& span{
display:flex;
width:38px;
justify-content:center;

}
${LinkPages}.active{
font-weight:900;
color:${props=>props.theme.colors.main.default} !important;
}
${LinkPages}.active:after{
background:${props=>props.theme.colors.main.default};
}
&:after{

background:${props=>props.theme.colors.main.default};
}
};

`
