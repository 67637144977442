import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// mui 
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import { GradeOutlined } from '@mui/icons-material';
import Slider from '@mui/material/Slider';
import GradeIcon from '@mui/icons-material/Grade';
import { useSnackbar } from "notistack";
//api requests
import { Helper } from "../tools/helper";
import apiRoutes from "../apiRoutes";


const Container = styled.div`

`
const FlexDiv = styled.div`
    display:flex;
   justify-content:space-between;
   
`
const Title = styled.h1`
    text-transform:capitalize;
    color:${props => props.theme.colors.main.default};
    font-size : ${props => props.theme.typography.Montserrat_SemiBold_xl.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_xl.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_xl.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_xl.lineHeight};

`


const ButtonSection = styled(Button)`
    border:1px solid grey !important;
    color:grey !important;
    border-radius:30px !important;
    padding:10px;
    height:40px;
    background:white !important;
    transition:0.2s ;
    text-transform:capitalize !important;
    &:hover{
        color:white !important;
        background:grey !important;   
    }

`
// Accordion Section

const SubTitle = styled.h2`
    text-transform:capitalize;
    color:${props => props.theme.colors.main.default};
    font-size : ${props => props.theme.typography.Montserrat_SemiBold_base.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_base.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_base.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_base.lineHeight};

`
const Titlefilter = styled.h6`
    text-transform:capitalize;
    font-size : ${props => props.theme.typography.Montserrat.fontSize};
    font-family : ${props => props.theme.typography.Montserrat.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat.weight};
    line-height :  ${props => props.theme.typography.Montserrat.lineHeight};
    display:flex;
    align-items:center
    `
const Hr = styled.hr`
width:80%;
margin:auto;
height:1px;
background:rgba(221, 221, 221, 1)
`
const ValueDiv = styled.div`
border:1px solid grey ;
color:grey ;
border-radius:8px ;
background:white ;
padding:10px;
width:100px
`


    

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    border: "2px solid #d80621",
    padding:"1.5px 1.6px",
    background: "white !important",
    '.Mui-focusVisible &': {
    
    //   outline: '2px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,

    },
    'input:hover ~ &': {
      backgroundColor:  '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:""
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    position:"relative",
    border: "1px solid rgba(71, 37, 143, 1)",
    '&::before': {
      display: 'block',
      width: 11,
      height: 11,
      background:"rgba(71, 37, 143, 1)",
      content: '""',
      borderRadius:"50%",
      position:"absolute",
      top:"50%",
      left:"50%",
      transform:"translate(-50%,-50%)"

    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

  function valuetext(value) {
    return `${value}°C`;
  }
  
  const minDistance = 10;
function Filter({ lang,data_cat=[],returnValueForFilter,isSideBar = false,...props}) {

    const { enqueueSnackbar} = useSnackbar()

    const [brands, setBrands] = useState([])
    const [isHover,setisHover] = useState({})
    const [isAppendRest,setIsAppendRest] = useState(false)
    const [ isLoadingCat, setIsLoadingCat ] = useState(false)
    const [ isChangePrice, setIsChangePrice ] = useState(false)
    const [ isLoading, setIsLoading ] = useState({
        cat_view:false,
        brand_view:false
    })
    const [ total, setTotal ] = useState({
        cat_view:0,
        brand_view:0
    })
    const [showMore, setShowMore] = useState({
        cat_view:10,
        brand_view:5
    })
    const array_cat = useRef([])
    const rating = useRef(0)
    const array_brand = useRef([])
    
    const getBrands = async()=>{
        setIsLoading(prev=>({...prev,["brand_view"]:true}))
        const {response,message } = await Helper.Get({
            url:apiRoutes.brand.view,
            hasToken:true,
            data:{results:showMore.brand_view}
            
        })
        if(response){
            // response.data.forEach((element,i) => {
                setBrands(response.data)
            // });

            // enqueueSnackbar("Successfully Register",{variant:"success"}) 
            setIsLoading(prev=>({...prev,["brand_view"]:false}))
            setTotal(prev=>({...prev,["brand_view"]:response.meta.total}))
        }
    }
    
    useEffect(()=>{
         getBrands(lang)
    },[showMore.brand_view])


    // Filter Function
    const handleCheck = (value,key,id)=>{
        if(key=="category"){
            if(value.target.checked){
                array_cat.current.push(id)
            }else{
                let array_temp =array_cat.current
                array_cat.current = array_temp.filter(e => e != id)
            }
        }else if(key == "brand"){
            if(value.target.checked){
                array_brand.current.push(id)
            }else{
                let array_temp =array_cat.current
                array_brand.current = array_temp.filter(e => e != id)
            }
        }else{
            rating.current= id
        }
        
        // returnCategories(array_cat.current)

    }
    const handleFilter = ()=>{
        if(array_brand.current.length>0 && array_cat.current.length>0 && rating.current>0 ){
            returnValueForFilter({
                in_brands :array_brand.current.toString(),
                in_categories :array_cat.current.toString(),
                ratings:rating.current,
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        } else if(rating.current>0 && array_cat.current.length>0  ){
            returnValueForFilter({
                ratings:rating.current,
                in_categories :array_cat.current.toString(),
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }else if(rating.current>0 && array_brand.current.length>0  ){
            returnValueForFilter({
                ratings:rating.current,
                in_brands :array_brand.current.toString(),
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }else if(array_brand.current.length>0 && array_cat.current.length>0 ){
            returnValueForFilter({
                in_brands :array_brand.current.toString(),
                in_categories :array_cat.current.toString(),
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }else if(array_brand.current.length>0 ){
            returnValueForFilter({
                in_brands :array_brand.current.toString(),
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }else if(array_cat.current.length>0 ){
            returnValueForFilter({
                in_categories :array_cat.current.toString(),
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }else if(rating.current>0  ){
            returnValueForFilter({
                ratings:rating.current,
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        } else{
            returnValueForFilter({
                sellrangefrom: value2[0]*10,
                sellrangeto:value2[1]*10
            })
        }
    }
    // End Filter Function
    const clear_values = ()=>{
        array_brand.current = [] 
        array_cat.current = []
        rating.current = 0
    } 
    
    // slider 
  
    const [value2, setValue2] = React.useState([0, 100]);
  
    const handleChange2 = (event, newValue, activeThumb) => {
      if (!Array.isArray(newValue)) {
        return;
      }
  
      if (newValue[1] - newValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], 100 - minDistance);
          setValue2([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(newValue[1], minDistance);
          setValue2([clamped - minDistance, clamped]);
        }
      } else {
        setValue2(newValue);
      }
    };
    return ( <Container {...props} >

                <FlexDiv style={{height:"52px"}}>
                    <Title>
                        Filters
                    </Title>
                    <ButtonSection onClick={clear_values}>
                        clear all
                    </ButtonSection>
                </FlexDiv>
       
            <div style={isSideBar?{border:"",marginTop:"0",borderRadius:"4px",padding:"5px 15px"}
                                        :{border:"1px solid rgba(243, 243, 243, 1)",marginTop:"19px",borderRadius:"4px",padding:"5px 15px"}}>
                {/* Category Accordion */}
<Accordion defaultExpanded   sx={{
    borderBottom:"1px solid rgba(221, 221, 221, 1) !important",
    boxShadow:"unset !important",
    '&::before': {
        display: 'none !important',
      },
  }}>
    <AccordionSummary
    sx={{padding:"0 !important"}}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
    >
      <SubTitle>Category</SubTitle>
    </AccordionSummary>
    <AccordionDetails sx={{
        padding:"0 !important"
    }}>
        {data_cat.length>0 ?<>{data_cat.map((ele,i)=>{
            let first_append = []
            let rest_append = []
            if(i == 0 || i==1 || i==2 || i==3 || i==4 || i==5 || i==6 ){
                first_append.push(ele)
            }else{
                rest_append.push(ele)
            }
            return <>
                {
                    first_append.map((first)=><FlexDiv key={first.name} >
                    <Typography style={{display:"flex",alignItems:"center"}}>
                        <Checkbox onChange={(e)=>{handleCheck(e,"category",first.id)}} sx={{color: '#d80621',}} color="secondary"   /> 
                        <Titlefilter>{first.translations[lang]?first.translations[lang].name:""}</Titlefilter>
                    </Typography>
                    {/* <Typography style={{display:"flex",alignItems:"center"}}>
                        ({e.num})
                    </Typography> */}
        
                </FlexDiv>)
                }
                {
                    isAppendRest && rest_append.map((rest)=><FlexDiv key={rest.name} >
                    <Typography style={{display:"flex",alignItems:"center"}}>
                        <Checkbox onChange={(e)=>{handleCheck(e,"category",rest.id)}} sx={{color: '#d80621',}} color="secondary"   /> 
                        <Titlefilter>{rest.translations[lang]?rest.translations[lang].name:""}</Titlefilter>
                    </Typography>
                    {/* <Typography style={{display:"flex",alignItems:"center"}}>
                        ({e.num})
                    </Typography> */}
        
                </FlexDiv>)
                }
                
            </>
        })}
        {
            !isAppendRest && <SubTitle style={{cursor:"pointer",padding:"12px"}} onClick={()=>{setIsAppendRest(true)}} >
                show more
            </SubTitle>
        }
        {/* {categories.length<total.cat_view &&<>
            { isLoading.cat_view?<div style={{display:"flex",gap:"10px"}}>
            <SubTitle style={{cursor:"pointer",padding:"12px"}} onClick={()=>{setShowMore(prev=>({...prev,brand_view:prev.brand_view+5 }))}} >
            show more <CircularProgress color="primary"  />
            </SubTitle>
            
            </div>:<SubTitle style={{cursor:"pointer",padding:"12px"}} onClick={()=>{setShowMore(prev=>({...prev,cat_view:prev.cat_view+5 }))}} >
            show more
        </SubTitle>}
        </>} */}
        
        </>:<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <CircularProgress /></div>}
        
        
      
    </AccordionDetails>
  </Accordion>
`
{/* Rating Accordion */}
<Accordion defaultExpanded sx={{
    borderBottom:"1px solid rgba(221, 221, 221, 1) !important",
    boxShadow:"unset !important",
    '&::before': {
        display: 'none !important',
      },
  }}>
    <AccordionSummary
     sx={{padding:"0 !important"}}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel2-content"
      id="panel2-header"
    >
        
      <SubTitle>Rating</SubTitle>
    </AccordionSummary>
    <AccordionDetails sx={{
        padding:"0 !important"
    }}>
        {[{
            name:"kids",
            rate:1,
            num:1
        },{
            name:"man",
            rate:2,
            num:2
        },{
            name:"women",
            rate:3,
            num:3
        },{
            name:"casual",
            rate:4,
            num:4
        },{
            name:"casual",
            rate:5,
            num:5
        }].map((ele)=>{
            let empty=[]
            let fill =[]
            for(let i =0 ;i<ele.rate;i++){
                fill.push(i)
            }
            for(let j=0 ; j<(5-ele.rate);j++){
                empty.push(j)
            }
            return<FlexDiv key={ele.name} >
            <Typography style={{display:"flex",alignItems:"center"}}>
                <BpCheckbox onChange={(e)=>{handleCheck(e,"rating",ele.rate)}}  />           
                    {fill.map(()=><GradeIcon sx={{
                    fontSize:"18px",
                    color:"rgba(229, 152, 25, 1)"}}/>)}
                    {empty.map(()=><GradeOutlined sx={{
                    fontSize:"18px",
                    color:"rgba(229, 152, 25, 1)"}}/>)}
                    
                
                {ele.rate} & down
            </Typography>
            <Typography style={{display:"flex",alignItems:"center"}}>
                ({ele.num})
            </Typography>

        </FlexDiv>
        })}
        
      
    </AccordionDetails>
  </Accordion>

{/* Brand Accordion */}
  {/* <Accordion defaultExpanded sx={{
    borderBottom:"1px solid rgba(221, 221, 221, 1) !important",
    boxShadow:"unset !important",
    '&::before': {
        display: 'none !important',
      },
  }}>
    <AccordionSummary
        sx={{padding:"0 !important"}}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel3-content"
      id="panel3-header"
    >
      <SubTitle>Brand</SubTitle>
    </AccordionSummary>
    <AccordionDetails sx={{
        padding:"0 !important"
    }}>
        {brands.length>0 ?<>{brands.map((ele)=>(<FlexDiv key={ele.name} >
            <Typography style={{display:"flex",alignItems:"center"}}>
            <Checkbox onChange={(e)=>{handleCheck(e,"brand",ele.id)}}  sx={{color: '#d80621',}} color="secondary"   />
                <Titlefilter>{ele.translations[lang]?ele.translations[lang].name:""}</Titlefilter>
            </Typography>

        </FlexDiv>))}
        {brands.length<total.brand_view && <>
            {isLoading.brand_view?<div style={{display:"flex",gap:"10px"}}>
            <SubTitle style={{cursor:"pointer",padding:"12px"}} onClick={()=>{setShowMore(prev=>({...prev,brand_view:prev.brand_view+5 }))}} >
            show more <CircularProgress color="primary" size="1rem"  />
            </SubTitle>
            </div>:<SubTitle style={{cursor:"pointer",padding:"12px"}} onClick={()=>{setShowMore(prev=>({...prev,brand_view:prev.brand_view+5 }))}} >
            show more
        </SubTitle>}
        </>}
        
        </>:<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            <CircularProgress/></div>}
        
        
      
    </AccordionDetails>
  </Accordion> */}
{/* Price Accordion */}
<Accordion defaultExpanded sx={{
    boxShadow:"unset !important",
    '&::before': {
        display: 'none !important',
      },
  }}>
    <AccordionSummary
     sx={{padding:"0 !important"}}
      expandIcon={<ArrowDropDownIcon />}
      aria-controls="panel4-content"
      id="panel4-header"
    >
      <SubTitle>price</SubTitle>
    </AccordionSummary>
    <AccordionDetails sx={{
        padding:"0 !important"
    }}>
        <FlexDiv >
            <Typography style={{width:"100%",paddingLeft:"12px"}} >
            <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value2}
                onChange={handleChange2}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                disableSwap
                />
            </Typography>
            <Typography style={{display:"flex",alignItems:"space-between"}}>
               
            </Typography>

        </FlexDiv>
        <FlexDiv style={{justifyContent:"unset",gap:"10px",paddingLeft:"12px"}}>
            <ValueDiv>
                ${value2[0]*10}
            </ValueDiv>
            <ValueDiv>
                ${value2[1]*10}
            </ValueDiv>
        </FlexDiv>
        
      
    </AccordionDetails>
  </Accordion>




{/* Button Apply */}     
      <div style={{padding:"5px 0",display:"flex",justifyContent:"center"}}>
            <Button onClick={handleFilter}  sx={{
                    flex:"1",
                    background:"#e3a404",
                    color:"white",
                    textTransform:"uppercase",
                    transition:"0.3s",
                    fontFamily:"Montserrat",
                    fontSize:"14px",
                    borderRadius: "4px",
                    border:"1px solid inherit",
                    "&:hover":{
                        color:"#7F7F7F",
                        background:"white",
                        border:"1px solid #e3a404"
                    }
                }}>
                        
                            apply
                        </Button>
      </div>
             
            </div>
    </Container> );
}

export default Filter;