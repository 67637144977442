import  React,{useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { CircularProgress, Icon, Skeleton } from '@mui/material';
import { Helper } from '../tools/helper';
import apiRoutes from '../apiRoutes';
import { Language } from '../redux/action';
import { connect } from 'react-redux';



function CodeMenu({changeLang}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState(false)
    const [ languages, setLanguages] = useState([])
    const [langSelected, setLangSelected] = useState("En")
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      
    };
    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
      get_languages(signal)
      return ()=>{
          controller.abort()
      }
    },[])
    const get_languages = async(signal)=>{
      setIsLoading(true)
      const {response, message} = await Helper.Get_Abort({
        url:apiRoutes.setting.language_view,
        signal:signal,
      })
      if(response){
        setLanguages(response.data)
        localStorage.setItem("lang",JSON.stringify(response.data))
        response.data.forEach(element => {
          if(element.code == "en"){
            setLangSelected(element.code)
          }   
        });
        setIsLoading(false)
      }
    }
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Select Language">
            <Box
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 ,padding:"0 !important",
              textTransform:"capitalize",
              display:"flex",
              alignItems:"center",
              "& span":{
                fontSize:"14px",
              },
              "@media(max-width:992px)":{
                "& span":{
                  fontSize:"10px"
                }
              }}}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
              {/* <Icon >account_circle</Icon> */}
              <span style={{color:"black" , fontWeight:"600"}} >{langSelected} </span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.94184 9.81721C7.82464 9.93438 7.6657 10.0002 7.49997 10.0002C7.33424 10.0002 7.1753 9.93438 7.0581 9.81721L3.52247 6.28159C3.46278 6.22393 3.41516 6.15497 3.38241 6.07871C3.34965 6.00246 3.33241 5.92045 3.33169 5.83746C3.33097 5.75448 3.34678 5.67218 3.37821 5.59537C3.40963 5.51856 3.45604 5.44877 3.51472 5.39009C3.57341 5.33141 3.64319 5.285 3.72 5.25357C3.79681 5.22215 3.87911 5.20633 3.9621 5.20705C4.04508 5.20778 4.1271 5.22502 4.20335 5.25777C4.2796 5.29053 4.34857 5.33814 4.40622 5.39784L7.49997 8.49159L10.5937 5.39784C10.7116 5.28399 10.8695 5.22099 11.0333 5.22241C11.1972 5.22384 11.354 5.28957 11.4699 5.40545C11.5857 5.52133 11.6515 5.67809 11.6529 5.84196C11.6543 6.00583 11.5913 6.16371 11.4775 6.28159L7.94184 9.81721Z" fill="#9F9F9F"/>
                  </svg>
            </Box>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              width: 80,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {isLoading?<div style={{display:"flex",justifyContent:"center"}}>
            <Skeleton animation="wave" width={50}  />
          </div>:languages.length>0 && languages.map((e)=>{
           
            return <MenuItem sx={{textTransform:"capitalize"}} key={`Code_${e.code}`} onClick={()=>{
              handleClose()
              changeLang(e.code)
              setLangSelected(e.code)
            }}>
            {e.name}
          </MenuItem>
          })}

        </Menu>
      </React.Fragment>
    );
  }
const mapDispatchToProps = (dispatch) =>{
  return {
    changeLang :(lang)=>(dispatch(Language(lang)))
  }
}
export default connect(null,mapDispatchToProps)(CodeMenu)