import balance from "../pages/profile/balance/balance";

const initialValues = {
    username: "",
    photo:null,
    google_play: "",
    app_store:"",
    conversion_rate:"",
    open_box_fee:"",
    lang:"fr",
    charge_fee:"",
    balance:0,
    search :"",
    number:0,
    notification:{},
    conversation_id: 0,
    message:{},
    telegram_number:"",
    facebook_link:"",
    youtube_link:"",
    whatsapp_number:"",
    main_email:"",
    url:"",
    nextUrl:"",
    isOpen:false,
    isOpenPopup:false,
    isOpenConversation:false,
    userOther:{},
    cart:{
        products:[]
    },
    messageDir:"",
    urlProduct:""
}

export const reducerRoot = (state=initialValues,action) =>{
    switch(action.type){
        case "LOGIN-USER" : 
            return{
                ...state,
                username: action.username,
                photo:action.photo
            }
        case "LANGUAGE":
            return{
                ...state,
                lang: action.lang
            }
        break;
        case "CHAT-POPUP":
            return{
                ...state,
                userOther: action.userOther,
                isOpenPopup:action.isOpenPopup,
                isOpenConversation:action.isOpenConversation
            }
        break;
        case "DIRECT-MESSAGE":
            return{
                ...state,
                messageDir: action.messageDir,
                urlProduct:action.urlProduct,
            }
        break;
        case "GET-NOTIFICATION_CART":
            return{
                ...state,
                number: action.number,
                isOpen:action.isOpen
            }
        break;
        case "CHANGE-BALANCE":
            return{
                ...state,
                balance:action.balance
            }
        break;
        case "SEARCH":
            return{
                ...state,
                search: action.search
            }
        break;
        case "SETTING":
            return{
                ...state,
                google_play: action.google_play,
                app_store: action.app_store,
                conversion_rate: action.conversion_rate,
                charge_fee:action.charge_fee,
                open_box_fee:action.open_box_fee,
                telegram_number:action.telegram_number,
                facebook_link:action.facebook_link,
                youtube_link:action.youtube_link,
                whatsapp_number:action.whatsapp_number,
                main_email:action.main_email
            }
        break;
        case "GET-CART":
            return{
                ...state,
                cart: action.cart
            }
        break;
        case "PATH-NAME-LOCATION":
            return{
                ...state,
                url: action.url,
                nextUrl:action.nextUrl
            }
        break;
        case "ECHO_NOTIFICATIONS":
            return{
                ...state,
                notification:action.notification,
                conversation_id: action.conversation_id,
            }
        break;
        case "ECHO_MESSAGE":
            return{
                ...state,
                message:action.message
            }
        break;

        default :
            return{...state}
            break;
    }
}