import React , { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Box} from '@mui/material';
// animation 
import { Zoom } from "react-awesome-reveal";
import { useSnackbar } from "notistack";
import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
//axios
import axios from 'axios';
import { Helper } from "../../../tools/helper";
import apiRoutes from "../../../apiRoutes";
import LoadingPage from '../../../componentExternal/loadingPage';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import MDButton from '../../../components/MDButton';
import { Button } from '@mui/material';

const Container = styled.div`
padding:0px 70px;
overflow:hidden;
@media(max-width:500px){
  padding:30px 10px;  
}
@media(min-width:501px) and (max-width:992px){
    padding:10px 10px;
}
@media(min-width:992px) and (max-width:1200px){
    padding:10px 20px;
}


`
const FlexDiv = styled.div`
    display:flex;
    margin:20px 0;
    @media(max-width:500px){
    flex-direction:column;
    margin: 30px 0px !important;
}
@media(min-width:500px) and (max-width:992px){
    flex-direction:column;
    margin: 30px 20px !important;
}
   


`
const First = styled.div`
    overflow:hidden;
    align-items:center;
    width:100%;
        text-align:center;
        justify-content:center 
  
`
const Title = styled.h2`
    text-transform:capitalize;
    text-align:center;
    color:${props => props.theme.colors.main.default} ;
    font-size:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_SemiBold_5xl.weight};
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    line-height:unset !important;
    @media(max-width:992px){
        font-size:27px
    }
`
const SubTitle = styled.h4`
    text-transform:capitalize;
    text-align:center;
    color:black;
    font-size:16px;
    font-weight:${props => props.theme.typography.Montserrat_SemiBold_5xl.weight};
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    line-height:unset !important;
    @media(max-width:992px){
        font-size:14px
    }
`
const Paragraph = styled.p`
    margin-top:5px;
    // padding:0 20px 0 0 ;
    color:black;
    font-size:${props => props.theme.typography.Montserrat_Regular_lg.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Regular_lg.weight};
    font-family:${props => props.theme.typography.Montserrat_Regular_lg.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_lg.lineHeight};
    @media(max-width:992px){
        font-size:12px;
        padding: 0 ;
        width:100% !important
    }
`
const Div = styled.div`
margin-bottom:70px




`

function Shake_Unique({lang}) {
    const navigate = useNavigate()
    const isSmall = useBreakpoint(801)
    const { enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(true)
    const [ page, setPage ]= useState({})
    // AOS.init()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPages(signal)
        return ()=>{
            controller.abort()
        }
    },[])
    const getPages = async(signal)=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            hasToken:true,
            signal:signal,
            data:{name:"shake_unique"}
        })
        if(response){
            if(response.data.length>0){
                setPage(response.data[0])
                setIsLoading(false)
            }
            
          
        }
    }
    return (isLoading?<LoadingPage/>:<Container>
        <FlexDiv>
            <First>
                <Div>
                    <Title
                    //  data-aos="fade-up"
                    //     data-aos-delay="1000"
                    //         data-aos-duration="1000"
                             style={{margin:"10px 0"}}>
                    {((lang in page.translations)&&page.translations[lang].name) ? page.translations[lang].name:"" }
                    </Title>
                </Div>


                {page.sections && page.sections.length>0 &&<Box sx={{display:"flex",justifyContent:"space-between",
                        "@media(max-width:800px)":{
                            flexDirection:"column",
                            alignItems:"center",
                            gap:"10px"
                        }}}>
                    {page.sections.map((e,i)=>{
                        return<Box key={e.name ? e.name :`shakes_page_${i}`}  sx={(i%2!=0 && !isSmall)?{marginTop:"55px",width:"250px"}:{marginTop:"0",width:"250px"}}>
                            <Box 
                            // data-aos="fade-up"
                            //     data-aos-delay="1100"
                            // data-aos-duration="1000"
                              sx={{display:"flex",justifyContent:"center"}}>
                                    <Box sx={{borderRadius:"50%", width: "45px",
                                        height: "45px",
                                        borderRadius: "50%",
                                        background: "white",
                                        boxShadow: "1px 2px 3px 0px #a6a2a2",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"}}>
                                        <img alt={e.name?e.name:""}  src={e.photo?e.photo:""} style={{width:"30px",objectFit:"contain"}} />
                                    </Box>
                                </Box>
                                <SubTitle
                                //  data-aos="fade-up"
                                //     data-aos-delay="1000"
                                //         data-aos-duration="1000"
                                         style={{margin:"10px 0"}}>
                                {((lang in e.translations) && e.translations[lang].name) ? e.translations[lang].name:"" }
                                </SubTitle>
                                <Paragraph 
                                // data-aos="fade-up"
                                // data-aos-delay="1500"
                                // data-aos-duration="1000"
                                 dangerouslySetInnerHTML={{__html:((lang in e.translations) && e.translations[lang].description)? e.translations[lang].description:""  }}/>

                        </Box>
                    })}
                    
                    
                    </Box>}


            </First>
            

        </FlexDiv>
    </Container> );
}

export default Shake_Unique;