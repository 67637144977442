
// Images Swiper
import img from "../../assets/images/support_chat.png"
import imgBig from "../../assets/images/Rectangle 1054 (1).png"
// import cover from "../../assets/images/finall_.png" 
import imgChristmas from "../../assets/images/Rectangle 1056.png"
import person from "../../assets/images/Rectangle 3766.png";
import GroupSection from "./components/groupSection";
import Blog from "./components/blog";
import Brand from "./components/brand";
import Application from "./components/application";
import { lazy,Suspense , useEffect ,useRef,useState} from "react";

//api request
import { Helper } from "../../tools/helper";
import apiRoutes from "../../apiRoutes";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
// pages
import Introduction from "./components/intro";
import { setting } from "../../redux/action";
import { Box, Grid, Tooltip } from "@mui/material";
import More_Win from "./components/more_win";
import Thirll from "./components/thrill";
import Top from "./components/top";
import Shake_Unique from "./components/shake_unique";
import Trends from "./components/trends";
import Section from "./components/section";
import Join_Us from "./components/JoinCommunity";

import lottie from 'lottie-web';
import { useNavigate } from "react-router-dom";
import Start from "./components/start_with_us";
//global custom styles
import { CustomGrid} from "../../styles/homeStyles"




function Home({lang,google_play,app_store}) {
    const [isLoading,setIsLoading] = useState(false)
    const [ page_for_meta, setPage_for_meta ] = useState({}) 
    const div_animate = useRef(null)
    const navigate = useNavigate()
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const controller = new AbortController()
        const signal = controller.signal
        get_home_for_meta(signal)
        return ()=>{controller.abort()} 
    },[]);
    useEffect(()=>{
        lottie.loadAnimation({
            animationData: require("../../data/support_chat.json"),
            autoplay: true,
            container: div_animate.current,
            loop: false,
            renderer: "svg",
          })
        const idInterval = setInterval(()=>{
            lottie.play()
        },1000)
          
        return ()=>{
        lottie.destroy()
        clearInterval(idInterval)
        }

    })
    const get_home_for_meta = async (signal)=>{
        const {response,message} = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            hasToken:true,
            signal:signal,
            data:{name:"home_page"}
        })
        if(response){
            setPage_for_meta(response.data[0])
        }
    }
   
    return (<div id="home-page" >
        { ("id" in page_for_meta) &&
            <Helmet>
            <title>{(page_for_meta.translations && (lang in page_for_meta.translations))? page_for_meta.translations[lang].meta_title:""}</title>
            <meta
                name="description"
                content={(page_for_meta.translations && (lang in page_for_meta.translations))? page_for_meta.translations[lang].meta_description:""} />
       
       {/* <script type="application/ld+json">
                {
               ` "@context": "http://schema.org",
                "@type": "Store",
                "name": "Shake Shake",
                "url": "https://full.winshakes.com",
                "description": "Win every time with awesome mystery boxes",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "+1 343-987-4565",
                    "contactType": "customer service"
                },
                "paymentAccepted": "Credit Card, PayPal",
                "hasOfferCatalog": {
                    "@type": "OfferCatalog",
                    "name": "Mystery Boxes",
                    "url": "https://full.winshakes.com/products"
                }`
                }
                </script> */}
        </Helmet>}

       
            
        <Introduction  lang={lang}/>
        <Join_Us  lang={lang} />
                
                <More_Win lang={lang}/>
                {/* <Win_And_Earn lang={lang}/> */}
                <CustomGrid container >
                    <Grid item sx={{display:"flex",alignItems:"center"}} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Top google_play={google_play} lang={lang} />
                    </Grid>
                    <Grid item sx={{display:"flex",alignItems:"center"}} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Thirll lang={lang}/>
                    </Grid>
                </CustomGrid>
                <Shake_Unique lang={lang}  />
                <Start/>
                <Trends lang={lang} />
                {/* <Section lang={lang} title="Top sellers"  /> */}
                <Box sx={{margin:"40px 0"}}>
                    <Section lang={lang}  title="Latest Products" />
                </Box>
                {/* <GroupSection lang={lang}  title="Shop by category" /> */}
                {/* <Blog lang={lang}  /> */}
                {/* <Box sx={{margin:"70px 0 50px 0"}}>
                <Brand lang={lang}  title="Our Brands" />
                </Box> */}
                {/* <Box sx={{margin:"30px 0"}}>
                    <Application/>
                </Box> */}
                
                
 
</div>);
}
const mapStateToProps = (state)=>{
    return{
        lang:state.lang,
        google_play:state.google_play,
        app_store:state.app_store
    }
}
const mapDispatchToState = (dispatch)=>{
    return{
       setting: (google_play,app_store,conversion_rate,charge_fee)=>{dispatch(setting(google_play,app_store,conversion_rate,charge_fee))}
    }
}
export default connect(mapStateToProps,mapDispatchToState)(Home);