import { lazy } from 'react'
import Home from "../pages/home";
const Wishlist = lazy(()=>import("../pages/wishlist"))
const Vendor_Page = lazy(()=>import("../pages/vendor/vendors"))
const ProductDetails = lazy(()=>import("../pages/marketplace/viewDetails"))
const Marketplace = lazy(()=>import("../pages/marketplace"))
const SignIn = lazy(()=>import("../pages/auth"));
const Shake_Proccess = lazy(()=>import("../pages/shakes"));
const Select = lazy(()=>import("../pages/shakes/select"));
const Pay_Shakes = lazy(()=>import("../pages/shakes/pay"))
const Products_Box = lazy(()=>import("../pages/shakes/products"));
const Sell_Box = lazy(()=>import("../pages/shakes/sell"));
const Details_Blog = lazy(() => import('../pages/blog/details'));
const Page = lazy(() => import('../pages/dynamic-pages/base'));
const Blog = lazy(() => import('../pages/blog'));
const Vendor_details = lazy(() => import('../pages/vendor'));
const Error = lazy(() => import('../pages/error'));
const Messages_Page = lazy(() => import('../pages/chat'));
const Support_Conversation  = lazy(() => import('../pages/chat/supportConversation'));
const Address_Pay = lazy(() => import('../pages/checkout/address'));
const Pay = lazy(() => import('../pages/checkout'));
const Items = lazy(() => import('../pages/checkout/items'));
const Reuslt = lazy(() => import('../pages/checkout/result'));
const Oops_page = lazy(() => import('../pages/checkout/oops'));
const Shipping_Method_Pay = lazy(() => import('../pages/checkout/method'));
const VendorOrder = lazy(() => import('../pages/profile/incoming_order/vendorOrder'));
const Notifications = lazy(() => import('../pages/profile/notifications/notifications'));
const Redeem = lazy(() => import('../pages/profile/redeem_requests/redeem'));
const Store = lazy(() => import('../pages/profile/store/store'));
const Add_Address = lazy(() => import('../pages/profile/my_profile/add_address'));
const Shakes = lazy(() => import('../pages/profile/shakes_history/shakes'));
const Orders = lazy(() => import('../pages/profile/order_history/order'));
const Details_Shakes = lazy(() => import("../pages/profile/shakes_history/detailsShakes"));
const Details_Order = lazy(() => import("../pages/profile/order_history/detailsOrder"));
const Profile = lazy(() => import('../pages/profile'));
const Details_Order_Vendor = lazy(() => import("../pages/profile/incoming_order/details_vendorOrder"));
const Profile_Outlet = lazy(() => import('../pages/profile/my_profile'));
const Profile_Details = lazy(() => import('../pages/profile/my_profile/profile'));
const Change_Password = lazy(() => import("../pages/profile/my_profile/changePassword"));
const Set_Phone = lazy(() => import("../pages/profile/my_profile/setPhone"));
const Verfication_Phone = lazy(() => import("../pages/profile/my_profile/verification"));
const Edit_Product_Store = lazy(() => import('../pages/profile/store/editProduct'));
const Balance = lazy(() => import('../pages/profile/balance/balance'));
const Address = lazy(() => import('../pages/profile/my_profile/address'));
const Friends_view = lazy(() => import('../pages/profile/friend_requests/friends_view'));
const Friends = lazy(() => import('../pages/profile/friend_requests'));
const Friend_request = lazy(() => import('../pages/profile/friend_requests/friend_request'));
const SendFriendRequests = lazy(() => import('../pages/profile/friend_requests/send_request'));
const Followers = lazy(() => import('../pages/profile/friend_requests/followers'));
const Blocked_User = lazy(() => import('../pages/profile/friend_requests/block'));
const Page_404 = lazy(() => import('../pages/error/404_Page'));
const Invite_friend = lazy(() => import('../pages/profile/invite_friend'));
const Box_Details = lazy(() => import('../pages/shakes/box'));
export  const  allRoutes = [
    // Home 
    {
        component:<Home/>,
        key:"home",
        route:"/",
    },
     // Blog 
     {
        component:<Blog/>,
        key:"blog",
        route:"/blog",
    },
    {
        component:<Details_Blog/>,
        key:"blog-details",
        route:"/blog/:id",
    },
    // Auth 
    {
        component:<SignIn/>,
        key:"sign-in",
        route:"/autherization",
    },
    {
        component:<SignIn pageName={"register"}/>,
        key:"register",
        route:"/register",
    },
    // Checkout
    {
        component:<Pay/>,
        key:"pay",
        route:"/pay",
    },
    {
        component:<Items/>,
        key:"pay-items",
        route:"/pay/items",
    },
    // Address & Methods
    {
        component:<Shipping_Method_Pay/>,
        key:"method",
        route:"/:name/pay/method",
    },
    {
        component:<Address_Pay/>,
        key:"address",
        route:"/:name/pay/address",
    },
    // Pages 
    {
        component:<Page/>,
        key:"dynamic-page",
        route:"/:name/:subname",
    },
    {
        component:<Reuslt/>,
        key:"result",
        route:"/result",
    },
    {
        component:<Oops_page/>,
        key:"oops-page",
        route:"/result/oops",
    },
    {
        component:<Error/>,
        key:"error-page",
        route:"/error",
    },

    // Marketplace
    {
        component:<Marketplace/>,
        key:"marketplace-name",
        route:"/products/:name",
    },
    {
        component:<Marketplace/>,
        key:"marketplace-name-id",
        route:"products/:id",
    },
    {
        component:<Marketplace/>,
        key:"marketplace",
        route:"/products",
    },
    {
        component:<ProductDetails/>,
        key:"product-details",
        route:"/product/:id",
    },

    // Shakes
    {
        component:<Box_Details/>,
        key:"box-details",
        route:"/shakes/box/:id",
    },
    // Shakes
    {
        component:<Shake_Proccess/>,
        key:"shakes",
        route:"/shakes",
    },
    {
        component:<Pay_Shakes/>,
        key:"shake-pay",
        route:"/shakes/pay",
    },
    {
        component:<Select/>,
        key:"shake-select",
        route:"/shakes/select",
    },
    {
        component:<Sell_Box/>,
        key:"shake-sell",
        route:"/shakes/sell",
    },
    {
        component:<Products_Box/>,
        key:"shake-products",
        route:"/shakes/box/products",
    },
    // Profile 
    {
        component:<Profile/>,
        key:"profile",
        route:"/profile/:name",
        children:[{
            component:<Profile_Outlet/>,
            key:"profile-layout",
            route:"info",
            children:[{
                component:<Profile_Details/>,
                key:"profile-detailes",
                route:"mainInfo",
            },{
                component:<Change_Password/>,
                key:"change-password",
                route:"changePassword",
            },{
                component:<Set_Phone/>,
                key:"set-phone",
                route:"setPhone",
            },{
                component:<Verfication_Phone/>,
                key:"verification",
                route:"verification",
            },{
                component:<Address/>,
                key:"address",
                route:"address",
            },{
                component:<Add_Address/>,
                key:"add-address",
                route:"addAddress",
            },{
                component:<Add_Address/>,
                key:"edit-address",
                route:"addAddress/:id",
            }]
        },
        
        {
            component:<Balance/>,
            key:"balance",
            route:"balance",
        },{
            component:<Redeem/>,
            key:"redeem",
            route:"redeem",
        },{
            component:<VendorOrder/>,
            key:"incoming-order",
            route:"incomingOrder",
        },{
            component:<Details_Order_Vendor/>,
            key:"incoming-order-detailes",
            route:"incomingOrder/:id",
        },{
            component:<Orders/>,
            key:"order-history",
            route:"orderHistory",
        },{
            component:<Details_Order/>,
            key:"order-history-detailes",
            route:"orderHistory/:id",
        },{
            component:<Shakes/>,
            key:"shakes-list",
            route:"shakes",
        },{
            component:<Details_Shakes/>,
            key:"shakes-list-detailes",
            route:"shakes/:id",
        },{
            component:<Friends/>,
            key:"friends",
            route:"info",
            children:[{
                component:<Friends_view/>,
                key:"friends-list",
                route:"friends",
            },{
                component:<Friend_request/>,
                key:"friend-requests",
                route:"friendRequests",
            },{
                component:<SendFriendRequests/>,
                key:"send-requests",
                route:"sendRequests",
            },{
                component:<Followers/>,
                key:"followers",
                route:"followers",
            },{
                component:<Blocked_User/>,
                key:"blocked-people",
                route:"blockedPeople",
            }]
        },{
            component:<Store/>,
            key:"store",
            route:"myStore",
        },{
            component:<Edit_Product_Store/>,
            key:"edit-product",
            route:"myStore/product/:id",
        },{
            component:<Notifications/>,
            key:"notification",
            route:"notification",
        },{
            component:<Invite_friend/>,
            key:"invitation",
            route:"invitation",
        }]
    },
    // Wishlist
    {
        component:<Wishlist/>,
        key:"wishlist",
        route:"/wishlist",
    },
    // Chat
    {
        component:<Messages_Page/>,
        key:"messages",
        route:"/messages",
    },
    {
        component:<Support_Conversation/>,
        key:"support-chat",
        route:"/chat/support/:id",
    },
    {
        component:<Support_Conversation/>,
        key:"support-chat",
        route:"/chat/support",
    },
    {
        component:<Messages_Page/>,
        key:"messages-getone",
        route:"/messages/:id",
    },
    // Vendor
    {
        component:<Vendor_Page/>,
        key:"vendors",
        route:"/vendors",
    },
    {
        component:<Vendor_details/>,
        key:"vendor-detailes",
        route:"/vendor/:vendor/:id",
    },

]
