import React ,{ useEffect, useRef, useState } from 'react';
import styled ,{keyframes} from 'styled-components';
import cover from "../../assets/images/nature.jpg"
import person from "../../assets/images/profile.png"
import imgProduct from "../../assets/images/product-photo.png"
import noItem from "../../assets/images/wishlist.png"
//mui 
import { Avatar ,Box,Card,Typography,CardContent,CardActions,Button, Grid} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MDButton from '../../components/MDButton';
import ChatIcon from '@mui/icons-material/Chat';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CategoryIcon from '@mui/icons-material/Category';
import TuneIcon from '@mui/icons-material/Tune';
import { CircularProgress, IconButton, Skeleton ,Tooltip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useBreakpoint } from "react-use-size";
import Filter from '../../componentExternal/filter_section';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Modal from '@mui/material/Modal';
//api request
import { Helper } from '../../tools/helper';
import apiRoutes from '../../apiRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Sidebar } from 'primereact/sidebar';
import { Helmet } from 'react-helmet-async';
import LoadingPage from '../../componentExternal/loadingPage';
import { connect } from 'react-redux';
import Paginator from '../../componentExternal/paginator';
import Simple_Card_Product from '../../componentExternal/simple_card_product';
import { Close } from '@mui/icons-material';
import Page_404 from '../error/404_Page';

const Continer = styled.div`
padding:60px 0;
    display:flex;
    justify-content:center;

& h2{
    color:${props=> props.theme.colors.main.default};
    font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
    font-weight:600;
    font-size:20px;
    text-align:center;
}
& h6{
    color:black;
    font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
    font-weight:500;
    font-size:16px;
    text-align:center;
    margin-bottom:10px;
}
& img{
    object-fit:contain;
    height:250px;

}
& span{
    display: flex;
    margin-right: 10px;
}
`
const Container = styled.div`

`
const Cover = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:center;
    align-items:center;
    font-family : Montserrat;
    
    
`
const ContentCover = styled.div`
    width:70%;
    border-radius:4px;
    position:relative;
    height:380px;
    @media(max-width:768px){
        width:90%;
    }
`
const ImgVendor = styled.div`

`
const ContentVendor = styled.div`
    position:absolute;
    padding:0 20px;
    bottom:0;
    left:0;
    right:0;
    display:flex;
    justify-content:space-between;
    @media(max-width:768px){
        justify-content:unset; 
        position:static;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height:500px
    }

`
const InfoVendor = styled.div`

`
const ImgV = styled.img`
    width:100%;
    object-fit:cover;
    height:200px;
    border-radius:4px;
`


const Nav = styled.div`
display:flex;
justify-content:space-between;
margin:10px 0;
border-bottom:1px solid gery;

`

const CustomAvatar = styled(Avatar)`

top:-30px;
@media(max-width:768px){
    left:50%;
    top:-50px;
    transform:translateX(-50%)
}

`
const CardProduct = styled.div`
font-family : "Montserrat";
& h3{
    color:grey;
    font-size:14px;
    margin-top:5px;
    font-weight:unset !important;
    line-height:unset !important;
}
& span{
    color:black;
    font-size:12px;
    font-weight:700
}
& h5{
    color:grey;
    font-size:12px;
    margin-top:5px;
    font-weight:unset !important;
    line-height:unset !important;
}
& a{
    font-weight:600;
}
& a:hover{
    color:${props=>props.theme.colors.main.default}
}

& img{
    border-radius:4px;
    // width: 100%;
    height: 100%;
    object-fit: contain;
    transition:0.5s;
}
&: hover{
    cursor:pointer;
    img{
       
        transform:scale(1.1);
    }
    h3{
        color:#e3a404;
    }
}
`

const LinkVendor = styled.a`
    color: ${props=>props.theme.colors.main.default};
    transition:0.5s;
    & :hover{
        color:silver 
    }
`
const Para = styled.p`
color:${props => props.theme.colors.main.default};
font-size : 12px;
font-family : ${props => props.theme.typography.Extra_Sale_16_Regular.fontFamily};

`
const Para_info = styled.p`
color:black;
font-size : 15px;
font-weight:500;
font-family : ${props => props.theme.typography.Extra_Sale_16_Regular.fontFamily};

`

function Wishlist({lang}) {
    const {vendor,id} = useParams()
    const [vendorInfo, setVendorInfo] = useState({})
    const isSmall = useBreakpoint(993)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [fromFollow, setFromFollow] = useState(false)
    const [categories, setCategories] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [products,setProducts] = useState([])
    const [isLoading , setIsLoading] = useState(true)
    const [totalPages,setTotalPages] = useState(0)

    const [isLoadingProduct , setIsLoadingProduct] = useState(true)
    const [view,setView] = useState(false)
    const [isRemoveItems,setIsRemoveItems] = useState(false)

    const [addWishlist,setAddWishlist] = useState({})
    const follow_ref = useRef(null)
    const friend_ref = useRef(null)
    const accept_ref = useRef(null)
    const page =  useRef(1)
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        window.scrollTo({left:0, top:0, behavior:"smooth"})
        setCategories([])
        get_Products(signal)
        return ()=>{
            controller.abort()
        }
    },[])


    const applicate_filter = (res)=>{
        setIsOpen(false)
        get_Products(null, res,1)
    }
    const paginate = (number_page)=>{
        page.current = number_page
        get_Products(null,{},page.current)

    }
    const get_Products = async (signal,filter_values={},page_paramerter = 1) =>{
        setIsLoadingProduct(true)
        setProducts([])
        let data ={user_id:id,results:12,page:page_paramerter}
        // if(filter_values.ratings)
        //     data.ratings = filter_values.ratings 
        // if(filter_values.in_categories)
        //     data.in_categories = filter_values.in_categories 
        // if(filter_values.sellrangeto)
        //     data.sellrangeto = filter_values.sellrangeto
        
        // data.sellrangefrom = filter_values.sellrangefrom ?filter_values.sellrangefrom:0 

        const {response , message} = await Helper.Get_Abort({
            url:apiRoutes.wishlist.view,
            signal:signal,
            hasToken:true,
            data:data
        })
        if(response){
            setProducts(response.data)
            response.data.forEach(ele=>{
                setAddWishlist(prev=>({...prev,[ele.id]:false}))
            })
            setTotalPages(response.meta.last_page)
            setIsLoadingProduct(false)
        }

    }
    const removeItem = async(id)=>{
        setAddWishlist(prev=>({...prev,[id]:true}))
        const {response,message} = await Helper.Post({
            url:apiRoutes.wishlist.add(id),
            hasToken:true,
        })
        if(response){
            get_Products()
           //  enqueueSnackbar(`successfully add `, {variant:"success"} );
        
           setAddWishlist(prev=>({...prev,[id]:false}))
        }else{ 
             if(typeof(message) === "string"){
                enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }else{
                Object.keys(message).map((key)=>{
                    enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                })
            }
           
            setAddWishlist(prev=>({...prev,[id]:false}))
           
        }
    }
    const removeItems = async()=>{
        setIsRemoveItems(true)
        const {response,message} = await Helper.Get({
            url:apiRoutes.wishlist.remove,
            hasToken:true,
        })
        if(response){
            setProducts([])
           //  enqueueSnackbar(`successfully add `, {variant:"success"} );
           setIsRemoveItems(false)
        }else{ 
             if(typeof(message) === "string"){
                enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }else{
                Object.keys(message).map((key)=>{
                    enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                })
            }
           
            setIsRemoveItems(false)
           
        }
    }
    
    const animationMove = keyframes`
        from{
            left:-100px;
        }to{
            left:-10px;
        }

    `
    const animationMoveBack = keyframes`
        from{
            left:0;
        }to{
            left:-100px;
        }

    `
    const FilterSection= styled.div`
    width:25%;
    // display:${props=>("block")};
    display:${props=>(isOpen?"block":"none")};
    background:white;
    position:absolute;
    width:300px;
    top:0;
    bottom:0;
    transition:0.8s;
    z-index:10;
    &.animation{
        animation:${animationMove} 0.7s ease;
    }
    &.no-animation{
        animation:${animationMoveBack} 0.7s ease;
    }
    @media(max-width:768px){
        width:300px;
        
    }
    @media(min-width:769px)and (max-width:1000px){
        width:33%;
    }
    
    `
    const Content = styled.div`
    padding:20px 50px;
    background :rgba(242, 237, 237, 0.34);
    @media(max-width:768px){
        // margin-top:  ${props=>((localStorage.hasOwnProperty("user")&&(vendorInfo.id != JSON.parse(localStorage.getItem("user")).id))?"272px":"40px")};
        padding:20px 10px;
    }
`
    
    return (<>
    {<>
        <Container>
        <Helmet>
            <title>Shake Shake | Whislist</title>
        </Helmet>

        <Content >
            <Box sx={{display:"flex",justifyContent:"end",padding:"10px 0"}}>
            <Button disabled={products.length >0 ?false :true} onClick={()=>{removeItems()}} sx={{
                        width:'180px',
                        height:"40px",
                        background:"white",
                        borderRadius:"4px",
                        border:products.length >0 ?"1px solid #d80621":"1px solid grey",
                        color:"#d80621",
                        transition:"0.5s",
                        textTransform:"capitalize !important",
                        fontSize:"14px !important",
                        padding:"5px 20px ",
                        "& span":{
                            marginRight:"5px",
                            display:"flex"
                        },
                        "& span path":{
                            fill:products.length >0 ?"#d80621":"grey"
                        },
                        "&:hover":{
                            background:"#d80621",
                            border:"1px solid white ",
                            color:"white"
                        },
                        "&:hover span path":{
                            fill:"white"
                        }
                    }}>
                    
                    <span>
                    
                        {isRemoveItems?<CircularProgress size="1rem" style={{color:"#e3i404"}} />:<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                        <g clipPath="url(#clip0_712_24958)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.75 3.5V2H10.25V3.5H5.75ZM4.25 3.5V1.5C4.25 1.23478 4.35536 0.98043 4.54289 0.792893C4.73043 0.605357 4.98478 0.5 5.25 0.5H10.75C11.0152 0.5 11.2696 0.605357 11.4571 0.792893C11.6446 0.98043 11.75 1.23478 11.75 1.5V3.5H14.25C14.4489 3.5 14.6397 3.57902 14.7803 3.71967C14.921 3.86032 15 4.05109 15 4.25C15 4.44891 14.921 4.63968 14.7803 4.78033C14.6397 4.92098 14.4489 5 14.25 5H13.885L13.142 14.653C13.1034 15.1554 12.8767 15.6248 12.507 15.9673C12.1373 16.3097 11.6519 16.5 11.148 16.5H4.852C4.34808 16.5 3.86274 16.3097 3.49304 15.9673C3.12335 15.6248 2.89655 15.1554 2.858 14.653L2.115 5H1.75C1.55109 5 1.36032 4.92098 1.21967 4.78033C1.07902 4.63968 1 4.44891 1 4.25C1 4.05109 1.07902 3.86032 1.21967 3.71967C1.36032 3.57902 1.55109 3.5 1.75 3.5H4.25ZM3.62 5H12.38L11.646 14.538C11.6364 14.6636 11.5798 14.7809 11.4875 14.8666C11.3952 14.9522 11.2739 14.9999 11.148 15H4.852C4.72607 14.9999 4.60482 14.9522 4.5125 14.8666C4.42017 14.7809 4.36357 14.6636 4.354 14.538L3.62 5Z" fill="#D80621"/>
                        </g>
                        <defs>
                        <clip-path id="clip0_712_24958">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                        </clip-path>
                        </defs>
                        </svg>}
                    </span>
                        Remove All
                    </Button>
            </Box>
            <div style={{position:"relative" ,minHeight:"500px"}}>
    
            {isLoadingProduct?<Grid container>
                {[1,2,3,4,5,6,7,8,9,10,11,12].map((e,i)=>(<Grid key={`loading_wishlist_${i}`}  sx={{padding:'5px'}} lg={2} md={2} sm={4} xs={6}>
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <Skeleton wave="animation" height={100} sx={{width:"80%"}} />
                                </Box>
                                <Box>
                                    <Skeleton wave="animation" height={50} sx={{width:"100%"}}  />
                                </Box>
                            </Grid>))}
            </Grid>
                    :<Grid sx={{marginTop:"0!important"}}  container spacing={2}>
                {
                   products.length==0?<Continer style={{margin:"auto"}}>
                   <Box sx={{width:"80%"}} >
                       <Box sx={{display:"flex",justifyContent:"center"}}>
                           <img src={noItem} alt="no item" />
               
                       </Box>
                       <Box>
                           <h2>No item here.</h2>
                       </Box>

                   </Box>
               
               </Continer> : products.map((e,i)=>{
                        return  <Grid sx={{position:"relative",
                        "& .delete-icon":{
                            opacity:addWishlist[e.id]?"1":"0",
                            transition:"0.5s",
                            cursor:"pointer"
                        },
                        
                        "&:hover":{
                            "& .delete-icon":{
                                opacity:"1",
                            }
                        },
                        "@media(max-width:768px)":{
                            "& .delete-icon":{
                                opacity:"1",
                            }
                        },
                        }} key={e.product_translations[lang]?"product_vendor"+e.product_translations[lang].name:`product_vendor_${i*1500}`} item lg={2} md={2} sm={4} xs={6}>
                            <Simple_Card_Product
                            returnedRemove={(res)=>{
                                if(res){
                                    removeItem(e.id)
                                }

                            }}
                            fromWishlistPage={true}
                            wishlist={true}
                            photo={e.photo}
                            name={(lang in e.product_translations && e.product_translations[lang].name)?e.product_translations[lang].name.substring(35,0)+"...":""}
                            vendor={e.user}
                            id={e.id}
                            sellprice={e.sellprice}/>
                            {/* <Box sx={{position:"absolute",display:"flex",justifyContent:"space-between",padding:"0 5px",left:0, top:0,right:0}}>
                            <Box onClick={()=>{removeItem(e.id)}} className="delete-icon" sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <Tooltip title="Remove" >
                                    {addWishlist[e.id]?<CircularProgress size="1.2rem" />:<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 17" fill="none">
                                    <g clipPath="url(#clip0_712_24958)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.75 3.5V2H10.25V3.5H5.75ZM4.25 3.5V1.5C4.25 1.23478 4.35536 0.98043 4.54289 0.792893C4.73043 0.605357 4.98478 0.5 5.25 0.5H10.75C11.0152 0.5 11.2696 0.605357 11.4571 0.792893C11.6446 0.98043 11.75 1.23478 11.75 1.5V3.5H14.25C14.4489 3.5 14.6397 3.57902 14.7803 3.71967C14.921 3.86032 15 4.05109 15 4.25C15 4.44891 14.921 4.63968 14.7803 4.78033C14.6397 4.92098 14.4489 5 14.25 5H13.885L13.142 14.653C13.1034 15.1554 12.8767 15.6248 12.507 15.9673C12.1373 16.3097 11.6519 16.5 11.148 16.5H4.852C4.34808 16.5 3.86274 16.3097 3.49304 15.9673C3.12335 15.6248 2.89655 15.1554 2.858 14.653L2.115 5H1.75C1.55109 5 1.36032 4.92098 1.21967 4.78033C1.07902 4.63968 1 4.44891 1 4.25C1 4.05109 1.07902 3.86032 1.21967 3.71967C1.36032 3.57902 1.55109 3.5 1.75 3.5H4.25ZM3.62 5H12.38L11.646 14.538C11.6364 14.6636 11.5798 14.7809 11.4875 14.8666C11.3952 14.9522 11.2739 14.9999 11.148 15H4.852C4.72607 14.9999 4.60482 14.9522 4.5125 14.8666C4.42017 14.7809 4.36357 14.6636 4.354 14.538L3.62 5Z" fill="#D80621"/>
                                    </g>
                                    <defs>
                                    <clip-path id="clip0_712_24958">
                                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                                    </clip-path>
                                    </defs>
                                    </svg>}
                                
                                </Tooltip>
                                
                                </Box>
                               
                                
                            </Box> */}
    
                            </Grid>
                                        })
                                    }
    
    
            </Grid>}
    
            </div>
            
    
        </Content>
        {totalPages>1 &&<div style={{display:"flex",justifyContent:"center",margin:"20px 0"}}>
            <Paginator page_income={page.current} count_pages={totalPages} returnedPage={(res)=>{
                 if(res==="next"){
                    paginate(page.current+1)
                }else if(res==="previous"){
                    paginate(page.current-1)
                }else{
                    paginate(parseInt(res))
                }
            }}/>
        </div>}
        
    </Container>
    </>}
    
    </>  

);
}
const mapStateToProps = (state)=>{
    return{
        lang:state.lang
    }
}
export default connect(mapStateToProps,null)(Wishlist);