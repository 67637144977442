import React, { useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import SwiperClass from "swiper/types/swiper-class";
import SwiperCore, { FreeMode, Navigation, Thumbs, Controller } from 'swiper/modules';
// import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
export default function AdSwiper({array}) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const swiper1Ref = useRef(null);
  const swiper2Ref = useRef();

  useLayoutEffect(() => {
    if (swiper1Ref.current !== null) {
      swiper1Ref.current.controller.control = swiper2Ref.current;
    }
  }, []);

  return (
    <div className="h-[550px] ">
      <Swiper
      style={{height:"300px",position:"fixed",top:"20%",left:0,right:0
      ,display:"flex",justifyContent:"center",alignItems:"center",background:"#1b1919cc",zIndex:"2000",
        padding:"20px",
        '--swiper-navigation-color': 'white',
        '--swiper-pagination-color': 'white',
      }}
        onSwiper={(swiper) => {
          if (swiper1Ref.current !== null) {
            swiper1Ref.current = swiper;
          }
        }}
        preloadImages={false}
        controller={{ control: secondSwiper }}
        spaceBetween={10}
        slidesPerView={1}
        grabCursor={true}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Controller]}
        className="w-[848px] h-[454px] rounded-xl"
      >
     {array.length>0 && array.map((e)=>{
            if(e.type == "image"){
                return <SwiperSlide style={{display:"flex",justifyContent:"center"}}>
                  <img style={{height:"100%",width:"90%",objectFit:"contain"}} alt="" src={e.src} />
                </SwiperSlide>
            }
            if(e.type == "video"){
                return <SwiperSlide style={{display:"flex",justifyContent:"center"}}>
                    <video style={{height:"100%",width:"90%",objectFit:"contain"}}  controls>
                        <source src={e.src} />
                    </video>
                </SwiperSlide>
            }
      })}
      </Swiper>
      <Swiper
       style={{height:"100px",position:"fixed",left:0,right:0,bottom:"20%",padding:"20px"
       ,display:"flex",justifyContent:"center",alignItems:"center",background:"#1b1919cc",zIndex:"2100"}}
        controller={{ control: firstSwiper }}
        loop={false}
        spaceBetween={10}
        slidesPerView={8}
        watchSlidesProgress
        touchRatio={0.2}
        preloadImages={false}
        lazy
        slideToClickedSlide={true}
        onSwiper={setThumbsSwiper}
        modules={[Navigation, Thumbs, Controller]}
        className="h-[100.4px] w-[848px] mt-[20px] rounded-xl"
      >
           {array.length>0 && array.map((e)=>{
            if(e.type == "image"){
                return <SwiperSlide style={{display:"flex",justifyContent:"center"}}>
                  <img style={{height:"100%",width:"90%",objectFit:"contain"}} alt="" src={e.src} />
                </SwiperSlide>
            }
            if(e.type == "video"){
                return <SwiperSlide style={{display:"flex",justifyContent:"center"}}>
                    <video style={{height:"100%",width:"90%",objectFit:"contain"}}  controls>
                        <source src={e.src} />
                    </video>
                </SwiperSlide>
            }
      })}
      </Swiper>
    </div>
  );
}