import React , { useEffect, useState } from 'react';
import styled from 'styled-components';
// animation 
import { useSnackbar } from "notistack";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
import img from "../../../assets/images/mobile.png"
import cover from "../../../assets/images/Group 1000006060.png"
//axios
import { Helper } from "../../../tools/helper";
import apiRoutes from "../../../apiRoutes";
import LoadingPage from '../../../componentExternal/loadingPage';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid,Box } from '@mui/material';
import { connect } from 'react-redux';
//global styles
import { ParagraphLarge } from '../../../styles/homeStyles';


const BasicContainer = styled.div`
 height: 450px;

 position: relative;
 display: flex;
 align-items:center;
 & .content{
    padding:20px 40px;
    background:${props => props.theme.colors.main.default} ;
 }
 & img{
    position: absolute;
    object-fit:cover;
    left:50%;
    transform: translateX(-50%) scale(0.8)
 }
 & #down {
    font-size:14px;
    margin-bottom:20px;
    font-weight:500;
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    color:white
};

 @media(max-width:992px){
    & img{
        display:none
    }
    
}
// @media(min-width:769px) and (max-width:991px){
//     & img{
//         transform: translateX(-95%) scale(0.7);
//         left:95%;
//     }
// }
`

const ButtonApplication = styled(Button)`

width: 200px!important;
// height: 60px !important;
justify-content:unset !important;
color:black !important;
text-transform: capitalize !important;
margin-right:15px !important;

`
const Icon = styled.span`
    margin-right:4px;
    display:flex

`
const ContentButton= styled.p`
display:flex;
align-items:center;
padding:5px;
`
const ButtonTitle=styled.h4`
font-size:15px; 
text-align: start;
line-height:13px;
color:black;
@media(max-width:400px){
    font-size:13px; 
}
@media(min-width:992px) and (max-width:1100px){
    font-size:13px; 
}



`
const ButtonSubTitle=styled.h6`
    font-size:12px;
    font-weight: 500;
    color:black;
    text-align: start;
    line-height:17px;
    @media(max-width:400px){
        font-size:8px; 
    }
    @media(min-width:992px) and (max-width:1100px){
        font-size:8px; 
    }

`
const DivButton = styled.div`
    line-height: 14px;
    // display:flex;
    align-items:center;
    justify-content:center

`



function Start({lang,google_play,app_store}) {
    const navigate = useNavigate()
    const { enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(true)
    const [ page, setPage ]= useState({})
    // AOS.init()
    const isSmall = useBreakpoint(993)
    const [application, setApplication] = useState({
        "google_play":{
            title:"Google Play",
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <g clipPath="url(#clip0_824_51555)">
            <path d="M18.163 15.1234L3.01115 29.7136C2.30161 29.2287 1.88034 28.4222 1.88779 27.5628V2.68404C1.88034 1.82468 2.30161 1.01811 3.01115 0.533203L18.163 15.1234Z" fill="#282828"/>
            <path d="M18.1628 14.7058L3.49477 0.581457C4.17487 0.236042 4.98714 0.250405 5.65813 0.628771L5.66153 0.630658L22.9618 10.0928L18.1628 14.7058Z" fill="#282828" stroke="white" strokeWidth="0.6"/>
            <path d="M29.8881 15.1217C29.898 16.0918 29.3708 16.9879 28.5181 17.4507L23.4629 20.218L18.1611 15.1217L23.4629 10.0254L28.5181 12.7927C29.3708 13.2555 29.898 14.1516 29.8881 15.1217Z" fill="#282828"/>
            <path d="M22.9618 20.1523L5.66153 29.6145L5.66012 29.6152C4.98733 29.9879 4.17761 30.0023 3.49583 29.6626L18.1629 15.5393L22.9618 20.1523Z" fill="#282828" stroke="white" strokeWidth="0.6"/>
            </g>
            <defs>
            <clip-path id="clip0_824_51555">
            <rect width="30.1724" height="30.1724" fill="white" transform="translate(0.801758 0.0332031)"/>
            </clip-path>
            </defs>
            </svg>,
            href:"https://play.google.com/store/apps/details?id=com.zorba.shake&pli=1",
            sub_Title:"Andriod App On"
        },
        "app_store":{
            title:"App Store",
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <g clipPath="url(#clip0_824_51547)">
            <path d="M11.2266 30.2934C6.73599 30.2675 3.02148 21.1007 3.02148 16.4323C3.02148 8.80647 8.74216 7.13697 10.9469 7.13697C11.9404 7.13697 13.0014 7.52715 13.9371 7.87242C14.5915 8.11327 15.2682 8.3619 15.6446 8.3619C15.8699 8.3619 16.4008 8.1504 16.8696 7.96483C17.8692 7.56687 19.1131 7.07227 20.5616 7.07227C20.5642 7.07227 20.5677 7.07227 20.5702 7.07227C21.6518 7.07227 24.9313 7.30964 26.9029 10.2705L27.3648 10.9646L26.7001 11.4661C25.7506 12.1826 24.018 13.4896 24.018 16.0784C24.018 19.1447 25.9802 20.3239 26.9228 20.891C27.3389 21.1413 27.7696 21.3994 27.7696 21.964C27.7696 22.3326 24.8277 30.2476 20.5555 30.2476C19.5102 30.2476 18.7712 29.9334 18.1195 29.6563C17.46 29.3758 16.8911 29.1341 15.951 29.1341C15.4745 29.1341 14.8719 29.3594 14.234 29.5985C13.3623 29.9239 12.3755 30.2934 11.2559 30.2934H11.2266Z" fill="#282828"/>
            <path d="M21.0142 0.121094C21.1254 4.13285 18.2564 6.91601 15.3907 6.74146C14.9185 3.53995 18.256 0.121094 21.0142 0.121094Z" fill="#282828"/>
            </g>
            <defs>
            <clip-path id="clip0_824_51547">
            <rect width="30.1724" height="30.1724" fill="white" transform="translate(0.310547 0.121094)"/>
            </clip-path>
            </defs>
            </svg>,
            href:"https://apps.apple.com/us/app/shake-shake/id6502258199?platform=iphone",
            sub_Title:"Download on the"
        }
    })
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPages(signal)
        return ()=>{
            controller.abort()
        }
    },[])
    const getPages = async(signal)=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            hasToken:true,
            signal:signal,
            data:{name:"never_win"}
        })
        if(response){
            if(response.data.length > 0){
                setPage(response.data[0])

                // enqueueSnackbar("Successfully Register",{variant:"success"}) 
                setIsLoading(false)
            }
          
        }
    }
    return (isLoading?<LoadingPage/>:
    <BasicContainer >
            <img alt={""}   src={img} />
            <Grid container className='content'>
                <Grid item xs={12} sm={12} lg={6}  sx={{display:"flex",alignItems:"center"}}>
                    <Box >
                        <ParagraphLarge style={{color:"white"}} >
                        Start your new experience with us now
                        </ParagraphLarge>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={2} ></Grid>
                <Grid item xs={12} sm={12} lg={4}  >
                <Box >
                    <span id="down" >Dawnload Our Application</span>
                    <Box>
                    { Object.keys(application).map((key ,index)=>(<ButtonApplication 
                 key={application[key].title}
                // data-aos={index===1?"fade-left":"fade-right"}
                // data-aos-delay={isHero?(isSmall?"600":"2000"):"500"}
                // data-aos-duration="1000"
                // data-aos-mirror="true"
                sx={{

                    background:"white",
                    borderRadius:"4px",
                    width:"150px",
                    marginBottom:"10px",
                    transition:"0.4s background ease",
                    border:"0.603px solid white !important",
                    "&:hover":{
                        color:"black !important",
                        background:"white !important",
                        color:"black !important",
                        border:"0.603px solid black !important"
                    },
                    

                    }}>
           <Link to={application[key].href ? application[key].href : "#"}>
                <ContentButton>
                    <Icon>
                        {application[key].icon}
                    </Icon>
                    <DivButton>
                        <ButtonSubTitle>
                            {application[key].sub_Title}
                        </ButtonSubTitle>
                        <ButtonTitle>
                            {application[key].title}
                        </ButtonTitle>
                    </DivButton>
                </ContentButton>
            </Link>
        
                    </ButtonApplication>))}
                    </Box>
                                </Box>
                </Grid>
            </Grid>
    </BasicContainer>
    )
    

}
const mapStateToProps = (state)=>{
    return{
        google_play:state.google_play,
        app_store:state.app_store
    }
}
export default connect(mapStateToProps,null)(Start);