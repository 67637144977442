import { Avatar, Box, CircularProgress, Typography,Button } from '@mui/material';
import React,{useEffect, useState,useRef} from 'react';
import styled from 'styled-components';
import imgUser from "../../../assets/images/profile.png"
import MDInput from '../../../components/MDInput';
import { Grid } from '@mui/material';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { SnackbarProvider, useSnackbar } from 'notistack';
import MDButton from '../../../components/MDButton';
import 'rsuite/dist/rsuite.min.css';
import { connect } from 'react-redux';
//images
import balance_img from "../../../assets/images/2x_fram.png"
import dateFormat from "dateformat";
import Modal from '@mui/material/Modal';
import Paginator from '../../../componentExternal/paginator';
import CustomInput from '../../../componentExternal/customInput';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const Container = styled.div`
    padding:15px 0;
    @media(max-width:400px){
        padding:5px;
    }
`
const Title = styled.h2`
    color: ${props => props.theme.colors.main.default};
    font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
    font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
    border-bottom:1px solid #8080802e;
    padding:10px
`
const SubTitle = styled.h4`
    font-size: 20px;
    font-weight:600;
    text-transform:capitalize;
    font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};

`
const Section = styled.div`

    padding:10px 30px;
    border-radius:10px;
    border:1px solid #e4e4e4; 

`
const Card = styled.div`
    border-radius:10px;
    margin-top:15px;
    border:1px solid #e4e4e4; 
`

///Send Balance Component
const Send_Balance = ()=>{
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmit,setIsSubmit] = useState(false)
    const [values,setValues] = useState({
        phone:0,
        username:"",
        amount:0
    })
    const [code,setCode] = useState(0)
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
    }

    const handleSubmit =async ()=>{
        if(values.amount !=""){
            if((code >0 && values.phone>0) || values.username.length > 0){
                setIsSubmit(true) 
        
            let form_data = new FormData()
                if(code >0 && values.phone>0){
                    form_data.append("phone",values.phone)
                    form_data.append("phone_code", code)
                }
                if(values.username.length > 0){
                    form_data.append("username",values.username)
                }
                
                form_data.append("amount", values.amount)
                form_data.append("_method", "PUT")
    
            const { response, message } = await Helper.Post({
                url: apiRoutes.balance.sendPoints,
                data:form_data,
                hasToken:true
            })
            if(response){
                setIsSubmit(false)
                window.location.reload()
                enqueueSnackbar("Successfully send",{variant:"success",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }else{
                if(message=="not_enough_balance"){
                    enqueueSnackbar("You don't have enough balance.",{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                }else if(message == "amount_must_be_less_than_1000000"){
                    enqueueSnackbar("The transfer amount must be less than 1000000.",{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                }else if(typeof(message) === "string"){
                    enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                }else{
                    Object.keys(message).map((key)=>{
                        enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                          }}) 
                    })
                }
                setIsSubmit(false)
            }
            }else{
                enqueueSnackbar('Enter phone number or username to complete proccess .',{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }})    
            }
        }else{
            enqueueSnackbar('Check amount field .',{variant:"error",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }}) 
        }
        
        
        

    }
    return <Box >
        <Grid container spacing={1} sx={{margin:"10px 0"}}>
                <Grid md={3} lg={3} sm={3} xs={3}>
                    <CustomInput returnedCode={(phone)=>{setCode(phone)}} />
               
                </Grid>
                <Grid  md={9} lg={9} sm={9} xs={9}>
                    <MDInput width='95%' onChange={(e)=>{handleChange("phone",e.target.value)}} placeholder="phone" icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.23 15.26L16.69 14.97C16.3914 14.9349 16.0886 14.968 15.8046 15.0667C15.5206 15.1654 15.2626 15.3272 15.05 15.54L13.21 17.38C10.3712 15.9362 8.06382 13.6288 6.62004 10.79L8.47004 8.94C8.90004 8.51 9.11004 7.91 9.04004 7.3L8.75004 4.78C8.69335 4.29217 8.45923 3.84223 8.09228 3.51584C7.72532 3.18945 7.25115 3.00941 6.76004 3.01H5.03004C3.90004 3.01 2.96004 3.95 3.03004 5.08C3.56004 13.62 10.39 20.44 18.92 20.97C20.05 21.04 20.99 20.1 20.99 18.97V17.24C21 16.23 20.24 15.38 19.23 15.26Z" fill="#7f7f7f"/>
                        </svg>} />
                </Grid>
        </Grid>
        <Box sx={{margin:"10px 0"}}>
            <MDInput onChange={(e)=>{handleChange("username",e.target.value)}} value={values.username} type="text" placeholder="username" icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 10C12.42 10 16 11.79 16 14V16H0V14C0 11.79 3.58 10 8 10Z" fill="#7F7F7F"/>
                    </svg>} />
        </Box>
        <Box sx={{margin:"10px 0"}}>
            <MDInput onChange={(e)=>{
                console.log(typeof e.target.value);
                console.log("value", e.target.value ,"--")
                if(e.target.value>0 || e.target.value ==""){
                    handleChange("amount",e.target.value)
                }else{
                    handleChange("amount",1)
                }
            }} value={values.amount} type="number" placeholder="amount" icon={<svg style={{marginLeft:"-3px"}} class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AccountBalanceWalletIcon"><path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg>} />
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",margin:"5px"}}>
          <Button sx={{
                        background:"white",
                        borderRadius:"4px",
                        border:"1px solid #d80621",
                        color:" #d80621",
                        transition:"0.5s",
                        textTransform:"capitalize !important",
                        fontSize:"16px !important",
                        padding:"5px 40px ",
                        "&:hover":{
                            background:"#d80621",
                            border:"1px solid white ",
                            color:"white"
                        }
                    }} onClick={handleSubmit}> {isSubmit?
                    <CircularProgress size="1.5rem" sx={{color:"#e3a404"}} />:"Send Points"}</Button>

          </Box>

       
    </Box>
}
///Add Balance Component
const Add_Balance = ({charge_fee,conversion_rate})=>{
    const { enqueueSnackbar } = useSnackbar();
    const [isPaypal,setIsPaypal] = useState(false)
    const [values,setValues] = useState({
        point:1,
        USD:1*parseFloat(conversion_rate)
    })
    const [code,setCode] = useState(0)
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
     
    }
    const handleSubmit =async (IDPayment)=>{
        let form_data = new FormData()

        form_data.append("move_type", "1")
        form_data.append("balance_type", "1")
        form_data.append("active", "1")
        form_data.append("transaction",IDPayment )
        form_data.append("user_id", JSON.parse(localStorage.getItem("user")).id)
        form_data.append("amount", values.point)
        form_data.append("_method", "PUT")

        const { response, message } = await Helper.Post({
            url: apiRoutes.balance.addPoints,
            data:form_data,
            hasToken:true
        })
        if(response){
            window.location.reload()
            enqueueSnackbar("Successfully Add balance .",{variant:"success",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }}) 
        }else{
            if(typeof(message) === "string"){
                enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }else{
                Object.keys(message).map((key)=>{
                    enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                })
            }
        }
        

    }
    useEffect(()=>{
        setValues(prev=>({...prev,["USD"]:values.point*parseFloat(conversion_rate)}))
    },[values.point])
    useEffect(()=>{
        if(window.paypal && isPaypal){
            window.paypal.Buttons({
              createOrder:(data,action)=>{
                return action.order.create({
                  purchase_units:[{
                    amount:{
                      value: values.USD+parseFloat(charge_fee)
                    }
                  }]
                })
              },
              onApprove: (data,action)=>{
                console.log(data);
                handleSubmit(data.paymentID)
                return action.order.capture().then(details=>{
                  console.log(details);
                })
              },
              onError:()=>{
                enqueueSnackbar("Something Error!!",{variant:"error"})
              }
            }).render('#paypal-button-container')
          }
    },[isPaypal])
    return <Box sx={{
        "@media(min-width:768px)":{
            display:"flex",
            flexDirection:"column",
            alignItems:"center"
        }
    }} >
    {!isPaypal && <Grid container spacing={1} sx={{margin:"10px 0",
            "@media(min-width:768px)":{
                justifyContent:"center"
            }}}>

        <Grid  sm={12} xs={12} lg={4} md={4} xl={4}>
            <MDInput onChange={(e)=>{
                if(e.target.value > 0 || e.target.value == "" ){
                    handleChange("point",e.target.value)
                }else{
                    handleChange("point",1)
                }
            }} value={values.point} type="number"  width="98%" placeholder="point" icon={<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AccountBalanceWalletIcon"><path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg>} />
        </Grid>
        <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}} sm={12} xs={12} lg={2} md={2} xl={2}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 9L17 5V8H10V10H17V13M7 11L3 15L7 19V16H14V14H7V11Z" fill="#D80621"/>
            </svg>
        </Grid>
        <Grid sm={12} xs={12} lg={4} md={4} xl={4}>
            <MDInput value={values.USD}   type="number" onChange={(e)=>{handleChange(e.target.value,"USD")}} background={"white"} width="98%"  placeholder="USD" icon={<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AccountBalanceWalletIcon"><path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path></svg>} />
        </Grid>
    </Grid>}
    <Box sx={{margin:"10px 0"}}>
        <Typography>
        *A fee of {charge_fee} points will be added to the total 
        </Typography>
    </Box>
    <Box sx={{margin:"10px 0"}}>
         Total is: {values.USD+parseFloat(charge_fee)}
    </Box>
    
    <Box sx={{display:"flex",justifyContent:"center",margin:"5px"}}>
          {isPaypal?<div id="paypal-button-container" style={{maxWidth:"200px",minWidth:"200px"}}></div> :<Button sx={{
                        background:"white",
                        borderRadius:"4px",
                        border:"1px solid #d80621",
                        color:" #d80621",
                        transition:"0.5s",
                        textTransform:"capitalize !important",
                        fontSize:"16px !important",
                        padding:"5px 40px ",
                        "&:hover":{
                            background:"#d80621",
                            border:"1px solid white ",
                            color:"white"
                        }
                    }} onClick={()=>{
                        
                            setIsPaypal(true)
                        
                    }}> continue</Button>
                    
                    }

    </Box>

   
</Box>
}

//basic component
function Balance({lang,cart,charge_fee,conversion_rate}) {

    const { enqueueSnackbar } = useSnackbar();
    const [file, setFile] = useState("");
    const [countries, setCountries] = useState([]);
    const [balances, setBalances] = useState([])
    const [page, setPage] = useState(1);
    const [ isLoading, setIsLoading ] = useState(false)
    const [isSubmit,setIsSubmit] = useState(false)
    const [ isLoading_Country, setIsLoading_Country ] = useState(true)
    const [ balance, setBalance ] = useState(0)
    const [isSendOpen,setIsSendOpen] = useState(false)
    const [isAddOpen,setIsAddOpen] = useState(false)
    const [balance_total_pages, setBalance_total_pages]= useState(1)
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        get_transactions(signal)
        return ()=>{
            controller.abort()
        }
    },[page])
   

    const get_transactions = async(signal)=>{
        setIsLoading(true)
        const { response, message } = await Helper.Get_Abort({
            url: apiRoutes.balance.viewByUser,
            hasToken:true,
            signal:signal,
            data:{page:page}
        })
        if(response){
            setBalances(response.data)
            setBalance(response.balance)
            setBalance_total_pages(response.meta.last_page)
            setIsLoading(false)
        }
    }
    return ( <>
    <Container>
        <Section>
        <Box sx={{
            display:"flex",
            justifyContent:"space-between",
        }}>
            <Box sx={{
            display:"flex",
            justifyContent:"center",
            }} >
            <div >
                <SubTitle style={{color:"black",margin:"20px 0 30px 0"}}>
                        your balance
                    </SubTitle>
                    <Typography sx={{
                        fontFamily:"Montserrat",
                        display:"flex",
                        color:"#d80621",
                        fontSize:"30px",
                        fontWeight:"600"

                    }}>
                        {balance}<span style={{fontSize:"15px",margin:"15px 0 0 3px ",color:"grey"}}>pts</span>
                    </Typography>
            </div>
            </Box>
            <Box>
                <img style={{width:"100%"}} src={balance_img} />
            </Box>
        </Box>
        <Grid spacing={2} container sx={{
            display:"flex",
            marginTop:"10px"
            
        }}>
            <Grid item  lg={6} md={6} sm={12} xl={6} xs={12}>
                <Button onClick={()=>setIsSendOpen(true)} sx={{
                    width:'100%',
                    background:"white",
                    borderRadius:"4px",
                    border:"1px solid #d80621",
                    color:" #d80621",
                    transition:"0.5s",
                    textTransform:"capitalize !important",
                    fontSize:"16px !important",
                    padding:"5px 40px ",
                    "&:hover":{
                        background:"#d80621",
                        border:"1px solid white ",
                        color:"white"
                    }
                }}
                >Send points</Button>

            </Grid>
            
            <Grid item  lg={6} md={6} sm={12} xl={6} xs={12} >
                <Button onClick={()=>setIsAddOpen(true)} sx={{  width:'100%',
                                background:"#d80621",
                                borderRadius:"4px",
                                border:"1px solid  #d80621",
                                color:"white",
                                transition:"0.5s",
                                textTransform:"capitalize !important",
                                fontSize:"16px !important",
                                padding:"5px 40px",
                                "&:hover":{
                                    background:"white",
                                    border:"1px solid  #d80621",
                                    color:"#d80621"
                                }
                            }}
                >Add points</Button>
            </Grid>
        </Grid>
        </Section>
        <Typography sx={{margin:"10px 0",fontWeight:"600",fontSize:"20px",fontFamily:"Montserrat",color:"black"}}>
            Transaction
        </Typography>
      {isLoading?<Box sx={{display:"flex",justifyContent:'center',alignItems:"center",minHeight:"300px"}}>
            <CircularProgress color="primary" />
        </Box>:(balances.length>0 ?balances.map((e)=><Card  key={e}>
            <Grid sx={{padding:"10px"}} container>
                <Grid item sx={{display:"flex",justifyContent:'center',alignItems:"center"}}  lg={1} md={1} sm={1} xl={1} xs={1}>
                    {e.move_type=="1"? <svg xmlns="http://www.w3.org/2000/svg" width="41" height="39" viewBox="0 0 41 39" fill="none">
                        <path d="M4.31081 6.37009L28.1335 1.47125C32.0739 0.660946 35.9136 3.23755 36.6573 7.1911L39.4929 22.2656C40.2932 26.5204 37.1836 30.5138 32.8624 30.7806L12.8226 32.0178C8.81271 32.2654 5.37635 29.1825 5.1889 25.1693L4.31081 6.37009Z" fill="#A4FF9C"/>
                        <path d="M0 12.0652C0 8.08985 3.22266 4.86719 7.19802 4.86719H33.2908C37.2662 4.86719 40.4888 8.08985 40.4888 12.0652V31.6692C40.4888 35.6445 37.2662 38.8672 33.2908 38.8672H7.19801C3.22265 38.8672 0 35.6445 0 31.6692V12.0652Z" fill="#4EC444"/>
                        <path d="M33.2908 4.86719H10.797L40.4888 17.4637V12.0652C40.4888 8.08985 37.2662 4.86719 33.2908 4.86719Z" fill="#6DED62"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.3153 17.0018C21.3153 16.578 20.9718 16.2344 20.5479 16.2344C20.1241 16.2344 19.7805 16.578 19.7805 17.0018V26.1253L17.6378 23.9826C17.3381 23.6829 16.8522 23.6829 16.5525 23.9826C16.2528 24.2823 16.2528 24.7682 16.5525 25.0679L19.9869 28.5022C20.127 28.6522 20.3265 28.746 20.5479 28.746C20.7738 28.746 20.9768 28.6485 21.1173 28.4932L24.5426 25.0679C24.8423 24.7682 24.8423 24.2823 24.5426 23.9826C24.2429 23.6829 23.757 23.6829 23.4573 23.9826L21.3153 26.1246V17.0018Z" fill="white"/>
                    </svg>:<svg xmlns="http://www.w3.org/2000/svg" width="41" height="39" viewBox="0 0 41 39" fill="none">
                                <path d="M4.31081 6.37009L28.1335 1.47125C32.0739 0.660946 35.9136 3.23755 36.6573 7.1911L39.4929 22.2656C40.2932 26.5204 37.1836 30.5138 32.8624 30.7806L12.8226 32.0178C8.81271 32.2654 5.37635 29.1825 5.1889 25.1693L4.31081 6.37009Z" fill="#F1C1C1"/>
                                <path d="M0 12.0652C0 8.08985 3.22266 4.86719 7.19802 4.86719H33.2908C37.2662 4.86719 40.4888 8.08985 40.4888 12.0652V31.6692C40.4888 35.6445 37.2662 38.8672 33.2908 38.8672H7.19801C3.22265 38.8672 0 35.6445 0 31.6692V12.0652Z" fill="#BE0D0D"/>
                                <path d="M33.2908 4.86719H10.797L40.4888 17.4637V12.0652C40.4888 8.08985 37.2662 4.86719 33.2908 4.86719Z" fill="#F15858"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M21.3153 27.9748C21.3153 28.3986 20.9717 28.7422 20.5479 28.7422C20.124 28.7422 19.7805 28.3986 19.7805 27.9748V18.8513L17.6378 20.994C17.3381 21.2937 16.8522 21.2937 16.5525 20.994C16.2528 20.6943 16.2528 20.2084 16.5525 19.9087L19.986 16.4752C20.1261 16.3247 20.326 16.2305 20.5479 16.2305C20.7734 16.2305 20.9762 16.3278 21.1166 16.4827L24.5426 19.9087C24.8423 20.2084 24.8423 20.6943 24.5426 20.994C24.2429 21.2937 23.757 21.2937 23.4573 20.994L21.3153 18.8519V27.9748Z" fill="white"/>
                                </svg>}
                   
                </Grid>
                <Grid sx={{paddingLeft:"10px"}} item  lg={8} md={8} sm={8} xl={8} xs={8}>
                    <Typography sx={{
                        color:e.move_type=="1"?"rgba(78, 196, 68, 1)":"rgba(190, 13, 13, 1)",
                        textTransform:"capitalize",
                        fontWeight:"600",
                        fontFamily:"Montserrat",
                        marginButtom:"10px"
                    }}>{e.type.name}</Typography>
                    {( e.to_user_id && e.to_user_id != 0 )? <Typography sx={{fontFamily:"Montserrat",}}>{e.to_user.name}</Typography>:""}
                    
                    <Typography sx={{fontFamily:"Montserrat",fontSize:"15px",color:"rgba(173, 173, 180, 1)"}}>{dateFormat(e.created_at, " mmmm dS, yyyy | h:MM:ss TT")}</Typography>
                </Grid>
                <Grid item sx={{fontFamily:"Montserrat",display:"flex",justifyContent:'end'}} lg={3} md={3} sm={3} xl={3} xs={3}>

                    {e.move_type == "1"?<span style={{color:"rgba(78, 196, 68, 1)",fontWeight:"600"}}> +{e.amount}pts</span>
                    :<span style={{color:"rgba(190, 13, 13, 1)",fontWeight:"600"}}> -{e.amount}pts</span>}
                   
                </Grid>

            </Grid>
            

        </Card>):<Typography sx={{marginTop:"10px",display:"flex",justifyContent:'center',alignItems:"center",minHeight:"300px",fontSize:"20px",fontFamily:"Montserrat",color:"black",fontWeight:"600"}}>
                    No Transaction
                </Typography>)}

       
    </Container>
    {balance_total_pages >1 && <Box sx={{display:"flex",justifyContent:'center',alignItems:"center",margin:"10px 0"}}>

<Paginator page_income={page} count_pages={balance_total_pages} returnedPage={(res)=>{
    if(res==="next"){
        setPage(page+1)
    }else if(res==="previous"){
        setPage(page-1)
    }else{
        setPage(parseInt(res))
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
 }} />
</Box> }
     
    <Modal 
        open={isAddOpen || isSendOpen}
        onClose={()=>{
        setIsAddOpen(false)
        setIsSendOpen(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
            position: 'absolute' ,
            top: '50%',
            left: '50%',
            borderRadius:"8px",
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        "@media(max-width:300px)":{
            width:"280px",
            padding:"10px 5px !important"
        },
        "@media(min-width:301px) and (max-width:400px)":{
            width:"350px",
            padding:"10px 5px !important"
        },
        "@media(min-width:401px) and (max-width:600px) ":{
            width:"400px"
        },
        "@media(min-width:601px)":{
            width:"550px"
        },
    }}>
            <Grid sx={{marginBottom:"40px"}} spacing={2} container>
                <Grid item   lg={6} md={6} sm={6} xl={6} xs={6}>
                    <Typography sx={{fontWeight:"700",fontSize:"20px",fontFamily:"Montserrat",color:"black"}}>{isSendOpen?"Send Points":"Add Points"}</Typography>
                </Grid>
                <Grid item sx={{display:"flex",justifyContent:"end"}} lg={6} md={6} sm={6} xl={6} xs={6}>
                    <Typography sx={{ fontWeight:"600",fontSize:"16px",fontFamily:"Montserrat",color:"black"}}>Balance : <span style={{color:"grey"}}>{balance} pts</span></Typography>
                </Grid>
            </Grid>
            
          {isAddOpen?<Add_Balance  charge_fee={charge_fee} conversion_rate={conversion_rate} />:<Send_Balance  />}    
        </Box>
      </Modal>

    </> );
}
const mapStateToProps = (state)=>{
    return{
        lang:state.lang,
        cart:state.cart,
        balance:state.balance,
        charge_fee:state.charge_fee,
        conversion_rate:state.conversion_rate
    }
}
export default connect(mapStateToProps,null)(Balance);