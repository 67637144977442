import React, { useEffect ,useState} from 'react';
import styled from 'styled-components';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination,Autoplay } from 'swiper/modules';
import brand_1 from "../../../assets/images/Vector (1).png"
import { Skeleton } from '@mui/material';

const Container = styled.div`
    padding:10px 10px;
    position:relative;
    height:180px;
    z-index:0;

`
const Border = styled.div`
    height:143.44px;
    position:absolute;
    right:10px;
    left:10px;
    top:0;
    bottom:0;
    z-index: 1;
    border : 2px solid grey;
    border-radius:4px;

`
const Title= styled.h1`
    position:absolute;
    width:250px;
    right:45%;
    top:-20px;
    background:white;
    padding:10px 10px;
    z-index:5;
    text-align:center;
    font-size : ${props => props.theme.typography.Montserrat_Bold_lg.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_Bold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_Bold_lg.weight};
    line-height :  ${props => props.theme.typography.Montserrat_Bold_lg.lineHeight};
    left:50%;
    transform : translateX(-50%);
`
const CardImgs = styled.div`
    position:absolute;
    height:143.44px;
    right:20px;
    left:20px;
    top:0;
    bottom:0;
    z-index: 10;
    display:flex;
    align-items:center;
    @media(max-width:992px){
        right:0px;
        left:0px;
        justify-content : space-between;
    }
`
function Brand({title,lang}) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [data,setData] = useState([])
    const [brands,setBrands] = useState([])
    useEffect(()=>{
        get_Data()
    },[])
    const get_Data = async () =>{
      setIsLoading(true)
      const {response , message} = await Helper.Get({
          url:apiRoutes.brand.view,
          data:{results:20}
      })
      if(response){
          response.data.forEach(ele=>{
              setData(prev=>[...prev,{
                  name:ele.translations[lang]?ele.translations[lang].name:"",
                  photo:ele.photo?ele.photo:brand_1,
                  id:ele.id
              }])
          })
          setIsLoading(false)
      }else{
        //   if(typeof(message) === "string"){
        //       enqueueSnackbar(message,{variant:"error"}) 
        //   }else{
        //       Object.keys(message).map((key)=>{
        //           enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error"}) 
        //       })
        //   }
         
        //   setIsLoading(false)
      }

  }
    return ( <Container>
        <Title>Our Brands</Title>
        <Border/>
        <CardImgs>
                <Swiper
                slidesPerView={6}
                spaceBetween={30}
                freeMode={true}
                pagination={false}
                autoplay={{
                    delay:1500
                }}
                breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 3,
                      centeredSlides:true,
        
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 3,
         
                    //   spaceBetween: 24,
                    },
                    // when window width is >= 640px
                    500: {
                        slidesPerView: 3,
                      //   spaceBetween: 24,
                      },
                    992:{
                        slidesPerView: 3,
                    },
                    1235: {
                      slidesPerView: 6,
                    //   spaceBetween: 32,
                    //   slidesPerGroup: 1,
                    },
                    1336: {
                      slidesPerView: 6,
                    //   spaceBetween: 32,
                    },
                  }}
                modules={[FreeMode,Autoplay, Pagination]}
                className="mySwiper"
            >
                {isLoading?[0,1,2,3,4].map((e)=>{
                    return<SwiperSlide >
                    <Skeleton height={60} animation="wave" />
                </SwiperSlide>
                }):data.map((e)=>{
                   return <SwiperSlide >
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",background:"white"}}>
                            <img onClick={()=>{
                                navigate(`/products/brand/${e.id}`)
                            }} style={{padding:"20px 80px",height:"60px",objectFit:"cover"}} alt={e.name} src={e.photo} />
                        </div>
                    </SwiperSlide>
                })}

            </Swiper> 
        </CardImgs>

        
    </Container>);
}

export default Brand;