import { NavLink } from "react-router-dom";
import styled , {keyframes}  from "styled-components";


const width = window.innerWidth
export const Countainer = styled.div`
    width:100%;
    background:white;
    top:0;
    height:90px;
    display:flex;
    padding:10px 25px 10px 25px;
    box-shadow: 2px 2px 8px #80808047;
    @media(max-width:400px){
        height:130px;
        padding:10px 0;
        display:block
    }
    @media(min-width:401px) and (max-width:992px){
        height:130px;
        padding:10px 10px 10px 10px;
        display:block
    }


`
export const SubContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    padding:0 5px;

    justify-content:space-between;
    @media(max-width:992px){
        // padding:0 25px 0 0;  
    }
`
export const ContentLogo = styled.div`
display:flex;
corsor:pointer;

@media(max-width:300px){
    width:34%;
}
@media(min-width:301px) and (max-width:400px){
    width:50%;
}
`

export const Search = styled.div`
    width:50%;
    padding:0 10px ;
    display:flex;
    justify-content:center;
    align-items:center;
    @media(max-width:992px){
        width:100%;
        padding:10px 10px ;
    }

`
export const Menu = styled.div`
    display:flex;
    width:50%;
    justify-content:end
`


export const Logo = styled.div`
    width: 100%;
    display:flex;
    justify-content:start;
    align-items:center;
    // width:200px;
    cursor:pointer; 
    z-index:10;
    & .path-fill{
        fill:${props => props.theme.colors.main.default};
    }
    & .path-stroke{
        stroke:${props => props.theme.colors.main.default};
    }
    $:hover{
        cursor:pointer;
    }
    @media(max-width:400px){
        width: 100%;
    }
    @media(min-width:993px){
        width: 25%;
    }


    `
export  const Content = styled.div`
        width:114px;
        position:relative;
        height:100%

    `
    
export const Span = styled.span`
        position:absolute;
        top:0px;
        right:-10px;
        transform:translateY(-15%) ;
        font-size:15px;
        @media(max-width:992px){
            font-size:8px;  
            right:-7px;
        }
    

    `
export const Hint = styled.span`
        color:${props => props.theme.colors.main.hint };
        font-size:12px;
        position:absolute;
        top:40px;
        font-weight:${props => props.theme.typography.Montserrat_Regular_sm.weight};
        font-family:${props => props.theme.typography.Montserrat_Regular_sm.fontFamily};
        line-height:${props => props.theme.typography.Montserrat_Regular_sm.lineHeight};
    
        @media(max-width:992px){
            // top:30px;
            // font-size:8px;
            top:21px;
            font-size:6px;
        }
        `



export const LogoTitle = styled.div`
color:${props => props.theme.colors.main.default};
position:absolute;
top:15px;
font-size:20px;
font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};

@media(max-width:992px){
    // top:10px;
    // font-size:15px;
    top:3px;
    font-size:11px;
}
`
// Search Input
export const InputSearch =  styled.input`
display: block;
width:100%;
background: ${prop=>prop.theme.colors.background.input};
height:48px;
padding-left: 65px;
padding-top:5px;
font-size:${props => props.theme.typography.Montserrat_Regular_sm.fontSize};
font-weight:${props => props.theme.typography.Montserrat_Regular_sm.weight};
font-family:${props => props.theme.typography.Montserrat_Regular_sm.fontFamily};
line-height:${props => props.theme.typography.Montserrat_Regular_sm.lineHeight};
color: ${props => props.theme.colors.text.black};
border:none;
transition:0.2s;
border-radius: 5px;
&:focus {
  outline: none;
  border:1.5px solid  inherit;
  box-shadow:rgb(238 238 216) 0px 3px 12px
  };

&::placeholder{
  color:black;
} 
`;

export const Icon = styled.div`
    position: absolute;
    top:56%;
    transform:translateY(-50%);
    left:24px;
    z-index:3
`