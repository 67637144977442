import React ,{useState,useEffect} from 'react';
import styled from 'styled-components';

//mui Components 
import {Button} from '@mui/material';
import { useSnackbar } from "notistack";
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
//animation
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
//redux
import { connect } from 'react-redux';


// const ButtonApplication = styled(Button)`
// border:0.603px solid #FFF !important;
// width: 160.724px !important;
// // height: 60px !important;
// justify-content:unset !important;
// text-transform: capitalize !important
// `
// const Icon = styled.span`
//     margin-right:4px;
//     display:flex

// `
// const ContentButton= styled.div`
// display:flex;
// align-items:center;
// padding:5px;
// `
// const ButtonTitle=styled.h2`
// font-size:15px; 
// text-align: start;
// line-height:13px;
// @media(max-width:400px){
//     font-size:13px; 
// }
// @media(min-width:992px) and (max-width:1100px){
//     font-size:13px; 
// }



// `
// const ButtonSubTitle=styled.h6`
//     font-size:10px;
//     font-weight: 500;
//     text-align: start;
//     line-height:17px;
//     @media(max-width:400px){
//         font-size:8px; 
//     }
//     @media(min-width:992px) and (max-width:1100px){
//         font-size:8px; 
//     }

// `
// const DivButton = styled.div`
//     line-height: 14px;
//     display:flex;
//     align-items:center;
//     justify-content:center

// `
// const Link= styled.a`
//     color:white

// `



const ButtonApplication = styled(Button)`
border:0.603px solid #FFF !important;
width: 160.724px !important;
// height: 60px !important;
justify-content:unset !important;
text-transform: capitalize !important
`
const Icon = styled.span`
    margin-right:4px;
    display:flex

`
const ContentButton= styled.span`
display:flex;
align-items:center;
padding:5px;
`
const ButtonTitle=styled.span`
font-size:15px; 
text-align: start;
line-height:13px;
@media(max-width:400px){
    font-size:13px; 
}
@media(min-width:992px) and (max-width:1100px){
    font-size:13px; 
}



`
const ButtonSubTitle=styled.span`
    font-size:10px;
    font-weight: 500;
    text-align: center;
    line-height:17px;
    display:block;
    @media(max-width:400px){
        font-size:8px; 
    }
    @media(min-width:992px) and (max-width:1100px){
        font-size:8px; 
    }

`
const DivButton = styled.span`
    line-height: 14px;
    // display:flex;
    align-items:center;
    justify-content:center

`
const Link= styled.a`
    color:white

`
function ButtonApp({isHero,app_store,google_play}) {
    // AOS.init()
    const isSmall = useBreakpoint(993)
    const [application, setApplication] = useState({
        "google_play":{
            title:"Google Play",
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <g clipPath="url(#clip0_41_63653)">
            <path d="M18.1621 15.1234L3.01017 29.7136C2.30063 29.2287 1.87937 28.4222 1.88682 27.5628V2.68404C1.87937 1.82468 2.30063 1.01811 3.01017 0.533203L18.1621 15.1234Z" fill="white"/>
            <path d="M18.1618 14.7058L3.49379 0.581457C4.17389 0.236042 4.98617 0.250405 5.65716 0.628771L5.66056 0.630658L22.9608 10.0928L18.1618 14.7058Z" fill="white" stroke="#D7D7D7" strokeWidth="0.6"/>
            <path d="M29.8871 15.1217C29.897 16.0918 29.3698 16.9879 28.5172 17.4507L23.462 20.218L18.1602 15.1217L23.462 10.0254L28.5172 12.7927C29.3698 13.2555 29.897 14.1516 29.8871 15.1217Z" fill="white"/>
            <path d="M22.9608 20.1523L5.66055 29.6145L5.65915 29.6152C4.98636 29.9879 4.17663 30.0023 3.49486 29.6626L18.1619 15.5393L22.9608 20.1523Z" fill="white" stroke="#D7D7D7" strokeWidth="0.6"/>
            </g>
            <defs>
            <clip-path id="clip0_41_63653">
            <rect width="30.1724" height="30.1724" fill="white" transform="translate(0.800781 0.0332031)"/>
            </clip-path>
            </defs>
            </svg>,
            href:google_play,
            sub_Title:"Andriod App On"
        },
        "app_store":{
            title:"App Store",
            icon:<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
            <g clipPath="url(#clip0_749_16990)">
            <path d="M11.1582 30.7931C6.66763 30.7672 2.95312 21.6005 2.95312 16.9321C2.95312 9.30623 8.6738 7.63673 10.8785 7.63673C11.8721 7.63673 12.933 8.02691 13.8688 8.37218C14.5231 8.61303 15.1999 8.86166 15.5763 8.86166C15.8016 8.86166 16.3325 8.65016 16.8012 8.46458C17.8008 8.06663 19.0447 7.57202 20.4933 7.57202C20.4958 7.57202 20.4993 7.57202 20.5019 7.57202C21.5835 7.57202 24.8629 7.80939 26.8346 10.7703L27.2964 11.4643L26.6318 11.9659C25.6822 12.6824 23.9496 13.9893 23.9496 16.5782C23.9496 19.6444 25.9118 20.8236 26.8544 21.3908C27.2706 21.6411 27.7013 21.8992 27.7013 22.4638C27.7013 22.8324 24.7594 30.7474 20.4872 30.7474C19.4418 30.7474 18.7029 30.4332 18.0511 30.1561C17.3916 29.8755 16.8227 29.6338 15.8827 29.6338C15.4061 29.6338 14.8036 29.8591 14.1657 30.0983C13.2939 30.4236 12.3072 30.7931 11.1875 30.7931H11.1582Z" fill="white"/>
            <path d="M20.9458 0.620697C21.0571 4.63245 18.188 7.41562 15.3223 7.24107C14.8501 4.03955 18.1877 0.620697 20.9458 0.620697Z" fill="white"/>
            </g>
            <defs>
            <clip-path id="clip0_749_16990">
            <rect width="30.1724" height="30.1724" fill="white" transform="translate(0.242188 0.620697)"/>
            </clip-path>
            </defs>
            </svg>,
            href:app_store,
            sub_Title:"Download on the"
        }
    })
    return (<>
    {app_store && google_play && Object.keys(application).map((key ,index)=>(<ButtonApplication 
                key={application[key].title}
                // data-aos={index===1?"fade-left":"fade-right"}
                // data-aos-delay={isHero?(isSmall?"600":"2000"):"500"}
                // data-aos-duration="1000"
                // data-aos-mirror="true"
                sx={{
                    background:"#D80621",
                    borderRadius:"4px",
                    color:"white !important",
                    width:"150px",
                    transition:"0.4s background ease",
                    "&:hover":{
                        color:"black !important",
                        background:"#e3a404 !important",
                        color:"black !important"
                    }
                    }}>
            <Link href={application[key].href?application[key].href:"#"}>
            <ContentButton>
            <Icon>
            {application[key].icon}

            </Icon>
            <DivButton>
                {/* <span> */}
                <ButtonSubTitle>
                {application[key].sub_Title}
                </ButtonSubTitle>
                <ButtonTitle>
            
                {application[key].title}
                </ButtonTitle>
                    {/* </span> */}
            </DivButton>
        </ContentButton>
            </Link>
        
    </ButtonApplication>))}

            

    
    </>  );
}
const mapStateToProps = (state)=>{
    return{
            google_play : state.google_play,
            app_store: state.app_store

    }
}
export default connect(mapStateToProps,null)(ButtonApp);