import { Avatar, Box } from "@mui/material";
import { useEffect, useState } from "react";
import ConversationComponent from "./conversationComponent";
// styles
import {BasicContainer,Header ,Content,RecieverDiv,SenderDiv,SendDiv} from "../../styles/popupChat";
import { connect } from "react-redux";
import { chat_open_popup, send_msg_dir } from "../../redux/action";
import { useNavigate } from "react-router-dom";

function PopupChat({send_msg,isOpenConversation,isOpenPopup , userOther,popupChat}) {
    const navigate = useNavigate()
    const [reciever, setReciever] = useState({
        photo:"",
        username:"",
        id:""
    })
    const [closeAll, setCloseAll] = useState(false)
    const [closeConversation,setCloseConversation] = useState(false)
    const [listConversations, setListConversations] = useState([])
    useEffect(()=>{
        if(localStorage.getItem("userOther")){
            setReciever(JSON.parse(localStorage.getItem("userOther")))
        }else{
            setReciever(userOther) 
        }
     
        if(localStorage.getItem("users")){
            const users = JSON.parse(localStorage.getItem("users"))
            if(users.length == 5){
                users.shift()
            }
            localStorage.setItem("users",JSON.stringify(users))
            setListConversations(users)
        }
        if(localStorage.getItem("forChat")){
            const infoChat = JSON.parse(localStorage.getItem("forChat"))
            setCloseConversation(infoChat.isOpenConversation)
        }else{
            setCloseConversation(isOpenConversation)
        }
    },[userOther,isOpenConversation,isOpenPopup])

    const HandleCloseAll = ()=>{
        localStorage.removeItem("forChat")
        localStorage.removeItem("users")
        popupChat(false,false,{})
    }
    const navigateToChatInterface =()=>{
        navigate(`/messages/${reciever.id}`,{state:{
            message:"Out"
        }})
        localStorage.setItem("userOther",JSON.stringify({
            username:reciever.username,
            photo:reciever.photo?reciever.photo:"",
            id: reciever.id
        }))
        localStorage.removeItem("forChat")
        localStorage.removeItem("users")
        popupChat(false,false,{})

    }
    const addTolist = ()=>{
        const infoForChat = JSON.parse(localStorage.getItem("forChat"))
        infoForChat["isOpenConversation"] = false
        localStorage.setItem("forChat",JSON.stringify(infoForChat))
        if(localStorage.getItem("users")){
            const users = JSON.parse(localStorage.getItem("users"))
            const filter_array = []
            users.forEach(element => {
                filter_array.push(element.id)
            });
            if(!filter_array.includes(reciever.id)){
                users.push(reciever)
            }
            localStorage.setItem("users",JSON.stringify(users))
            setListConversations(users)
        }else{
            const array = []
            array.push(reciever)
            localStorage.setItem("users",JSON.stringify(array))  
            setListConversations(array)
        }
        popupChat(true,false,{})
        setCloseConversation(false)
    }
    const removeUserFromList = (id)=>{
        const list = [...listConversations]
        const new_list = list.filter(e=>(e.id != id))
        setListConversations(new_list)
        localStorage.setItem("users",JSON.stringify(new_list))
        if(new_list.length==0){
            localStorage.removeItem("forChat")
            localStorage.removeItem("users")
            popupChat(false,false,{})  
            setCloseConversation(false)
        }

    }
    const closeConversationOpened = ()=>{
        const users = [...listConversations]
        const new_users = users.filter(e=>e.id!=userOther.id)
        if(new_users.length==0){
            localStorage.removeItem("forChat")
            localStorage.removeItem("users")
            popupChat(false,false,{}) 
        }else{
            setListConversations(new_users)
            const userLocalStorage = JSON.parse(localStorage.getItem("forChat"))
            userLocalStorage["isOpenConversation"] = false
            localStorage.setItem("forChat",JSON.stringify(userLocalStorage))
            localStorage.setItem("users",JSON.stringify(new_users))
            popupChat(true,false,userOther)  
            
        }
        setCloseConversation(false)
       
    }
    return (  <BasicContainer>
        {closeConversation &&<Box className="chat-interface" >
        <Header>
            <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
            <Avatar src={reciever.image} alt={`Chat_popup_${reciever.username}`} sx={{width:32,height:32}}/>
                <h3>
                    {reciever.username}
                </h3>
            </Box>
            <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                
                <Box onClick={addTolist} sx={{display:"flex",alignItems:"center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="5" viewBox="0 0 14 2" fill="none">
                        <path d="M13 1.99609H1C0.734784 1.99609 0.48043 1.89074 0.292893 1.7032C0.105357 1.51566 0 1.26131 0 0.996094C0 0.730877 0.105357 0.476524 0.292893 0.288987C0.48043 0.101451 0.734784 -0.00390625 1 -0.00390625H13C13.2652 -0.00390625 13.5196 0.101451 13.7071 0.288987C13.8946 0.476524 14 0.730877 14 0.996094C14 1.26131 13.8946 1.51566 13.7071 1.7032C13.5196 1.89074 13.2652 1.99609 13 1.99609Z" fill="white"/>
                    </svg>
                </Box>
                <Box onClick={closeConversationOpened}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 43 43" fill="none">
                        <path d="M1.20011 1.20988C1.97002 0.440195 3.01411 0.0078125 4.10277 0.0078125C5.19143 0.0078125 6.23552 0.440195 7.00543 1.20988L21.5228 15.7273L36.0402 1.20988C36.8146 0.462008 37.8516 0.0481868 38.9281 0.0575411C40.0046 0.0668954 41.0343 0.498677 41.7955 1.25989C42.5568 2.0211 42.9885 3.05084 42.9979 4.12731C43.0072 5.20379 42.5934 6.24087 41.8456 7.0152L27.3281 21.5326L41.8456 36.05C42.5934 36.8243 43.0072 37.8614 42.9979 38.9379C42.9885 40.0144 42.5568 41.0441 41.7955 41.8053C41.0343 42.5665 40.0046 42.9983 38.9281 43.0077C37.8516 43.017 36.8146 42.6032 36.0402 41.8553L21.5228 27.3379L7.00543 41.8553C6.23111 42.6032 5.19402 43.017 4.11755 43.0077C3.04107 42.9983 2.01133 42.5665 1.25012 41.8053C0.488911 41.0441 0.0571297 40.0144 0.0477755 38.9379C0.0384212 37.8614 0.452242 36.8243 1.20011 36.05L15.7175 21.5326L1.20011 7.0152C0.430429 6.24528 -0.00195312 5.20119 -0.00195312 4.11254C-0.00195312 3.02388 0.430429 1.97979 1.20011 1.20988Z" fill="white"/>
                    </svg>
                </Box>
                <Box onClick={navigateToChatInterface}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 38 38" fill="none">
                        <path d="M36 25.1818V36H25.1818M35.0148 35.0119L23.6364 23.6364M2 12.8182V2H12.8182M2.98523 2.98813L14.3636 14.3636M25.1818 2H36V12.8182M35.0119 2.98523L23.6364 14.3636M12.8182 36H2V25.1818M2.98813 35.0148L14.3636 23.6364" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </Box>
            </Box>
            

        </Header>
        <Content>
            <ConversationComponent forPopupChat={true} id={reciever.conversation_id} userOther={reciever}/>
        </Content>

        
    </Box>}
        
    <Box className="list-chat">
        <Box>
    
            <Box onClick={HandleCloseAll} className="close-list">
                
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 43 43" fill="none">
                        <path d="M1.20011 1.20988C1.97002 0.440195 3.01411 0.0078125 4.10277 0.0078125C5.19143 0.0078125 6.23552 0.440195 7.00543 1.20988L21.5228 15.7273L36.0402 1.20988C36.8146 0.462008 37.8516 0.0481868 38.9281 0.0575411C40.0046 0.0668954 41.0343 0.498677 41.7955 1.25989C42.5568 2.0211 42.9885 3.05084 42.9979 4.12731C43.0072 5.20379 42.5934 6.24087 41.8456 7.0152L27.3281 21.5326L41.8456 36.05C42.5934 36.8243 43.0072 37.8614 42.9979 38.9379C42.9885 40.0144 42.5568 41.0441 41.7955 41.8053C41.0343 42.5665 40.0046 42.9983 38.9281 43.0077C37.8516 43.017 36.8146 42.6032 36.0402 41.8553L21.5228 27.3379L7.00543 41.8553C6.23111 42.6032 5.19402 43.017 4.11755 43.0077C3.04107 42.9983 2.01133 42.5665 1.25012 41.8053C0.488911 41.0441 0.0571297 40.0144 0.0477755 38.9379C0.0384212 37.8614 0.452242 36.8243 1.20011 36.05L15.7175 21.5326L1.20011 7.0152C0.430429 6.24528 -0.00195312 5.20119 -0.00195312 4.11254C-0.00195312 3.02388 0.430429 1.97979 1.20011 1.20988Z" fill="white"/>
                    </svg>
                
            </Box>
            {listConversations.map((ele,i)=>(<Box key={`user_${ele.username}`} className="item">
                <Box onClick={()=>{removeUserFromList(ele.id)}} className="close-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 43 43" fill="none">
                        <path d="M1.20011 1.20988C1.97002 0.440195 3.01411 0.0078125 4.10277 0.0078125C5.19143 0.0078125 6.23552 0.440195 7.00543 1.20988L21.5228 15.7273L36.0402 1.20988C36.8146 0.462008 37.8516 0.0481868 38.9281 0.0575411C40.0046 0.0668954 41.0343 0.498677 41.7955 1.25989C42.5568 2.0211 42.9885 3.05084 42.9979 4.12731C43.0072 5.20379 42.5934 6.24087 41.8456 7.0152L27.3281 21.5326L41.8456 36.05C42.5934 36.8243 43.0072 37.8614 42.9979 38.9379C42.9885 40.0144 42.5568 41.0441 41.7955 41.8053C41.0343 42.5665 40.0046 42.9983 38.9281 43.0077C37.8516 43.017 36.8146 42.6032 36.0402 41.8553L21.5228 27.3379L7.00543 41.8553C6.23111 42.6032 5.19402 43.017 4.11755 43.0077C3.04107 42.9983 2.01133 42.5665 1.25012 41.8053C0.488911 41.0441 0.0571297 40.0144 0.0477755 38.9379C0.0384212 37.8614 0.452242 36.8243 1.20011 36.05L15.7175 21.5326L1.20011 7.0152C0.430429 6.24528 -0.00195312 5.20119 -0.00195312 4.11254C-0.00195312 3.02388 0.430429 1.97979 1.20011 1.20988Z" fill="white"/>
                    </svg>
                </Box>
                <Avatar onClick={()=>{
                    setCloseConversation(true)
                    const userLocalStorage = JSON.parse(localStorage.getItem("forChat"))
                    userLocalStorage["isOpenConversation"] = true
                    localStorage.setItem("forChat",JSON.stringify(userLocalStorage))
                    popupChat(true,true,ele)
                    setReciever(ele)
                    send_msg("","")
                    localStorage.setItem("userOther",JSON.stringify(ele))
                    }} src={ele.image} alt={`user_${ele.username}`}/>
            </Box>))}
            
            
       
            </Box>
       
        
    </Box>
    </BasicContainer> );
}
const mapDispatchToState =(dispatch)=>{
    return{
        popupChat : (isOpen,isOpenConversation,userOther)=>{dispatch(chat_open_popup(isOpen,isOpenConversation,userOther))},
        send_msg:(msg,url)=>{dispatch(send_msg_dir(msg,url))}
    }
}
const mapStateToProps = (state)=>{
    return{
        userOther: state.userOther,
        isOpenPopup:state.isOpenPopup,
        isOpenConversation:state.isOpenConversation
    }
}
export default connect(mapStateToProps,mapDispatchToState)(PopupChat);