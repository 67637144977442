import axios from "axios";
import JSEncrypt from "jsencrypt";

const encryptRequestData = (data) => {
    let KEY = `-----BEGIN PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpacjMsYHN+81HLpDUmWpi2tu/
    pZL3nSXMXTzcSW0KIcIDBDtxLpfzEKB0slPnQu8czBP6K2COfJrTUJUdjpXzSk3F
    dfjHr3R8Pv3XwA94BkAgS4Flra4EJv9hUFBx901O003K4RbP+aOQ4ukw/OiSdrLI
    TyuMVs6U5pEYRbt+sQIDAQAB
    -----END PUBLIC KEY-----`;
    
    let encryptor = new JSEncrypt();
    encryptor.setPublicKey(KEY);
    let KeyToEncpt = 'Shake Shake';
    // return encryptor.encrypt(KeyToEncpt);
    return "acO+4JTx8lmZmOo4qZemnKS7JufhcyviuvUaz5VL7faQo60isZFx/sf7FbtNs1FlkLG03/HsIs+6odEwY/30HrST7JZaDsAmTMrvB0qm25LqxoZquKThjgW9S6NCX8lWWLhp6mUOCBfe86B0dcgEhe9SXgmFVqA8UvzZ+G+YC8Y="
};
const token = () =>{
    if(localStorage.hasOwnProperty("user")){
        return `Bearer ${JSON.parse(localStorage.getItem("user")).token}`
    }
    return ""
}
export const Helper = {
    Get : async ({url, hasToken, data=null,signal=null}) => {
       
        try{
            const response = await axios.get(url,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                },
                params : data ? data : {},
                
            }:{ params : data ? data : {},
                headers:{
                    ApiKey :encryptRequestData()
                }
            }
            )
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data}  
            }else{
                return {message:response.data.message,
                    response:response.data}
            }
            
            
        }catch(err){
            console.log(err);
            if(!err.response?.data){
                // return { message:err.message}
                return { message:"Somthing wrong, try again later."}
            }else{
                const err_response = err.response.data
                if(err.response.status == "401"){
                    // if(window.location.pathname != "/register" || !window.location.pathname != "/authentication"  ){
                    //      window.location.href = "/register"
                    // }
                    
                    return { message:"Please login or register to complete your joy.",status:401}
                }else if(err.response.status == "403"){
                    return { message:err_response.data,status:403}
                }else if(err.response.status == "422" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:422}
                        }else{
                            return { message:err_response.message,status:422}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:422}
                    }  
                }else if(err.response.status == "400" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:400}
                        }else{
                            return { message:err_response.message,status:400}
                        }
                        
                    }else{
                        console.log("4");
                        return { message:"Somthing wrong, try again later.",status:400}
                    }  
                }else if(err.response.status == "500"){
                    return { message:"Somthing wrong, try again later.",status:500}
                }else if(err.response.status == "404"){
                    return { message:"The route isn't found.",status:404} 
                }
                
            }
        
            
           
    
        
        }
    
    },
    Get_Abort : async ({url, hasToken, data=null,signal}) => {
       
        try{
            const response = await axios.get(url,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                },
                params : data ? data : {},
                signal : signal
                
            }:{ params : data ? data : {},
                headers:{
                    ApiKey :encryptRequestData()
                },
                signal : signal}
            )
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data}  
            }else{
                return {message:response.data.message,
                    response:response.data}
            }
            
            
        }catch(err){
            if(!err.response?.data){
                // return { message:err.message}
                return { message:"Somthing wrong, try again later."}
            }else{
                const err_response = err.response.data
                if(err.response.status == "401"){
                //    if(window.location.origin == "http://localhost:3000/autherization" ||window.location.origin == "http://localhost:3000/register" ){
                //     window.location.href= "http://localhost:3000/autherization"
                //    }
                    // if(window.location.pathname != "/register" ){
                    //     window.location.href = "/register"
                    //     console.log("here");
                    // }
                    // console.log(window.location.pathname == "/register");
                    return { message:"Please login or register to complete your joy.",status:401}
                }else if(err.response.status == "403"){
                    return { message:err_response.data,status:403}
                }else if(err.response.status == "422" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:422}
                        }else{
                            return { message:err_response.message,status:422}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:422}
                    }  
                }else if(err.response.status == "400" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:400}
                        }else{
                            return { message:err_response.message,status:400}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:400}
                    }  
                }else if(err.response.status == "500"){
                    return { message:"Somthing wrong, try again later.",status:500}
                }else if(err.response.status == "404"){
                    return { message:"The route isn't found.",status:404} 
                }
                
            }
            
           
    
        
        }
    
    },
    Post: async ({url, hasToken, data=null}) => {
       
        try{
            const response = await axios.post(url,data,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                    
                }
            }:{
                headers:{
                    ApiKey :encryptRequestData()
                },
            }
            )
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data
                }  
            }else{
                return {message:response.data.message,
                    response:response.data,
                }
            }
            
            
        }catch(err){
            if(!err.response?.data){
                // return { message:err.message}
                return { message:"Somthing wrong, try again later."}
            }else{
                const err_response = err.response.data
                if(err.response.status == "401"){
                    // window.location.href = "/register"
                    return { message:"Please login or register to complete your joy.",status:401}
                }else if(err.response.status == "403"){
                    return { message:err_response.data,status:403}
                }else if(err.response.status == "422" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:422}
                        }else{
                            return { message:err_response.message,status:422}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:422}
                    }  
                }else if(err.response.status == "400" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:400}
                        }else{
                            return { message:err_response.message,status:400}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:400}
                    }  
                }else if(err.response.status == "500"){
                    return { message:"Somthing wrong, try again later.",status:500}
                }else if(err.response.status == "404"){
                    return { message:"The route isn't found.",status:404} 
                }
                
            }

            // console.log(err);
            // if(!err.response?.data){
            //     return { message:err.message}
            // }else{
            //     const err_response = err.response.data
            //     if(err_response.success !== undefined && err_response.data !== undefined){
                    
            //         if(err_response.data.length>0){
            //             return { message:err_response.data}
            //         }else{
            //             return { message:err_response.message}
            //         }
                    
            //     }else{
            //         return { message:err.message}
            //     }
            // }
            
    
        
        }
    
    },
    Post_Abort: async ({url, hasToken, data=null,signal}) => {
        try{
            const response = await axios.post(url,data,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                    
                },
                signal : signal
            }:{
                headers:{
                    ApiKey :encryptRequestData()
                },
                signal : signal
            }
            )
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data
                }  
            }else{
                return {message:response.data.message,
                    response:response.data,
                }
            }
            
            
        }catch(err){
            if(!err.response?.data){
                // return { message:err.message}
                return { message:"Somthing wrong, try again later."}
            }else{
                const err_response = err.response.data
                if(err.response.status == "401"){
                    // window.location.href = "/register"
                    return { message:"Please login or register to complete your joy.",status:401}
                }else if(err.response.status == "403"){
                    return { message:err_response.data,status:403}
                }else if(err.response.status == "422" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:422}
                        }else{
                            return { message:err_response.message,status:422}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:422}
                    }  
                }else if(err.response.status == "400" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:400}
                        }else{
                            return { message:err_response.message,status:400}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:400}
                    }  
                }else if(err.response.status == "500"){
                    return { message:"Somthing wrong, try again later.",status:500}
                }else if(err.response.status == "404"){
                    return { message:"The route isn't found.",status:404} 
                }
                
            }

            // console.log(err);
            // if(!err.response?.data){
            //     return { message:err.message}
            // }else{
            //     const err_response = err.response.data
            //     if(err_response.success !== undefined && err_response.data !== undefined){
                    
            //         if(err_response.data.length>0){
            //             return { message:err_response.data}
            //         }else{
            //             return { message:err_response.message}
            //         }
                    
            //     }else{
            //         return { message:err.message}
            //     }
            // }
            
    
        
        }
    
    },
    Put: async({url, hasToken, data=null}) => {
        try{
            const response = await axios.put(url,data,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                }
            }:{
                headers:{
                    ApiKey :encryptRequestData()
                },
            }
            )
            
            
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data,
                }  
            }else{
                return {message:response.data.message,
                    response:response.data,
                }
            }
            
        }catch(err){
            console.log(err);
            if(!err.response?.data){
                return { message:err.message}
            }else{
            const err_response = err.response.data
            if(err_response.success !== undefined && err_response.data !== undefined){
                
                if(err_response.data.length>0){
                    return { message:err_response.data}
                }else{
                    return { message:err_response.message}
                }
                
            }else{
                return { message:err.message}
            }
        }
    }

    },
    Delete: async({url, hasToken, data=null}) => {
        try{
            const response = await axios.delete(url,hasToken?{
                headers:{
                    Authorization: token(),
                    ApiKey :encryptRequestData()
                }
            }:{
                headers:{
                    ApiKey :encryptRequestData()
                }
            }
            )
            if(response.success && response.status === 200){
                return {message:response.data.message,
                    response:response.data,
                }  
            }else{
                return {message:response.data.message,
                    response:response.data,
                }
            }
            
        }catch(err){
            if(!err.response?.data){
                // return { message:err.message}
                return { message:"Somthing wrong, try again later."}
            }else{
                const err_response = err.response.data
                if(err.response.status == "401"){
                    // window.location.href = "/register"
                    return { message:"Please login or register to complete your joy.",status:401}
                }else if(err.response.status == "403"){
                    return { message:err_response.data,status:403}
                }else if(err.response.status == "422" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:422}
                        }else{
                            return { message:err_response.message,status:422}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:422}
                    }  
                }else if(err.response.status == "400" ){
                    if(err_response.success !== undefined && err_response.data !== undefined ){
                        if( err_response.data.length>0){
                            return { message:err_response.data,status:400}
                        }else{
                            return { message:err_response.message,status:400}
                        }
                        
                    }else{
                        return { message:"Somthing wrong, try again later.",status:400}
                    }  
                }else if(err.response.status == "500"){
                    return { message:"Somthing wrong, try again later.",status:500}
                }else if(err.response.status == "404"){
                    return { message:"The route isn't found.",status:404} 
                }
                
            }
            // if(!err.response?.data){
            //     return { message:err.message}
            // }else{
            //     const err_response = err.response.data
            // if(err_response.success !== undefined && err_response.data !== undefined){
                
            //     if(err_response.data.length>0){
            //         return { message:err_response.data}
            //     }else{
            //         return { message:err_response.message}
            //     }
                
            // }else{
            //     return { message:err.message}
            // }
            // }
            
        }

    }
}
