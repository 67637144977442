import { ThemeProvider } from "styled-components"

const redTheme ={
    colors:{ 
      background: {
        default: "#FFFFFF",
        input:"#EEE"
      },
      text: {
        default: "#161616",
        hint: "#9f9f9f",
        black: "black",
        warning:"rgba(212, 141, 59, 1)",
        error:"#D80621",
      },
      main: {
        default: "#d80621",
        complemently: "#7f7f7f",
        subMain:"#930000",
        hint: "#9f9f9f",
      },
      dark:{
        default:"#65010E"
      },
      border:{
          gray:"#D8D8D8"
      },
      error:{
        default:"#be0d0d"
      }
    },
    // colors:{ 
    //   background: {
    //     default: "#FFFFFF",
    //     input:"#EEE"
    //   },
    //   text: {
    //     default: "#161616",
    //     hint: "#9f9f9f",
    //     black: "black",
    //   },
    //   dark:{
    //     default:"#451971",
    //   },
    //   main: {
    //     default: "#7934be",
    //     complemently: "#9f6abb",
    //     hint: "#9F9F9F",
    //   },
    //   border:{
    //       gray:"#D8D8D8"
    //   },
    //   error:{
    //     default:"#be0d0d"
    //   }
    // },
    breakpoint:{
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,     
    },
    typography:{
      Montserrat:{
        fontFamily:"Montserrat",
        weight:400,
        fontSize:"16px",
        lineHeight:"27.2px"
        
       },
      Montserrat_Bold_base:{
        fontFamily:"Montserrat",
        weight:700,
        fontSize:"16px",
        lineHeight:"24px"
        
       },
       Montserrat_Bold_lg:{
        fontFamily:"Montserrat",
        weight:700,
        fontSize:"18px",
        lineHeight:"27px"
        
       },
      Montserrat_Bold_xl:{
        fontFamily:"Montserrat",
        weight:700,
        fontSize:"18px",
        lineHeight:"30px"
        
       },
        Montserrat_Bold_2xl:{
            fontFamily:"Montserrat",
            weight:700,
            // fontSize:"24px",
            fontSize:"20px",
            lineHeight:"36px"
            
        },
        Montserrat_Bold_3xl:{
          fontFamily:"Montserrat",
          weight:700,
          fontSize:"30px",
          lineHeight:"37.5px"
          
      },
        Montserrat_Bold_4xl:{
          fontFamily:"Montserrat",
          weight:700,
          // fontSize:"36px",
          fontSize:"30px",
          lineHeight:"45px"
        },
        Montserrat_Bold_5xl:{
          fontFamily:"Montserrat",
          weight:700,
          fontSize:"48px",
          lineHeight:"48px"  
            
        },
        Montserrat_Regular_sm:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"14px",
            lineHeight:"17.5px"
        },
        Montserrat_Regular_base:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"16px",
            lineHeight:"24px" 
        },
        Montserrat_Regular_2xl:{
          fontFamily:"Montserrat",
          weight:400,
          fontSize:"24px",
          lineHeight:"36px" 
        },
        Montserrat_SemiBold_base:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"16px",
            lineHeight:"24px"  
        },
        Montserrat_SemiBold_sm:{
          fontFamily:"Montserrat",
          weight:600,
          fontSize:"14px",
          lineHeight:"17.5px"  
        },
        Montserrat_Regular_xs:{
          fontFamily:"Montserrat",
          weight:400,
          fontSize:"12px",
          lineHeight:"15px"
      },
      Montserrat_Regular_lg:{
        fontFamily:"Montserrat",
        weight:400,
        fontSize:"14px",
        lineHeight:15/10+"em"
    },
      Montserrat_Regular_xl:{
        fontFamily:"Montserrat",
        weight:400,
        fontSize:"20px",
        lineHeight:"30px" 
      },
        Montserrat_SemiBold_xl:{
          fontFamily:"Montserrat",
          weight:600,
          fontSize:"20px",
          lineHeight:"30px"  
        },
        Montserrat_SemiBold_2xl:{
          fontFamily:"Montserrat",
          weight:600,
          fontSize:"24px",
          lineHeight:"36px"  
        },
        Montserrat_SemiBold_5xl:{
          fontFamily:"Montserrat",
          weight:600,
          fontSize:"38px",
          lineHeight:"48px"  
        },
          Montserrat_SemiBold_lg:{
            fontFamily:"Montserrat",
            weight:600,
            // fontSize:"18px",
            fontSize:"14px",
            lineHeight:"27px"  
        },
        Body_Regular_Body_4_Regular_12:{
          fontFamily:"Montserrat",
          weight:400,
          fontSize:"12px",
          lineHeight:"20.4px" 
        },
        Body_Bold_Body_1_Bold_18:{
          fontFamily:"Montserrat",
          weight:700,
          fontSize:"18px",
          lineHeight:"30.6px" 
        },
        Extra_Caption_10_Regular:{
          fontFamily:"Montserrat",
          weight:400,
          fontSize:"10px",
          lineHeight:"14px" 
        },
        Extra_Price_24_SemiBold:{
          fontFamily:"Montserrat",
          weight:600,
          fontSize:"24px",
          lineHeight:"33.6px" 
        },
        Extra_Sale_16_Regular:{
          fontFamily:"Montserrat",
          weight:400,
          fontSize:"16px",
          lineHeight:"24px" 
        },
        Plus_Jakarta_Sans:{
          fontFamily:"Plus Jakarta Sans",
            weight:700,
            fontSize:"16.524px",
            lineHeight:"20.82px"
        }
    }
}

const RedTheme = ({children}) => {
    return <ThemeProvider theme={redTheme}>{children}</ThemeProvider>

}
export default RedTheme