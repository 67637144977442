import React,{useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useBreakpoint} from 'react-use-size';
//mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, Grid, Skeleton } from '@mui/material';
import Badge from '@mui/material/Badge';
//api request 
import MDButton from '../components/MDButton';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { echo_info, login } from '../redux/action';
//pages
import noNotification from "../assets/images/Items.png"
import profile from "../assets/images/profile.png"
import { Helper } from '../tools/helper';
import apiRoutes from '../apiRoutes';
import dateFormat from 'dateformat';
import axios, { Axios } from 'axios';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {  listen_note } from '../echo/echo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Head = styled.h1`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
  font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
  text-align:center
`
const Des = styled.p`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Regular_base.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Regular_base.weight};
  font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Regular_base.lineHeight};
  text-align:center
`

const TitleMenu = styled.h2`
font-size:${props => props.theme.typography.Montserrat_SemiBold_base.fontSize};
font-weight:${props => props.theme.typography.Montserrat_SemiBold_base.weight};
font-family:${props => props.theme.typography.Montserrat_SemiBold_base.fontFamily};
line-height:${props => props.theme.typography.Montserrat_SemiBold_base.lineHeight};
padding:0 10px;
`
const PathBasic = styled.path`
fill:${props=> props.theme.colors.main.complemently}
`
const Svg = styled.svg`
fill:${props=> props.theme.colors.main.default}
`
const Path= styled.path`
stroke:${props=> props.theme.colors.main.default};

`
const PathFill = styled.path`
fill:${props=> props.theme.colors.main.default}
`
function NotificationMenu({name,lang,notification_info_redux}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSoSmall = useBreakpoint(600)
    const [ nameMenu, setNameMenu ] = React.useState("")
    const navigate = useNavigate()
    const [Notification,setNotification] = React.useState([])
    const [New,  setNew] = React.useState(null)
    const [count,setCount] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
    
    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
      if(localStorage.hasOwnProperty("user")){
        get_notification(signal)
        // listen_note()
        // setNew(localStorage.getItem("notification"))
    }
    return()=>{
      controller.abort()
    }
    },[notification_info_redux])
    const get_notification = async(signal) => {
      setIsLoading(true)
      const {response, message} = await Helper.Get_Abort({
        url:apiRoutes.notification.view,
        signal:signal,
        hasToken:true
      })
      if(response){
        setNotification(response.data)
        setNew(response.new)
        setIsLoading(false)
      }
    }
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setNew(0)
        localStorage.removeItem("notification")
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
      setAnchorEl(null);
    };
    const handleNavigate = (e)=>{
      setAnchorEl(null);
      navigate(e)
    }
    React.useEffect(()=>{
      setNameMenu(name)

    })

    return (
      <React.Fragment>
        <li className='menu_li'>
          {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
          <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
          <Tooltip title={"Notification" }>
            <Box
              onClick={handleClick}
              size="small"
              sx={{ ml: 0 ,
              display:"flex",
              alignItems:"center",
              padding:"0 !important",
              "@media(max-width:319px)":{
                marginRight:"8px"
              },
              "& .path-nav-fill":{
                fill:"grey",
                transition:"0.3s"
              },
              "&:hover":{
                cursor:"pointer",
                  "& .path-nav-fill":{
                    
                    fill:"#D80621"
                  }
                }
            
            }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {/* <Avatar onClick={handleClose} sx={{background:"white", width: 32, height: 32 }}> */}
                <Badge color="error" badgeContent={New}>
                  {/* <svg  xmlns="http://www.w3.org/2000/svg" width={isSoSmall?"18":"24"} height={isSoSmall?"18":"24"} viewBox="0 0 24 24" fill="none">
                <PathBasic d="M10.146 3.24792C10.2954 2.87864 10.5517 2.56238 10.882 2.3397C11.2124 2.11701 11.6017 1.99805 12 1.99805C12.3984 1.99805 12.7877 2.11701 13.118 2.3397C13.4483 2.56238 13.7046 2.87864 13.854 3.24792C15.3328 3.65461 16.6371 4.53548 17.5668 5.75526C18.4964 6.97504 19 8.46627 19 9.99992V14.6969L20.832 17.4449C20.9325 17.5955 20.9902 17.7706 20.999 17.9514C21.0078 18.1322 20.9673 18.3121 20.8819 18.4717C20.7965 18.6313 20.6693 18.7648 20.514 18.8578C20.3587 18.9508 20.1811 19 20 18.9999H15.465C15.3446 19.833 14.9281 20.5949 14.2917 21.1459C13.6554 21.6969 12.8418 22.0001 12 22.0001C11.1583 22.0001 10.3447 21.6969 9.70833 21.1459C9.07197 20.5949 8.65543 19.833 8.53503 18.9999H4.00003C3.81898 19 3.64133 18.9508 3.48602 18.8578C3.33071 18.7648 3.20356 18.6313 3.11815 18.4717C3.03274 18.3121 2.99227 18.1322 3.00106 17.9514C3.00984 17.7706 3.06755 17.5955 3.16803 17.4449L5.00003 14.6969V9.99992C5.00003 6.77592 7.18003 4.05992 10.146 3.24792ZM10.586 18.9999C10.6893 19.2926 10.8808 19.546 11.1342 19.7252C11.3875 19.9045 11.6902 20.0007 12.0005 20.0007C12.3109 20.0007 12.6136 19.9045 12.8669 19.7252C13.1202 19.546 13.3117 19.2926 13.415 18.9999H10.585H10.586ZM12 4.99992C10.6739 4.99992 9.40218 5.52671 8.46449 6.46439C7.52681 7.40207 7.00003 8.67384 7.00003 9.99992V14.9999C7.00007 15.1975 6.94161 15.3906 6.83203 15.5549L5.86903 16.9999H18.13L17.167 15.5549C17.0578 15.3905 16.9997 15.1974 17 14.9999V9.99992C17 8.67384 16.4732 7.40207 15.5356 6.46439C14.5979 5.52671 13.3261 4.99992 12 4.99992Z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width={isSoSmall?"18":"24"} height={isSoSmall?"18":"24"} viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_515_61076)">
                  <path className='path-nav-fill' d="M21.6732 18.5536C21.0303 17.9805 20.4675 17.3235 19.9999 16.6003C19.4894 15.602 19.1834 14.5117 19.0999 13.3936V10.1003C19.1043 8.344 18.4672 6.64658 17.3084 5.32691C16.1495 4.00724 14.5486 3.15617 12.8065 2.93359V2.07359C12.8065 1.83755 12.7128 1.61118 12.5459 1.44427C12.379 1.27736 12.1526 1.18359 11.9165 1.18359C11.6805 1.18359 11.4541 1.27736 11.2872 1.44427C11.1203 1.61118 11.0265 1.83755 11.0265 2.07359V2.94693C9.30004 3.18555 7.71852 4.04176 6.57489 5.357C5.43126 6.67223 4.80302 8.35736 4.80654 10.1003V13.3936C4.72304 14.5117 4.41705 15.602 3.90654 16.6003C3.44712 17.3218 2.89333 17.9788 2.25987 18.5536C2.18876 18.6161 2.13176 18.693 2.09268 18.7792C2.0536 18.8654 2.03332 18.9589 2.0332 19.0536V19.9603C2.0332 20.1371 2.10344 20.3066 2.22847 20.4317C2.35349 20.5567 2.52306 20.6269 2.69987 20.6269H21.2332C21.41 20.6269 21.5796 20.5567 21.7046 20.4317C21.8296 20.3066 21.8999 20.1371 21.8999 19.9603V19.0536C21.8997 18.9589 21.8795 18.8654 21.8404 18.7792C21.8013 18.693 21.7443 18.6161 21.6732 18.5536ZM3.41987 19.2936C4.04014 18.6944 4.58627 18.0229 5.04654 17.2936C5.68961 16.0879 6.06482 14.7576 6.14654 13.3936V10.1003C6.1201 9.31895 6.25115 8.54031 6.5319 7.81071C6.81265 7.0811 7.23734 6.41545 7.7807 5.85339C8.32406 5.29134 8.97496 4.84437 9.69466 4.53911C10.4144 4.23385 11.1881 4.07653 11.9699 4.07653C12.7516 4.07653 13.5254 4.23385 14.2451 4.53911C14.9648 4.84437 15.6157 5.29134 16.159 5.85339C16.7024 6.41545 17.1271 7.0811 17.4078 7.81071C17.6886 8.54031 17.8196 9.31895 17.7932 10.1003V13.3936C17.8749 14.7576 18.2501 16.0879 18.8932 17.2936C19.3535 18.0229 19.8996 18.6944 20.5199 19.2936H3.41987Z" />
                  <path className='path-nav-fill' d="M11.9996 22.854C12.4195 22.8443 12.8225 22.6864 13.1373 22.4083C13.4521 22.1301 13.6583 21.7496 13.7196 21.334H10.2129C10.2759 21.7609 10.4918 22.1503 10.8204 22.4299C11.1491 22.7095 11.5681 22.8602 11.9996 22.854Z" />
                  </g>
                  <defs>
                  <clip-path id="clip0_515_61076">
                  <rect width="24" height="24" fill="white"/>
                  </clip-path>
                  </defs>
                </svg>
                </Badge>
               
                
              {/* </Avatar> */}
            </Box>
          </Tooltip>
          
        </li>
        { localStorage.hasOwnProperty("user")?<Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          
          PaperProps={{
            elevation: 0,
            sx: {
              width:"400px",
              overflow: 'visible',
              overflowY:"",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              }
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {localStorage.hasOwnProperty("user")?<Box>
            <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",borderBottom:"1px solid #8080802e",}}>
                     <TitleMenu>
                     Notification
                   </TitleMenu> 
                    <Button onClick={()=>{
                       handleClose()
                       navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/notification`)
                      
                  }} disabled={isLoading}>View all</Button>
                  </Box>
                  
                  {isLoading?<Box className="scroll-div">
        
               <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",borderBottom:"1px solid #8080802e",}}>
                     <TitleMenu>
                     Notification
                   </TitleMenu> 
                    <Button onClick={()=>{
                       handleClose()
                       navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/notification`)
                      
                  }} disabled>View all</Button>
                  </Box>
              {[1,2,3,4,5].map((e,index)=>{
               return <Box key={`loading_account_menu_${index}`} style={{position:"relative"}} >
                 
                 
                    <Box sx={{display:"flex",
                    padding:"4px ",
                    borderBottom:"1px solid #8080802e",
                    transition:"0.2s ease-in",
                         "&:hover":{
                           cursor:"pointer",
                           background:"rgb(192 192 192 / 39%)"
                         }
                     }}>
                       <Box sx={{width:"100px",padding:"5px"}}>
                           <div style={{display:"flex",justifyContent:"center"}}><IconButton>
                             <Skeleton animation="wave"  width={20} />
                             
                           </IconButton></div> 
                           
                       </Box>
                       <Box sx={{padding:"5px"}}>
                         <Typography >
                         <Skeleton animation="wave"  width={120} />
                         
                       </Typography>
                       <Box sx={{color:"grey"}} >
                       <Skeleton animation="wave"  width={250} />
                       </Box>
                     
                       </Box>
                    </Box>
                 
                
            </Box>
            })}
           </Box>:(Notification.length<=0?<Box style={{position:"relative"}} >
             <Box sx={{display:"flex",justifyContent:"center"}}>
               <img src={noNotification}/>
             </Box> 
             <Box >
               <Head>No Notifications</Head>
               <Des>
               You don’t have any notifications.
               </Des>
             </Box>
          </Box>:<Box>
                  <Box  className="scroll-div-dropdown" >
                  {Notification.map((e,index)=>{
                  return <Box key={e.translations[lang]?e.translations[lang].name:`Notification_${index}`} style={{position:"relative"}} >    
                       <Box sx={{display:"flex",
                      padding:"0px 10px",
                      alignItems:"center",
                      //  padding:"4px 10px 8px 10px",
                       borderBottom:"1px solid #8080802e",
                       transition:"0.2s ease-in",
                            "&:hover":{
                              cursor:"pointer",
                              background:"rgb(192 192 192 / 39%)"
                            }
                        }} onClick={()=>{
                          if(e.type=="1"){
                                navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/orderHistory/${e.type_id}`)
                          }else if(e.type == "2"){
                            navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/balance`)
                          }else if(e.type == "3"){
                            navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/shakes/${e.type_id}`)
                          }else if(e.type == "4"){
                            navigate(`/notification/${e.type_id}`)
                          }else if(e.type == "5"){
                            navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/incomingOrder/${e.type_id}`)
                          }else if(e.type == "8"){
                            navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/info/friendRequests`)
                          }
                          handleClose()
                        }}>
                          <Box sx={{width:"37px",height:"37px",padding:"5px",display:"flex",justifyContent:"center",alignItems:"center",backgroundColor:"#f1f1f1",zIndex:"-10",borderRadius:"50%"}}>
                              <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <IconButton >
                                  <img style={{width: "20px",height: "20px",borderRadius:"50%",objectFit:"contain"}} src={e.photo} /> 
                                </IconButton></div>    
                          </Box>

                          <Box sx={{minWidth:"80%",maxWidth:"80%",width:"80%",paddingLeft:"15px"}}>

                            <Typography 
                              sx={{fontSize:"14px",fontWeight:"600"}} dangerouslySetInnerHTML={{__html:e.translations[lang]?e.translations[lang].name:"" }}>
                            </Typography>

                          <Box
                             sx={{color:"grey",fontSize:"13px"}} dangerouslySetInnerHTML={{__html:e.translations[lang]?e.translations[lang].description.substring(25,0)+".."+" on "+dateFormat(e.created_at,"mm/dd/yyyy"):""}} >
                              
                          </Box>

                          <Box sx={{display:"flex",justifyContent:"start"}}>
                              <div style={{display:"flex",padding:"3px"}}>
                                {/* <div style={{display:"flex",justifyContent:"center",fontSize:"11px",color:"#d80621"}}>{dateFormat(e.created_at,"mm/dd/yyyy")}</div>
                                <div style={{display:"flex",justifyContent:"center",fontSize:"11px",color:"#d80621"}}> 	&nbsp; at	&nbsp; </div> */}
                                <div style={{display:"flex",justifyContent:"center",fontSize:"10px",color:"#d80621"}}>{dateFormat(e.created_at,"h:MM TT")}</div>
                              </div>
                            </Box>
                          </Box>
                       </Box>
                    
                   
               </Box>
               })}

                  </Box>
             
              </Box>)}
          </Box>
          
          
          :""}
          
         

        </Menu>:""}
      </React.Fragment>
    );

  }

const mapStateToProps = (state)=>{
  return{
    lang:state.lang,
    notification_info_redux:state.notification,
    conversation_id_redux:state.conversation_id,
    message_redux:state.message
  }
}
const mapDispatchToState = (dispatch)=>{
  return {
    logout : (name,photo) => {dispatch(login(name,photo))},
    notification_redux : (notification, conversation_id,message) =>{dispatch(echo_info(notification, conversation_id,message))}
    
  }
}
export default connect(mapStateToProps,mapDispatchToState)(NotificationMenu)