import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Zoom } from "react-awesome-reveal";
//images 
import error_img from "../../assets/images/404.png"
import { useLocation, useNavigate } from 'react-router-dom';


const Continer = styled.div`
padding:60px 0;
    display:flex;
    justify-content:center;


    & h2{
        color:${props=> props.theme.colors.main.default};
        font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
        font-weight:600;
        font-size:20px;
        text-transform: capitalize;
        text-align:center;
        margin-top:15px
    }
    & p{
        color:black;
        font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
        font-weight:500;
        font-size:16px;
        text-align:center;
        margin-bottom:10px;
        margin:15px 0
    }
& img{
    object-fit:contain;
    height:250px;

}
& span{
    display: flex;
    margin-right: 10px;
}
`


function Page_404() {
    const navigate= useNavigate()
    const location = useLocation()
    const [order_id,setOrder_id] = useState("")
    // useEffect(()=>{
    //     setOrder_id(location.state.order_id)
    // },[location])
    return ( <Zoom style={{width:"100%"}} when={true}>
    <Continer>
    <Box sx={{width:"80%"}} >
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <img src={error_img} alt="404 not found" />

        </Box>
        <Box>
            <h2>Page not found !</h2>
            <p>This page aren't exist or was removed</p>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <Button onClick={()=>{navigate("/")}}  sx={{  width:'350px',
                                background:"#d80621",
                                borderRadius:"4px",
                                border:"1px solid  #d80621",
                                color:"white",
                                transition:"0.5s",
                                textTransform:"capitalize !important",
                                fontSize:"16px !important",
                                padding:"5px 40px",
                                "& span path":{
                                    fill:"white",
                                },
                                "&:hover":{
                                    background:"white",
                                    border:"1px solid  #d80621",
                                    color:"#d80621"
                                },
                                "&:hover span path ":{
                                    fill:"#D80621",
                                }
                            }}
                >
                   
                    Back to home Page
                    </Button>
        </Box>
    </Box>

</Continer> 
</Zoom>);
}

export default Page_404;