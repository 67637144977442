import React, { useState ,useEffect} from 'react';
import styled from 'styled-components';
import img from "../../../assets/images/Rectangle 1062.png"
import MDButton from '../../../components/MDButton';
import Skeleton from '@mui/material/Skeleton';

import { SwiperSlide,Swiper } from 'swiper/react';
import BottonControllerSwiper from './controllSwiper';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';
import { Button, IconButton, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Container = styled.div`
    background: ${props=>props.theme.colors.main.default};
    margin-bottom:20px;
    display:flex;
    padding:20px 0;
    @media(max-width:895px){
        flex-direction:column;
    }
`

const ArrowSection = styled.div`
    width:40%;
    padding:100px 80px;
    position:relative;
    @media(max-width:895px){
        width:100%;
        padding:10px 80px;
        text-align:center;
        // position:relative;
    }
`
const Title = styled.h2`
    color:white;
    font-size : ${props => props.theme.typography.Montserrat_Bold_4xl.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_Bold_4xl.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_Bold_4xl.weight};
    line-height :  ${props => props.theme.typography.Montserrat_Bold_4xl.lineHeight};
    margin-bottom:10px;
    @media(max-width:992px){
        font-size:25px
    }
`
const Paragraph = styled.p`
    color:white;
    font-size : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_lg.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_lg.lineHeight};
    margin-bottom:5px
`
const Arrows = styled.div`
    display:flex;
    gap:20px;
    @media(max-width:768px){
        padding:10px 30px;
        position:absolute;
        top:0;
        right:0;
        left:0;
        bottom:0;
        display:flex;
        justify-content:space-between;
        align-items:center;
        z-index:2
    }
`
const Arrow = styled.button`
    width:35px;
    height:35px;
    padding:5px;
    cursor:pointer;
    @media(max-width:768px){
        width:32px;
    height:32px;
    }
`
const SlideSection = styled.div`
    width:60%;
    @media(max-width:768px){
        width:100%;
    }
    @media(min-width:768px) and (max-width:895px){
        width:100%;
        display: flex;
        align-items:center;
        padding:0 0px
    }

`
const Card= styled.div`
    // width:300px;
    background:white;
    border-radius:4px;
    @media(max-width:992px){
        width:187.5px;
    }
`
const CardImg = styled.div`
    padding:10px
`
const ImgCard = styled.img`
    display:block;
    height: 188px;
    object-fit: cover;
    width:100%;

`
const TitleCard = styled.h2`
    padding:5px 13px;
    color: ${props => props.theme.colors.main.default};
    font-size : 18px;
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_lg.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_lg.lineHeight};
`
const DesCard = styled.p`
    padding:5px 13px;
    color: ${props => props.theme.colors.main.hint};
    font-size : 14px;
    height:90px;
    font-family : ${props => props.theme.typography.Montserrat.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat.weight};
    line-height :  ${props => props.theme.typography.Montserrat.lineHeight};
    @media(max-width:768px){
        font-family:"Montserrat";
        font-weight:400;
        line-height:15px;
        

    }
    @media(min-width:1200px){
        height: 91px;
        display: flex;
        align-items: center;
    }

`
const ButtonDiv = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px;
`
const Buttons = styled.div`
display:flex;
gap:15px;
@media(max-width:400px){
    padding:0 4px
}
@media(min-width:401px) and (max-width:895px){
    padding:0 30px
}
@media(max-width:895px){
    width:100%;
    position:absolute;
    left:0;
    right:0;
    top:50%;
    transform:translateY(-50%);
    justify-content:space-between;
    display: flex;
    // align-items:center;
    
}
`

const Skeleton_template = ()=>{
    return <Swiper spaceBetween={4} slidesPerView={3}  breakpoints={{
        // when window width is >= 320px
        320: {
        slidesPerView: 2,
        centeredSlides:true,

        },
        // when window width is >= 480px
        480: {
        slidesPerView: 2,

        //   spaceBetween: 24,
        },
        // when window width is >= 640px
        500: {
        slidesPerView: 3,
        //   spaceBetween: 24,
        },
        992:{
            slidesPerView: 3,
        },
        1336: {
            slidesPerView: 3,
            //   spaceBetween: 32,
            },
        1352: {
        slidesPerView: 3,
        //   spaceBetween: 32,
        //   slidesPerGroup: 1,
        },
        
    }}>
            
            {[0,1,2,3].map((e,index)=>{
                return(<SwiperSlide>
                    <Stack>
                    <Card  key={e.name}>
                    <CardImg>

                        <Skeleton animation="wave" variant="rounded" height={120} />
                    </CardImg>
                    <TitleCard>
                        <Skeleton animation="wave" height={60}  />
                    </TitleCard>
                        <Skeleton animation="wave" height={60} />
               
                    <ButtonDiv>
                        <Skeleton animation="wave" />
                    </ButtonDiv>
                    
    
                </Card>

                    </Stack>
                   
                </SwiperSlide>)
            })}
                <BottonControllerSwiper/>
            </Swiper>
}
function Blog({lang}) {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [blogs,setBlogs] = useState([])
    useEffect(()=>{
        // setInfo(prev=>data[title])
        get_Blogs()
    },[])
    const get_Blogs = async () =>{
      setIsLoading(true)
      const {response , message} = await Helper.Get({
          url:apiRoutes.blog.view,
          data:{results:20}
      })
      if(response){
          response.data.forEach(ele=>{
              setBlogs(prev=>[...prev,{
                  name:ele.translations[lang]?ele.translations[lang].name:"",
                  photo:ele.photo?ele.photo:img,
                  description:ele.translations[lang]?ele.translations[lang].description:"",
                  id:ele.id
              }])
          })
          setIsLoading(false)
      }else{
        //   if(typeof(message) === "string"){
        //       enqueueSnackbar(message,{variant:"error"}) 
        //   }else{
        //       Object.keys(message).map((key)=>{
        //           enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error"}) 
        //       })
        //   }
         
      }

  }
    return ( <Container>
        
        <ArrowSection>
            <Title>
            Shake Shake Blog
            </Title>
            <Paragraph>
            Read more about our event
            </Paragraph>
            <Buttons>
                <Button sx={{
                    background:"#dbd4d4",
                    borderRadius:"8px",
                    display:"flex",
                    height:"40px",
                    alignItems:"center",
                    justifyContent:"center",
                    position:"relative",
                    "&:hover":{
                        background:"#b5b4b4"
                    },
                    "@media(max-width:451px)":{
                        minWidth:"40px !important"
                    }
                }} onClick={()=>{
                    document.getElementById("prev-step").click()
                }}>

                    <IconButton sx={{position:"absolute",left:"55%",transform:"translateX(-50%)"}}>
                        <ArrowBackIosIcon />
                    </IconButton>
                </Button>
                <Button sx={{
                    background:"#dbd4d4",
                    borderRadius:"8px",
                    display:"flex",
                    height:"40px",
                    alignItems:"center",
                    justifyContent:"center",
                    position:"relative",
                    "&:hover":{
                        background:"#b5b4b4"
                    },
                    "@media(max-width:451px)":{
                        minWidth:"40px !important"
                    }
                }} onClick={()=>{
                    document.getElementById("next-step").click()
                }}>
                    <IconButton sx={{position:"absolute",left:"55%",transform:"translateX(-50%)"}}>
                    <ArrowForwardIosIcon/>
                    </IconButton>
                </Button>
            </Buttons>
           
        </ArrowSection>
        
        <SlideSection >
            {isLoading?<Skeleton_template/>:<Swiper spaceBetween={4} slidesPerView={3}  breakpoints={{
                    // when window width is >= 320px
                    320: {
                    slidesPerView: 2,
                    centeredSlides:true,
    
                    },
                    // when window width is >= 480px
                    480: {
                    slidesPerView: 2,
    
                    //   spaceBetween: 24,
                    },
                    // when window width is >= 640px
                    520: {
                    slidesPerView: 3,
                    //   spaceBetween: 24,
                    },
                    992:{
                        slidesPerView: 3,
                    },
                    1336: {
                        slidesPerView: 3,
                        //   spaceBetween: 32,
                        },
                    1352: {
                    slidesPerView: 3,
                    //   spaceBetween: 32,
                    //   slidesPerGroup: 1,
                    },
                    
                }}>
            
            {blogs.length>0 && blogs.map((e,index)=>{
                return(<SwiperSlide
                    slidesPerView={3} spaceBetween={20} breakpoints={{
                    // when window width is >= 320px
                    320: {
                    slidesPerView: 2,
                    centeredSlides:true,
    
                    },
                    // when window width is >= 480px
                    480: {
                    slidesPerView: 2,
    
                    //   spaceBetween: 24,
                    },
                    // when window width is >= 640px
                    520: {
                    slidesPerView: 3,
                    //   spaceBetween: 24,
                    },
                    992:{
                        slidesPerView: 3,
                    },
                    1336: {
                        slidesPerView: 2,
                        //   spaceBetween: 32,
                        },
                    1352: {
                    slidesPerView: 3,
                    //   spaceBetween: 32,
                    //   slidesPerGroup: 1,
                    },
                    
                }}>
                    <Card  key={e.name}>
                    <CardImg>
                        <ImgCard alt={e.name} src={e.photo} />
                    </CardImg>
                    <TitleCard>
                        {e.name}
                    </TitleCard>
                    <DesCard dangerouslySetInnerHTML={{__html:e.description.substring(50,0)}}/>
                    <ButtonDiv>
                        <MDButton  onClick={()=>navigate(`/blog/${e.id}`)} content={"Read More"} width="100%" height='50px' />
                    </ButtonDiv>
                    
    
                </Card>
                </SwiperSlide>)
            })}
                <BottonControllerSwiper bla={true} />
            </Swiper>}
            

        </SlideSection>
    </Container> );
}

export default Blog;