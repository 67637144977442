import styled from "styled-components";

const height = window.innerHeight

export const BasicContainer =styled.div`
    position:fixed;
    bottom:0;
    display:flex;
    right:5px;
    gap:10px;
    width:fit-content;
    border-top-left-radius:8px;
    border-top-right-radius:8px;
    z-index:1000;
    height:350px;
    & .chat-interface{
        position:relative;
        box-shadow: 0px 0px 18px 0px #5E5E5E8A;
        width:300px;
        background:white;
        border-top-left-radius:8px;
        border-top-right-radius:8px;
        z-index:1000;
        height:350px;
    };
    & .list-chat{
        position:relative;
        padding-bottom:10px;
        z-index:1000;
        display:flex;
        align-items:end;
        img{
            cursor:pointer;
        }
        .item{
            margin-top:5px;
            cursor:pointer;
            position:relative
        }
        .item:hover{
            .close-item{
                display:flex;
            }
        }
        .item .close-item{
            position:absolute;
            top:-5px;
            right:0;
            z-index:1002;
            width:15px;
            height:15px;
            display:none;
            align-items:center;
            justify-content:center;
            border-radius:50%;
            transition:0.3s;
            background:${props =>props.theme.colors.main.default};
            

        };
        & .close-list{
            width:32px;
            height:32px;
            display:flex;
            margin:auto;
            align-items:center;
            justify-content:center;
            border-radius:50%;
            transition:0.5s;
            background:${props =>props.theme.colors.main.complemently};
        }
        & .close-list:hover{
            cursor:pointer;
            background:${props =>props.theme.colors.main.default};
        }

    }
`

export const Header = styled.div`
background:${props =>props.theme.colors.main.default};
color:white;
display:flex;
justify-content:space-between;
border-top-left-radius:8px;
border-top-right-radius:8px;
padding:10px;
h3{
    font-size: clamp(0.7rem, 0.29vw + 0.56rem, 0.9rem);
}
& svg{
    cursor:pointer;
}

`
export const Content = styled.div`
padding:5px

`

export const RecieverDiv = styled.div`
display:flex;
justify-content:start;
color:black;
& .content{
    background:#e2e2e2;
    max-width:70%;
    width:fit-content;
    border-radius:0px 16px 16px 16px;
    padding:5px;
    margin-left:5px;
    font-size: clamp(0.7rem, 0.29vw + 0.56rem, 0.8rem);

}


`
export const SenderDiv = styled.div`
display:flex;
justify-content:end;

& .content{
    max-width:70%;
        color:white;
        width:fit-content;
        background:${props =>props.theme.colors.main.default};
        border-radius:16px 0px 16px 16px;
        padding:5px;
        font-size: clamp(0.7rem, 0.29vw + 0.56rem, 0.8rem);
}
`
export const Input = styled.input`
    width:100%;
    height:100%;
    border-radius:14px;
    outline:unset;
    background:rgb(159 159 159 / 24%);
    border:unset;
    padding:10px
`
export const SendDiv = styled.div`
    position:absolute;
    bottom:0;
    right:0;
    width:300px;
    z-index:1001;
`