import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import imgProduct from "../../../assets/images/product-photo.png"
import imgTop from "../../../assets/images/Rectangle 10581.png"
import MDButton from '../../../components/MDButton';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// MUI 
import { Button, CircularProgress, IconButton, Skeleton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay,Pagination,FreeMode, Navigation } from 'swiper/modules';

import { useBreakpoint } from "react-use-size";
import { ButtonBase, ButtonGroup,Box } from '@mui/material';
import { grey } from '@mui/material/colors';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';
import Simple_Card_Product from '../../../componentExternal/simple_card_product';
import wishlist from '../../wishlist';


const TitleOld = styled.h3`
font-size : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontSize};
font-family : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontFamily};
font-weight : ${props => props.theme.typography.Montserrat_SemiBold_2xl.weight};
line-height :  ${props => props.theme.typography.Montserrat_SemiBold_2xl.lineHeight}


`

const Container = styled.div`
    padding:10px 30px ;
    // display:flex;
    background:rgba(242, 237, 237, 0.34);
    gap:10px;
    .swiper-button-prev:after {
        color: #292727 !important;
    }
    .swiper-button-prev{
        left:-7px!important;
    }
    @media(max-width:500px){
        padding:10px 5px ;
    }
`
const Title = styled.h2`
font-size : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontSize};
font-family : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontFamily};
font-weight : ${props => props.theme.typography.Montserrat_SemiBold_2xl.weight};
line-height :  ${props => props.theme.typography.Montserrat_SemiBold_2xl.lineHeight}


`
const Card = styled.div`
font-family : "Montserrat";
& h3{
    color:grey;
    font-size:14px;
    margin-top:5px;
    font-weight:unset !important;
    line-height:unset !important;
}
& span{
    color:black;
    font-size:12px;
    font-weight:700
}
& h5{
    color:grey;
    font-size:12px;
    margin-top:5px;
    font-weight:unset !important;
    line-height:unset !important;
}
& a{
    font-weight:600;
}
& a:hover{
    color:${props=>props.theme.colors.main.default}
}

& img{
    border-radius:4px;
    // width: 100%;
    height: 100%;
    object-fit: contain;
    transition:0.5s;
}
&: hover{
    cursor:pointer;
    img{
       
        transform:scale(1.1);
    }
    h3{
        color:#e3a404;
    }
}
`





function Section({title,index,lang}) {
    const isSmall = useBreakpoint(993)
    const [products_api,setProducts_api] = useState([])
    const [isHover, setisHover] = useState({})
    const [isLoading , setIsLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        get_Products(signal)
        return()=>{
           controller.abort()
        }
        
    },[])
    const get_Products = async (signal) =>{
        setIsLoading(true)
        const {response , message} = await Helper.Get_Abort({
            url:apiRoutes.product.view,
            hasToken:true,
            signal:signal,
            data:{results:20}
        })
        if(response){
           if(response.data.length>0){
            response.data.forEach(ele=>{
                setProducts_api(prev=>[...prev,{
                    name:((lang in ele.product_translations) && ele.product_translations[lang].name)?ele.product_translations[lang].name:"",
                    category:((lang in ele.category_translations) && ele.category_translations[lang].name)?ele.category_translations[lang].name:"",
                    ratings:ele.ratings?ele.ratings:0,
                    sellprice:ele.sellprice,
                    originprice: ele.originprice,
                    is_stock:ele.is_stock?ele.is_stock:"",
                    id:ele.id,
                    wishlist:ele.wishlist,
                    photo:ele.photo?ele.photo:"",
                    user_name:ele.user_username?ele.user_username:"",
                    user_id:ele.user_id?ele.user_id:""
                }])
            })
            setIsLoading(false)
           }
        }

    }
    return ( <Container id="products">
        
    <TitleOld style={{marginBottom:"15px"}}>{title}</TitleOld>
    {isLoading?<Swiper style={{display:"flex",justifyContent:"center"}}

            pagination={false}
            spaceBetween={30}
            slidesPerView={5}
            grabCursor={true}    
            breakpoints={{
                // when window width is >= 320px
                320: {
                slidesPerView: 2,
                centeredSlides:true,

                },
                // when window width is >= 480px
                480: {
                slidesPerView: 3,

                //   spaceBetween: 24,
                },
                // when window width is >= 640px
                500: {
                slidesPerView: 3,
                //   spaceBetween: 24,
                },
                992:{
                slidesPerView: 4,
                },
                1336: {
                    slidesPerView: 6,
                    //   spaceBetween: 32,
                    },
                1352: {
                slidesPerView: 6,
                //   spaceBetween: 32,
                //   slidesPerGroup: 1,
                },
            }}
            // effect={'fade'}
            navigation={!isSmall&&true}
            modules={[Pagination,Navigation]}
            className="mySwiper"
            >
            {[0,1.2,3,4,5,6,7].map((e,i)=>{

            return(<SwiperSlide key={`loading_swiper_${i}`}  >
            <Card style={{padding:"0 10px",width:"100%"}} >
                                    
                                    
                    <Box sx={{display:"flex",justifyContent:"center"}}>
                        <Skeleton  width={"90%"} animation="wave" height={120} />
                    </Box>
                    <Skeleton animation="wave" width={"100%"} height={50} />

                </Card>

            </SwiperSlide>)})}

            </Swiper>
    :<Swiper style={{display:"flex",justifyContent:"center"}}
            pagination={false}
            spaceBetween={30}
            slidesPerView={5}
            grabCursor={true}    
            breakpoints={{
                // when window width is >= 320px
                320: {
                slidesPerView: 3,
                centeredSlides:true,

                },
                // when window width is >= 480px
                480: {
                slidesPerView: 3,

                //   spaceBetween: 24,
                },
                // when window width is >= 640px
                500: {
                slidesPerView: 3,
                //   spaceBetween: 24,
                },
                992:{
                    slidesPerView: 4,
                },
                1000:{
                    slidesPerView: 5,
                },
                1336: {
                    slidesPerView: 6,
                    //   spaceBetween: 32,
                    },
                1352: {
                slidesPerView: 6,
                //   spaceBetween: 32,
                //   slidesPerGroup: 1,
                },
                
            }}
            // effect={'fade'}
            navigation={!isSmall&&true}
            modules={[Pagination,Navigation]}
            className="mySwiper"
            >
{products_api.length>0  && products_api.map((e,index)=>{
  
    return(<SwiperSlide key={e.name ? `${e.name.substring(35,0)}_${index}`:`section_${index}`} >
                    <Simple_Card_Product 
                    wishlist={e.wishlist}
                    photo={e.photo} 
                    name={e.name ? e.name.substring(35,0)+"...":""}
                    id={e.id}
                    sellprice={e.sellprice}
                    vendor_id={e.user_id}
                    vendor={e.user_name}/>
                         
</SwiperSlide>)})}

</Swiper>}
    
    

</Container> );
}

export default Section;