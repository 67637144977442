import {Box, Tooltip} from "@mui/material"
import { useNavigate } from "react-router-dom";
import { DivAnimation } from "../../styles/homeStyles";
// api 
import apiRoutes from '../../apiRoutes';
import { Helper } from '../../tools/helper';
import { connect } from 'react-redux';
import { chat_open_popup } from "../../redux/action";
import PopupChat from "./popupChat";
import { useEffect, useState } from "react";

function ChatFixed({isOpen,userOther,openPopup}) {
    const pathname = window.location.pathname.split("/")[1]
    const navigate = useNavigate()
    const [isOpenInternal, setIsOpenInternal] = useState(false)
    const [user,setUser] = useState({})
    const supportChat = async()=>{
        const {response,status, message} = await Helper.Get({
            url:apiRoutes.messages.support,
            hasToken:true,
            // data:{role_id:31}
        })
        if(response){
            if("id" in response.data){
                navigate(`/chat/support/${response.data.id}`)
                localStorage.setItem("userOther",JSON.stringify({
                    username:response.data.second_name?response.data.second_name:"",
                    image:response.data.second_photo?response.data.second_photo:"",
                    id:response.data.second_user,
                    conversation_id:response.data.id

                }))
            }
        }else{
            if(status == 400){
                navigate(`/chat/support`)
            }
        }

       
    }
    useEffect(()=>{
        if(localStorage.getItem("forChat")){
            const info = JSON.parse(localStorage.getItem("forChat"))
            setIsOpenInternal(info.isOpen)
            setUser(info.user)
        }else{
            setIsOpenInternal(isOpen)
            setUser(userOther)
        }
    },[isOpen])
    return (localStorage.getItem("user") &&(pathname !="messages")&&(pathname !="chat")&&  <>
    
    { isOpenInternal?<PopupChat />:<Tooltip title="Support">
            
            <DivAnimation>
            <div class="svg-container"  onClick={supportChat} >
                <svg class="svg-container" width="50" height="50" viewBox="0 0 88 88" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="44" cy="44" r="45" fill="white" stroke="#EFEFEF" class="rotating"/>
                        <path fill="red" stroke="none"  d="M44 26.5C34.3505 26.5 26.5 34.3505 26.5 44V51.2502C26.5 53.0422 28.0697 54.5 30 54.5H31.75C32.2141 54.5 32.6592 54.3156 32.9874 53.9874C33.3156 53.6593 33.5 53.2141 33.5 52.75V43.7498C33.5 43.2856 33.3156 42.8405 32.9874 42.5123C32.6592 42.1841 32.2141 41.9998 31.75 41.9998H30.161C31.134 35.2273 36.9615 30 44 30C51.0385 30 56.866 35.2273 57.839 41.9998H56.25C55.7859 41.9998 55.3407 42.1841 55.0126 42.5123C54.6844 42.8405 54.5 43.2856 54.5 43.7498V54.5C54.5 56.4303 52.9303 58 51 58H47.5V56.25H40.5V61.5H51C54.8605 61.5 58 58.3605 58 54.5C59.9302 54.5 61.5 53.0422 61.5 51.2502V44C61.5 34.3505 53.6495 26.5 44 26.5Z"  strokeWidth="2"/>
                </svg>
            </div>
            </DivAnimation>
            
        
            </Tooltip>}
    </>);
}

const mapStateToProps = (state)=>{
    return{
        isOpen:state.isOpenPopup,
        userOther:state.userOther,
        
    }

}
const mapDispatchToState = (dispatch)=>{
    return{
        openPopup :(isOpen,isOpenConversation,userOther)=>{dispatch(chat_open_popup(isOpen,isOpenConversation,userOther))}
    }
}

export default connect(mapStateToProps, mapDispatchToState)(ChatFixed);