import styled from "styled-components";
import { Box, Grid, Tooltip } from "@mui/material";
import cover from "../assets/images/cover_opactity.webp" 


export const CustomGrid = styled(Grid)`
    background:url("${cover}");                    
    height:500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top;
    @media(max-width:768px){
        height:unset;  
    }
`
export const DivAnimation = styled.div`
.svg-container {
    margin: 20px;
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius:50%;
    cursor:pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    z-index:200
}

@keyframes shine {
    0% {
        left: -75%;
    }
    50% {
        left: 25%;
    }
    100% {
        left: 125%;
    }
}

.svg-container::before {
    content: "";
    position: absolute;
    top: 0;
    z-index:202;
    width: 100%;
    height: 100%;
    cursor:pointer;
    background: linear-gradient(to right, 
        rgba(255, 255, 255, 0) 0%, 
        rgba(255, 255, 255, 0) 25%, 
        rgba(255, 255, 255, 0.8) 50%, 
        rgba(255, 255, 255, 0) 75%, 
        rgba(255, 255, 255, 0) 100%);
    animation: shine 1.3s linear infinite;
}

svg {
    position: relative;
    z-index: -1;
}`


//join company
export const ParagraphLarge = styled.div`

    margin-top:5px;
    padding:0 20px 0 0 ;
    color:black;
    font-size: clamp(0.9rem, 0.43vw + 0.69rem, 1.2rem);
    font-weight:700;
    font-family:${props => props.theme.typography.Montserrat_Regular_lg.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_lg.lineHeight};
    @media(max-width:992px){
        // font-size:12px;
        padding: 0 ;
        width:100% !important
    }
`