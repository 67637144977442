import React , { useEffect, useState } from 'react';
import styled from 'styled-components';
// animation 
import { useSnackbar } from "notistack";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
//axios
import { Helper } from "../../../tools/helper";
import apiRoutes from "../../../apiRoutes";
import LoadingPage from '../../../componentExternal/loadingPage';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Container = styled.div`
padding:0px 70px;
overflow:hidden;
@media(max-width:500px){
  padding:30px 10px;  
}
@media(min-width:501px) and (max-width:992px){
    padding:10px 30px;
}


`
const FlexDiv = styled.div`
    display:flex;
    margin:20px 0;
    @media(max-width:500px){
    flex-direction:column;
    margin: 30px 0px !important;
}
@media(min-width:500px) and (max-width:992px){
    flex-direction:column;
    margin: 30px 20px !important;
}
   


`
const First = styled.div`
    width:50%;
    display:flex;
    overflow:hidden;
    align-items:center;
    @media(max-width:992px){
        width:100%;
        text-align:center;
        justify-content:center 
    }
`
const Title = styled.h2`
    text-transform:capitalize;
    color:${props => props.theme.colors.main.default} ;
    font-size:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_SemiBold_5xl.weight};
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    line-height:unset !important;
    @media(max-width:992px){
        font-size:27px
    }
`
const Paragraph = styled.p`
    margin-top:5px;
    padding:0 20px 0 0 ;
    color:black;
    font-size:${props => props.theme.typography.Montserrat_Regular_lg.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Regular_lg.weight};
    font-family:${props => props.theme.typography.Montserrat_Regular_lg.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_lg.lineHeight};
    @media(max-width:992px){
        font-size:12px;
        padding: 0 ;
        width:100% !important
    }
`
const Div = styled.div`




`

const CardImg = styled.div`
// height:400px;
width:100%;
padding:20px 0px;
display:flex;
text-align:center;
align-items:center;
justify-content:start;
@media(max-width:768px){
    padding:0;
    
    height:300px;
}
@media(max-width:992px){
    justify-content:center;
}




`
const Img = styled.img`
width:70%;
// height:100%;
border-radius:8px;
object-fit:contain;
@media(max-width:992px){
    width:80%;
    
}

` 
const Second = styled.div`
    width:50%;
    display:flex; 
    @media(max-width:992px){
        width:100%;
        justify-content:center
    }
`
const Buttons = styled.div`
display:flex;
margin-top:50px;
gap:20px;
@media(max-width:992px){
    justify-content:center
}


`
const Icon = styled.span`
    margin-right:4px;

`
function More_Win({lang}) {
    const navigate = useNavigate()
    const isSmall = useBreakpoint(769)
    const { enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(true)
    const [ page, setPage ]= useState({})
    // AOS.init()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPages(signal)
        return ()=>{
            controller.abort()
        }
    },[])
    const getPages = async(signal)=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            hasToken:true,
            signal:signal,
            data:{name:"never_win"}
        })
        if(response){
            if(response.data.length > 0){
                setPage(response.data[0])

                // enqueueSnackbar("Successfully Register",{variant:"success"}) 
                setIsLoading(false)
            }
          
        }
    }
    return (isLoading?<LoadingPage/>:<Container>
        <FlexDiv>
        <Second>
                <CardImg
                //  data-aos="zoom-in"
                //             data-aos-duration="1000"
                            >
                    <Img alt={page.name?page.name:""}  style={{objectFit:"cover",transform:"scale(0.9)"}} src={page.photo} />
                </CardImg>

            </Second>
            <First>
                <div>
                <Div>
                    <Title
                    //  data-aos="fade-up"
                    //     data-aos-delay="1000"
                    //         data-aos-duration="1000" 
                            style={{margin:"10px 0"}}>
                    {((lang in page.translations)&& page.translations[lang].name ) ? page.translations[lang].name :""}
                    </Title>
                    <Paragraph 
                    // data-aos="fade-up"
                    //         data-aos-delay="1500"
                    //         data-aos-duration="1000"
                             dangerouslySetInnerHTML={{__html:((lang in page.translations) && page.translations[lang].description)?page.translations[lang].description:""  }}>                      
                     </Paragraph>
        
                    
                </Div>
                <Buttons>
                   
                   <Link to={"/page/how-does-it-work"}>
                   <Button 
                //    data-aos="fade-right"
                //         data-aos-delay="1500"
                //             data-aos-duration="1000"
                            sx={{
                        background:"#d80621",
                        borderRadius:"4px",
                        border:"1px solid  #d80621",
                        color:"white",
                        transition:"0.5s",
                        textTransform:"capitalize !important",
                        fontSize:"16px !important",
                        padding:"5px 40px",
                        "&:hover":{
                            background:"white",
                            border:"1px solid  #d80621",
                            color:"#d80621"
                        }
                    }} >Learn More</Button>
                   </Link>
                    
                    
                </Buttons>
                </div>

            </First>
            

        </FlexDiv>
    </Container> );
}

export default More_Win;