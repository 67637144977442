import React ,{useState} from 'react';
import { styled } from 'styled-components';
//mui 
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton } from '@mui/material';
import imgProduct from "../../../assets/images/product-photo.png"
import MDButton from '../../MDButton';
import { useNavigate } from 'react-router-dom';
import Cart_Product from '../../../componentExternal/cartProduct';
import { connect } from 'react-redux';
import { get_cart, notification_Cart } from '../../../redux/action';
//api request 
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';

const Container = styled.div`
    min-width:30vw;
    background:white;
    padding:10px 5px;
    @media(max-width:768px){
        width:100vw
    }


`
//product Style
const ContentProducts = styled.div`
    border:1px solid #e4e4e4;
    border-radius:10px;
    // padding:5px 20px;
    margin:10px 0;
    transition:0.4s;
    &:hover{
        border:1px solid ${props=>props.theme.colors.main.default};   
    }
`
const Content= styled.div`
    display:flex;
`
const ImgPart = styled.div`
    width:30%;

`
const Img = styled.img`
    width:100%;
    object-fit:content
`
const ContentPart = styled.div`
width:70%;

`
const Title = styled.div`
display:flex;
align-items:center;
font-size:${props => props.theme.typography.Montserrat_Regular_base.fontSize};
font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
font-weight:600

`
const Category = styled.div`
 color:${props=> props.theme.colors.main.hint};
`
const DivController = styled.div`
    padding:5px 0;
    display:flex;

`
const Price = styled.div`
 color:${props=> props.theme.colors.main.default};
 font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
 font-weight:700;
 font-size:15px;
 width:20%;
 display:flex;
 align-items:center
`
const Buttons = styled.div`
display:flex;
gap:10px;
padding : 0 5px;
// height:20px;
margin-top:10px;
width:80%;
justify-content:end

`
const Counter = styled.div`
width:100px;
height:30px;
border-radius:20px;
background: #f7f7f7;
display:flex;
justify-content:space-between;
align-items:center;
padding:20px;
color:${props=>props.theme.colors.main.default}


`
const Num = styled.div`
   
    font-size:15px;
    width:20px;
    display:flex;
    justify-content:center
`
const Add = styled.div`
    text-align:center;
    color:${props=> props.theme.colors.main.complemently};
    font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
    font-weight:700;
    font-size:15px;

`

function Side_Bar({name,onClose,cart,cart_with_api,cart_number,number_cart}) {
    const [num,setNum] = useState(5)
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading,setIsLoading]= useState(false)
    const [view,setView] = useState(false)
    const handlePay = async ()=>{
        let data= new FormData()
        data.append("_method","PUT")
        setIsLoading(true)
        const {response,message} = await Helper.Post({
            url:apiRoutes.checkout.storeOrder,
            hasToken:true,
            data:data
        })
        if(response){
            navigate("/pay")
            localStorage.setItem("order_pay",JSON.stringify(response.data))
            onClose()
            cart_with_api({
                products:[]
            })
            cart_number(0,false)
            localStorage.removeItem("number_cart")
            setIsLoading(false)
            
        }else{
            if(message == "NOT_ENOUGH_BALANCE"){
                enqueueSnackbar("Not enough balance .",{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
                setView(true)
               
            }else if(typeof(message) === "string"){
                enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }else{
                Object.keys(message).map((key)=>{
                    enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                })
            }
            
            setIsLoading(false)
            
    
        } 

    }
    return ( <Container>
        <Box sx={{position:"relative",display:"flex",justifyContent:"center",alignItems:"center",height:'60px'}}>
            <IconButton sx={{position:"absolute",top:"50%",transform:"translateY(-50%)",left:"0"}}>
                <CloseIcon onClick={onClose} sx={{fontSize:"1.5rem",color:"black"}}/>
            </IconButton>
            <h4>Cart</h4>
            

        </Box>
        <div style={{padding:"10px 15px"}}>
        <Cart_Product onClose={onClose}/>
        
        {view &&<div style={{margin:"15px 0"}}>
            <MDButton onClick={()=>{
                navigate(`/profile/${JSON.parse(localStorage.getItem("user")).name}/balance`)
                setView(false)
                cart_number(number_cart,false)
            }} width="100%" 
            content={"add balance"} />
        </div> }
        
        {
            cart.products.length>0
             &&<div style={{margin:"15px 0"}}>
            <MDButton onClick={handlePay} width="100%" 
            content={isLoading?<CircularProgress size="1.5rem" sx={{color:"white"}} />:"Pay"} />
        </div>
        }
        </div>
        
        
       
    </Container> );
}
const mapStateToProps= (state)=>{
    return{
        cart:state.cart,
        number_cart : state.number
    }
}
const mapDispatchToState = (dispatch)=>{
    return {
        cart_with_api : (cart)=>{dispatch(get_cart(cart))},
        cart_number :(num,isOpen)=>{dispatch(notification_Cart(num,isOpen))},
    }
}
export default connect(mapStateToProps,mapDispatchToState)(Side_Bar);