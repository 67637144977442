import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';




const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    width:"100%",
    whiteSpace:"nowrap",
    backgroundColor:theme.palette.primary.main,
    display:"flex",
    gap:"10px",
    textTransform:"capitalize !important",
    '&:hover': {
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor:"#930000"
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.main ,
      color:"silver"
    }
  }));
  const IconButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    width:"100%",
    backgroundColor:"white",
    whiteSpace:"nowrap",
    border:`0.5px solid ${theme.palette.primary.main}`,
    display:"flex",
    gap:"10px",
    textTransform:"capitalize !important",
    '&:hover': {
      backgroundColor: "white",
    },
    "&:disabled": {
      backgroundColor: theme.palette.secondary.main,
      color:"silver"
    }
  }));
function MDButton({width="100%",height="90%",icon,disabled=false,content,isIcon=false,...props}) {
    return ( <Stack {...props} style={{width:width,height:height}} spacing={2} direction="row">
        {isIcon?<IconButton disabled={disabled} >{icon}</IconButton>:<ColorButton disabled={disabled}  >
                {icon}
                {content}
        </ColorButton>}
  </Stack> );
}

export default MDButton;