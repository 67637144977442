import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import custom styles
import "./assets/style/style.css"
import "./assets/style/font_styles.css"
// import redux 
import store  from './redux/store';
import { Provider } from 'react-redux';

//helmet
import { HelmetProvider } from 'react-helmet-async';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store} >
        {/* <Buttons/> */}
          <App />
      </Provider>
    </HelmetProvider> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
