import { Avatar, Box } from '@mui/material';
import React, { useState ,useEffect,useRef} from 'react';
import MDInput from '../MDInput';
import { Helper } from '../../tools/helper';
import apiRoutes from '../../apiRoutes';
import { connect } from 'react-redux';
import img from "../../assets/images/Rectangle 3766.png"
import { useBreakpoint } from 'react-use-size';
import { useNavigate,useHistory, useLocation } from 'react-router-dom';
import { search_value } from '../../redux/action';
import {InputSearch,Icon} from "../../styles/NavbarStyles"


function Search_Component({lang,isSearch,searchRedux}) {
    const isSmall = useBreakpoint(993)
    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [items, setItems] = useState([])
    const [isOpen,setIsOpen] = useState(true)
    const divRef = useRef(null)
    const inputRef = useRef(null)
    const controllerRef = useRef(null)
    const handleChange = async (value_search) => {
        setSearch(value_search)
        get_History(value_search)
        
       
        
    }
    useEffect(()=>{
    // to Close menu when click outside
        function handleClickOutside(event) {
        if (divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false)
        setSearch("")
        }}
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        };
    })
    const handleFoucs = ()=>{
        
        get_History("")
    }
    const get_History = async (keywords)=>{
        let data = {}
        if(keywords.length>0){
            data = {resutls:10,keywords:keywords}
        }else if(keywords == ""){
            data = {resutls:10}
        }
        if(localStorage.hasOwnProperty("user")){
            setIsOpen(true) 
            if(controllerRef.current){
                controllerRef.current.abort()
            }
            controllerRef.current = new AbortController()
            const signal = controllerRef.current.signal
            const {response, message} = await Helper.Get_Abort({
                url:apiRoutes.search.byUser,
                hasToken:true,
                data:data,
                signal:signal
    
            })
            if(response){
                setItems(response.data)
            }
        }
    }
    return (<Box sx={{width:"100%"}}>
        <Box sx={{width:"100%",position:"relative"}} >
                <Icon><svg onClick={()=>{
                        navigate({
                            pathname:`/products/${search}`,
                            search: `?keywords=${search}&is_search=${1}`,
                        })
                        }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3.31573 13.7811L4.04591 13.6098L3.31573 13.7811ZM3.31573 8.324L4.04591 8.49528L3.31573 8.324ZM18.7893 8.324L19.5195 8.15273L18.7893 8.324ZM18.7893 13.781L19.5195 13.9523L18.7893 13.781ZM13.781 18.7893L13.6098 18.0591L13.781 18.7893ZM8.324 18.7893L8.15273 19.5195L8.324 18.7893ZM8.324 3.31573L8.15272 2.58555L8.324 3.31573ZM13.781 3.31573L13.9523 2.58555L13.781 3.31573ZM20.4697 21.5303C20.7626 21.8232 21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L20.4697 21.5303ZM4.04591 13.6098C3.65136 11.9278 3.65136 10.1773 4.04591 8.49528L2.58555 8.15272C2.13815 10.06 2.13815 12.045 2.58555 13.9523L4.04591 13.6098ZM18.0591 8.49528C18.4537 10.1773 18.4537 11.9278 18.0591 13.6098L19.5195 13.9523C19.9669 12.045 19.9669 10.06 19.5195 8.15273L18.0591 8.49528ZM13.6098 18.0591C11.9278 18.4537 10.1773 18.4537 8.49528 18.0591L8.15273 19.5195C10.06 19.9669 12.045 19.9669 13.9523 19.5195L13.6098 18.0591ZM8.49528 4.04591C10.1773 3.65136 11.9278 3.65136 13.6098 4.04591L13.9523 2.58555C12.045 2.13815 10.06 2.13815 8.15272 2.58555L8.49528 4.04591ZM8.49528 18.0591C6.28757 17.5413 4.56377 15.8175 4.04591 13.6098L2.58555 13.9523C3.23351 16.7147 5.39038 18.8715 8.15273 19.5195L8.49528 18.0591ZM13.9523 19.5195C16.7147 18.8715 18.8715 16.7147 19.5195 13.9523L18.0591 13.6098C17.5413 15.8175 15.8175 17.5413 13.6098 18.0591L13.9523 19.5195ZM13.6098 4.04591C15.8175 4.56377 17.5413 6.28757 18.0591 8.49528L19.5195 8.15273C18.8715 5.39037 16.7147 3.23351 13.9523 2.58555L13.6098 4.04591ZM8.15272 2.58555C5.39037 3.23351 3.23351 5.39037 2.58555 8.15272L4.04591 8.49528C4.56377 6.28756 6.28757 4.56377 8.49528 4.04591L8.15272 2.58555ZM16.8048 17.8655L20.4697 21.5303L21.5303 20.4697L17.8655 16.8048L16.8048 17.8655Z" fill="#161616"/>
                        </svg></Icon>
                <InputSearch onFocus={handleFoucs} onKeyDown={(e)=>{
                    setIsOpen(false)
                    if (e.key === 'Enter') {
                        setSearch("")
                        navigate({
                            pathname: `/products/${search}`,
                            search: `?keywords=${search}&is_search=${1}`,
                        })
                    }   
                    }} 
                    width="100%" value={search} onChange={(e)=>{handleChange(e.target.value)}}
                    placeholder={"What are you looking for?"}  /> 
                
                { isOpen && items.length>0 &&<Box ref={divRef} sx={{background:"white",zIndex:"1000",position:"absolute",top:"50px",right:"0",left:"0",minHeight:"200px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                borderRadius:"8px"}}>
               { items.map((e)=>{
                return <Box onClick={()=>{setIsOpen(false)
                    navigate({
                        pathname: `/products/${e.name}`,
                        search: `?keywords=${e.name}`,
                      })
                    }} key={e.name} >
                <Box sx={{
                transition:"0.5s",
                "&:hover":{
                    borderRadius:"4px",
                    cursor:"pointer",
                    background:"rgb(192 192 192 / 39%)"
                }
                }} >
                <Box sx={{padding:"10px 18px",display:"flex"}} >
                        <Box sx={{paddingRight:"10px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M22.4999 5.25V11.25C22.4999 11.4489 22.4209 11.6397 22.2803 11.7803C22.1396 11.921 21.9488 12 21.7499 12C21.551 12 21.3603 11.921 21.2196 11.7803C21.0789 11.6397 20.9999 11.4489 20.9999 11.25V7.06031L13.2806 14.7806C13.2109 14.8504 13.1282 14.9057 13.0371 14.9434C12.9461 14.9812 12.8485 15.0006 12.7499 15.0006C12.6514 15.0006 12.5538 14.9812 12.4627 14.9434C12.3717 14.9057 12.289 14.8504 12.2193 14.7806L8.99993 11.5603L2.78055 17.7806C2.63982 17.9214 2.44895 18.0004 2.24993 18.0004C2.05091 18.0004 1.86003 17.9214 1.7193 17.7806C1.57857 17.6399 1.49951 17.449 1.49951 17.25C1.49951 17.051 1.57857 16.8601 1.7193 16.7194L8.4693 9.96937C8.53896 9.89964 8.62168 9.84432 8.71272 9.80658C8.80377 9.76884 8.90137 9.74941 8.99993 9.74941C9.09849 9.74941 9.19609 9.76884 9.28713 9.80658C9.37818 9.84432 9.4609 9.89964 9.53055 9.96937L12.7499 13.1897L19.9396 6H15.7499C15.551 6 15.3603 5.92098 15.2196 5.78033C15.0789 5.63968 14.9999 5.44891 14.9999 5.25C14.9999 5.05109 15.0789 4.86032 15.2196 4.71967C15.3603 4.57902 15.551 4.5 15.7499 4.5H21.7499C21.9488 4.5 22.1396 4.57902 22.2803 4.71967C22.4209 4.86032 22.4999 5.05109 22.4999 5.25Z" fill="#27242A"/>
                        </svg>
                        </Box>
                        <Box sx={{display:"flex",alignItems:"center",paddingLeft:"5px",fontSize:"15px"}}>
                            {e.name?e.name.substring(80,0)+"..":""}
                        </Box>
                    
                    </Box> 
                    <Box sx={{display:"flex",justifyContent:"center",padding:"0 10px"}}>
                    <Box sx={{background:"#ffffff52",height:"1px",width:"100%"}}></Box>
                    </Box>

                </Box>
               </Box>
               })}
        
    {/* <Box sx={{position:"fixed",zIndex:"500",left:"0",bottom:"0",right:"0",top:'60px',background:"grey"}} >

    </Box> */}
     </Box>}
            </Box>

    

              
 
     
    
    </Box>  );
}


const mapStateToProps = (state)=>{
    return{
        lang:state.lang,
        searchRedux: state.search
    }
}
const mapDispatchToState = (dispatch)=>{
    return{
        isSearch : (search)=>{dispatch(search_value(search))}
    }
}
export default connect(mapStateToProps,mapDispatchToState)(Search_Component);