import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from 'styled-components';
import { Button } from '@mui/material';


const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
`

export default function Paginator({count_pages,page_income=1,returnedPage}) {

  const { items } = usePagination({
    count: count_pages,
    page :page_income,
    onChange:(e)=>{
      returnedPage(e.target.textContent)
    }

  });
 
  const ButtonState = styled(Button)`

  `
  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <ButtonState
                type="button"
                sx={{
                    "&:hover":{
                        color:"white",
                        border:"1px solid inherit",
                        background:"rgba(102, 112, 133, 1)"
                    },
                  minWidth:"unset !important",
                  marginRight:"5px",
                  border:"1px solid #F3F3F3",
                  borderRadius:"4px",
                  fontWeight: page==page_income ? 'bold' : undefined,
                  color:page==page_income?"white":"rgba(102, 112, 133, 1)",
                  background:page==page_income?"rgba(102, 112, 133, 1)":""
                }}
                {...item}
              >
                {page}
              </ButtonState>
            );
          } else {
            children = (
              <ButtonState  type="button" sx={{
                "&:hover":{
                    color:"white",
                    border:"1px solid inherit",
                    background:"rgba(102, 112, 133, 1)"
                },
                minWidth:"unset !important",
                marginRight:"5px",
              border:"1px solid #F3F3F3",
              borderRadius:"4px",
              fontWeight: page==page_income ? 'bold' : undefined,
              color:page==page_income?"white":"rgba(102, 112, 133, 1)",
              background:page==page_income?"rgba(102, 112, 133, 1)":""
            }} {...item}>
                {type}
              </ButtonState>
            );
          }

          return <li  key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}