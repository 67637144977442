import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import app from "../../../assets/images/Frame 5911.png"
import appStor from "../../../assets/images/Group-appStor.png"
import googlePlay from "../../../assets/images/Badge.png"
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { Link, useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';

const Container = styled.div`
    margin:10px 20px;
    background:rgba(127, 127, 127, 1);
    border-radius:4px;
    display:flex;
    color:white;
    @media(max-width:768px){
        flex-direction:column
    }
`
const ImgDiv = styled.div`
    width:50%;
    display:flex;
    padding: 20px 0; 
    
    justify-content:center;
    @media(max-width:768px){
        width:100%;
        

    }
    @media(min-width:769px){
        padding: 20px 0 20px 40px;  
    }

`
const Img = styled.img`
width:100%;
object-fit: contain;
// @media(max-width:400px){
// transform: scale(0.8);
// }
// @media(min-width:600px) and (max-width:1300px){
//     // width:150px;
//     transform: scale(1.2);
//     object-fit: contain;
// }

`
const Content = styled.div`
    width:50%;
    padding:30px 0 30px 50px;
    @media(max-width:768px){
        width:100%;
        padding:0px 0 30px 0px;
    }
`
const BriefDiv = styled.div`
    margin-bottom:160px;
    padding-top:50px;
    @media(max-width:768px){
        margin-bottom:20px;
        padding-top:20px;
        text-align:center
    }
`
const Brief = styled.h1`
    font-size : ${props => props.theme.typography.Montserrat_Bold_2xl.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_Bold_2xl.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_Bold_2xl.weight};
    line-height :  ${props => props.theme.typography.Montserrat_Bold_2xl.lineHeight};
    @media(max-width:768px){
        text-align:center
    }
`
const Para = styled.p`
    font-size : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_lg.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_lg.lineHeight};
`
const ContentApp = styled.div`

`
const Contantlogs = styled.div`
    display:flex;
    gap:20px;
    margin-top:10px;
    @media (max-width:992px){
        flex-direction:column
    }
`
const FirstSide = styled.div`
    @media(max-width:768px){
        text-align:center
    }
`
const SecondSide = styled.div`
    @media(max-width:768px){
        text-align:center
    }
`
const ImgApp = styled.img`
width:150px;
height:60px;
object-fit:contain;
&:hover{
    cursor:pointer
}

`
function Application() {
    const navigate = useNavigate()
    useEffect(()=>{
        get_Links()
    },[])
    const [links, setLinks] = useState({})
    const [isLoading, setIsLoading] = useState({})
    const get_Links = async()=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get({
            url:apiRoutes.setting.view,
            hasToken:true
        })
        if(response){
            setLinks(response.data)
            // enqueueSnackbar("Successfully Register",{variant:"success"}) 
            setIsLoading(false)
          
        }
    }
    return (<Container>
        <ImgDiv>
            <Img alt="" src={app}/>
        </ImgDiv>
        <Content>
            <BriefDiv>
                <Brief>
                Shake Shake application
                </Brief>
                <Para>
                Shake your device and get mystery boxes
                </Para>

            </BriefDiv>
            <ContentApp>
            <Brief>
                    Download now :
                    </Brief>
                <Contantlogs>
                    <FirstSide>
                        <Link to={links.app_store_link?links.app_store_link:"#"}>
                            <ImgApp src={appStor} />
                        </Link>
                        
                    </FirstSide>
                    <SecondSide>
                        <Link to={links.google_play_link?links.google_play_link:"#"}>
                            <ImgApp  src={googlePlay} />
                        </Link>
                        
                    </SecondSide>
                </Contantlogs>
            </ContentApp>

        </Content>
    </Container>);
}

export default Application;