import styled from "styled-components";
import { CircularProgress, TextField } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";
import { useState } from "react";
import {Button} from "@mui/material";
import { Helper } from "../../tools/helper";
import apiRoutes from "../../apiRoutes";
import { SnackbarProvider, useSnackbar } from 'notistack';
const Box =  styled.div`
width:100%;
position:relative;
`
// const Input = styled.input`

//     background: ${prop=>prop.theme.colors.background.input};
//     height: 52px;
//     padding-left: 65px;
//     padding-top:5px;
//     font-size:${props => props.theme.typography.Montserrat_Regular_sm.fontSize};
//     font-weight:${props => props.theme.typography.Montserrat_Regular_sm.weight};
//     font-family:${props => props.theme.typography.Montserrat_Regular_sm.fontFamily};
//     line-height:${props => props.theme.typography.Montserrat_Regular_sm.lineHeight};
//     color: ${props => props.theme.colors.text.black};
//     border:1px solid  ${prop=>prop.theme.colors.main.complemently};
//     transition:0.2s;
//     border-radius: 5px;
//     position:relative;
    
//     // &:focus{
//     //     outline: none;
//     //     // border:1.5px solid  black;
//     //     border: 1px solid #7934be;
//     //     box-shadow: 0px 0px 10px grey;

//     // };
//     // &::placeholder{
//     //     transition:2s top;
//     //     position:absolute;
//     //     top:unset;
        
//     // };
//     // &:focus::placeholder{
//     //     transition: 0.2s ease-in;
//     //     position:absolute;
//     //     top:4px;
//     //     color:black;
    
//     // }
//     &:focus~label{
// 	top: -20px;
// 	font-size: 14px;
// 	color: #5264AE;
// }
// `
const Icon = styled.div`
    position: absolute;
    top:56%;
    transform:translateY(-50%);
    left:24px;
    z-index:3
`
const Label = styled.label`
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 65px;

  padding-top:20px;
  font-size:${props => props.theme.typography.Montserrat_Regular_sm.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Regular_sm.weight};
  font-family:${props => props.theme.typography.Montserrat_Regular_sm.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Regular_sm.lineHeight};
  transition: 0.2s ease all;


`;
const Input = styled.input`

  display: block;
  
    background: ${prop=>prop.theme.colors.background.input};
    height: 52px;
    padding-left: 65px;
    padding-top:5px;
    font-size:${props => props.theme.typography.Montserrat_Regular_sm.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Regular_sm.weight};
    font-family:${props => props.theme.typography.Montserrat_Regular_sm.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_sm.lineHeight};
    color: ${props => props.theme.colors.text.black};
    border:1px solid  ${prop=>prop.theme.colors.main.complemently};
    transition:0.2s;
    border-radius: 5px;
    // &:focus~${Label},&:valid~${Label} {
    //   top: -20px;
    //   font-size: 14px;
    //   color: #5264AE;
    // }
  &:focus ~ ${Label}{
    outline: none;
    border:1.5px solid  black;
    border: 1px solid #7934be;
    box-shadow: 2px 4px 20px 0px #b3b1b1;
  }
      
`;






function MDInput({
    placeholder,
    icon,
    background,
    isSearch=false,
    width="98%",
    onChange,
    type,
    style={},
    onKeyDown,
    isSubscribe=false,
    disabled=false,
    isDisable = false,
    isPassword = false,
    ...props
}) {
  const [isView,setIsView] = useState(false)
  const [email,setEmail]= useState("")
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false)
  const handleSend = async ()=>{
    if(email.length>0){
      setIsLoading(true)
      const form_data = new FormData()
      form_data.append("email",email)
      form_data.append("_method","PUT")
      const {response, message} = await Helper.Post({
        url:apiRoutes.subscribe.send_mail,
        data:form_data
      })

      if(response){
        setIsLoading(false)
        enqueueSnackbar("Successfully Subscribe!",{variant:"success",anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }})
      }else{
        setIsLoading(false)
        if(typeof(message) === "string"){
            enqueueSnackbar(message,{variant:"error",anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }}) 
        }else{
            Object.keys(message).map((key)=>{
                enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                }}) 
            })
        }
      }
    }else{
      enqueueSnackbar("Email filed required to complete Subscribe!",{variant:"error",anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }})
    }
  }
    return (<Box  className={isDisable?"group_label":"group"}>
      <Icon>{icon}</Icon>
      {/* <Label>{placeholder}</Label>

      <Input type={type} onChange={onChange} style={{background:background,width:width}}  /> 
     */}
     {isSubscribe?<input  {...props} disabled={disabled} onKeyDown={onKeyDown} type={isPassword?(isView?"text":"password"):type} onChange={(e)=>setEmail(e.target.value)} style={{background:background,width:width}} required/>
     :<input  {...props} disabled={disabled} onKeyDown={onKeyDown} type={isPassword?(isView?"text":"password"):type} onChange={onChange} style={{background:background,width:width}} required/>}
      
      <label  >{placeholder}</label>  
      {isSubscribe&&<div style={{position:"absolute",right:"15px", top:"50%",transform:"translateY(-50%"}}>
      <Button onClick={handleSend} sx={{  width:'100px',
                                background:"#d80621",
                                borderRadius:"4px",
                                border:"1px solid  #d80621",
                                color:"white",
                                transition:"0.5s",
                                textTransform:"capitalize !important",
                                fontSize:"13px !important",
                                padding:"5px 40px",
                                  "&:hover":{
                                      background:"#d80621",
                                      border:"1px solid  white ",
                                      color:"white"
                                  }
                            }}
                >{isLoading?<CircularProgress size="1.2rem" sx={{color:"white"}}/> :"Subscribe"}</Button>
        </div>}
      {isPassword && <div style={{position:"absolute",right:"4px", top:"50%",transform:"translateY(-50%"}} >
      { isView?<IconButton onClick={()=>setIsView(false)} sx={{padding:"0",width:"50px",borderRadius: "4px"}}>
         <VisibilityOffIcon/>
        </IconButton>:<IconButton onClick={()=>setIsView(true)} sx={{padding:"0",width:"50px",borderRadius: "4px"}}>
         <VisibilityIcon/>
        </IconButton>}
      </div>}
  


  </Box>
           
    );
}

export default MDInput;