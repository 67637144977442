import React,{useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useBreakpoint} from 'react-use-size';
//mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, Grid, Skeleton } from '@mui/material';
import Badge from '@mui/material/Badge';
//api request 
import MDButton from '../components/MDButton';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { echo_info, login } from '../redux/action';
//pages
import noNotification from "../assets/images/Items.png"
import profile from "../assets/images/profile.png"
import { Helper } from '../tools/helper';
import apiRoutes from '../apiRoutes';
import dateFormat from 'dateformat';
import axios, { Axios } from 'axios';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {  listen_note } from '../echo/echo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Head = styled.h1`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
  font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
  text-align:center
`
const Des = styled.p`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Regular_base.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Regular_base.weight};
  font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Regular_base.lineHeight};
  text-align:center
`

const TitleMenu = styled.h2`
font-size:${props => props.theme.typography.Montserrat_SemiBold_base.fontSize};
font-weight:${props => props.theme.typography.Montserrat_SemiBold_base.weight};
font-family:${props => props.theme.typography.Montserrat_SemiBold_base.fontFamily};
line-height:${props => props.theme.typography.Montserrat_SemiBold_base.lineHeight};
padding:0 10px;
`
const PathBasic = styled.path`
fill:${props=> props.theme.colors.main.complemently}
`
const Svg = styled.svg`
fill:${props=> props.theme.colors.main.default}
`
const Path= styled.path`
stroke:${props=> props.theme.colors.main.default};

`
const PathFill = styled.path`
fill:${props=> props.theme.colors.main.default}
`
function AccountMenu({userName,logout,name,photo}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSoSmall = useBreakpoint(600)
    const isSoSoSmall = useBreakpoint(320)
    const isSmallForArrow = useBreakpoint(360)
    const [ nameMenu, setNameMenu ] = React.useState("")
    const navigate = useNavigate()
    const [Notification,setNotification] = React.useState([])
    const [New,  setNew] = React.useState(null)
    const [count,setCount] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
 
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
      setAnchorEl(null);
    };
    const handleNavigate = (e)=>{
      setAnchorEl(null);
      navigate(e)
    }
    React.useEffect(()=>{
      setNameMenu(name)

    })
  
    const handleLogout = ()=>{
      logout("",null)
      logout("","")
      localStorage.removeItem("user")
      localStorage.removeItem("order_pay")
      localStorage.removeItem("number_cart")
      localStorage.removeItem("lang")
      localStorage.removeItem("userOther")
      localStorage.removeItem("auth")
      localStorage.removeItem("cart")
      localStorage.removeItem("method")
      localStorage.removeItem("shakes")
      localStorage.removeItem("shake_products")
      localStorage.removeItem("shake_id")
      localStorage.removeItem("shake")
      localStorage.removeItem("box")
      localStorage.removeItem("pusherTransportTLS")
      localStorage.removeItem("__paypal_storage__")
      localStorage.removeItem("users")
      localStorage.removeItem("forChat")
      window.location.href="/"
    }
    return (
      <React.Fragment>
        <li className='menu_li'>
          {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
          <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
          <Tooltip title={"My Account" }>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ 
                ml:isSoSoSmall?0:2 ,
                transition:"0.5s",
                "&:hover":{
                  borderRadius:"4px !important"
                }
              }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar src={photo} sx={isSoSoSmall?{background:"white", width: 22, height: 22}
                  :{background:"white", width: 32, height: 32,marginRight:"3px" }} />
              {/* <Avatar sx={{background:"white", width: 32, height: 32 }}> */}
              {!isSoSmall && <span style={{fontSize:"12px",fontWeight:"800",color:"black"}}>{userName}</span> }
              {!isSmallForArrow && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                  <PathBasic fillRule="evenodd" clipRule="evenodd" d="M7.94184 9.81684C7.82464 9.93401 7.6657 9.99984 7.49997 9.99984C7.33424 9.99984 7.1753 9.93401 7.0581 9.81684L3.52247 6.28122C3.46278 6.22357 3.41516 6.1546 3.38241 6.07835C3.34965 6.0021 3.33241 5.92008 3.33169 5.8371C3.33097 5.75411 3.34678 5.67181 3.37821 5.595C3.40963 5.51819 3.45604 5.44841 3.51472 5.38972C3.57341 5.33104 3.64319 5.28463 3.72 5.25321C3.79681 5.22178 3.87911 5.20597 3.9621 5.20669C4.04508 5.20741 4.1271 5.22465 4.20335 5.25741C4.2796 5.29016 4.34857 5.33778 4.40622 5.39747L7.49997 8.49122L10.5937 5.39747C10.7116 5.28362 10.8695 5.22062 11.0333 5.22205C11.1972 5.22347 11.354 5.2892 11.4699 5.40508C11.5857 5.52096 11.6515 5.67772 11.6529 5.84159C11.6543 6.00547 11.5913 6.16334 11.4775 6.28122L7.94184 9.81684Z" />
                </svg>}
               


                        
              {/* </Avatar> */}
            </IconButton>
          </Tooltip>
          
        </li>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          
          PaperProps={{
            elevation: 0,
            sx: {
              width:(nameMenu==="notification")?"400px":"190px",
              overflow: 'visible',
              overflowY:"",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem style={{position:"relative"}}  onClick={()=>{
            handleClose()
              navigate(`/profile/${JSON.parse(localStorage.getItem("user")).username}/info/mainInfo`)
            }}>
            
            <ListItemIcon  >
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 11 13" fill="none">
                  <path d="M5.49984 0.65625C4.79536 0.65625 4.11973 0.936104 3.62159 1.43425C3.12344 1.93239 2.84359 2.60802 2.84359 3.3125C2.84359 4.01698 3.12344 4.69261 3.62159 5.19075C4.11973 5.6889 4.79536 5.96875 5.49984 5.96875C6.20432 5.96875 6.87995 5.6889 7.37809 5.19075C7.87623 4.69261 8.15609 4.01698 8.15609 3.3125C8.15609 2.60802 7.87623 1.93239 7.37809 1.43425C6.87995 0.936104 6.20432 0.65625 5.49984 0.65625ZM2.6665 7.38542C1.96202 7.38542 1.2864 7.66527 0.788252 8.16341C0.290108 8.66156 0.0102539 9.33718 0.0102539 10.0417V10.8832C0.0102539 11.4172 0.397004 11.872 0.924004 11.9577C3.95425 12.4528 7.04542 12.4528 10.0757 11.9577C10.3307 11.9162 10.5627 11.7853 10.7301 11.5884C10.8975 11.3916 10.9894 11.1416 10.9894 10.8832V10.0417C10.9894 9.33718 10.7096 8.66156 10.2114 8.16341C9.71328 7.66527 9.03765 7.38542 8.33317 7.38542H8.09234C7.9613 7.38542 7.83096 7.40667 7.707 7.44633L7.09359 7.64679C6.05797 7.98483 4.9417 7.98483 3.90609 7.64679L3.29267 7.44633C3.16847 7.40591 3.03866 7.38535 2.90805 7.38542H2.6665Z" fill="#7F7F7F"/>
                  </svg>
              
              </ListItemIcon>My Profile
            <ListItemIcon style={{position:"absolute",right:"5px"}} >
            </ListItemIcon>
          </MenuItem>
          <MenuItem style={{position:"relative"}} onClick={()=>{handleLogout()}}>
            
            {<ListItemIcon  >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 17" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.24995 8.49976C5.24995 8.63237 5.30263 8.75954 5.3964 8.85331C5.49017 8.94708 5.61734 8.99976 5.74995 8.99976H13.0653L11.758 10.1198C11.7081 10.1625 11.6671 10.2146 11.6373 10.2732C11.6075 10.3318 11.5896 10.3956 11.5845 10.4611C11.5795 10.5266 11.5873 10.5925 11.6077 10.6549C11.6281 10.7174 11.6606 10.7752 11.7033 10.8251C11.746 10.875 11.7981 10.916 11.8567 10.9457C11.9153 10.9755 11.9791 10.9934 12.0446 10.9985C12.1101 11.0036 12.176 10.9957 12.2384 10.9753C12.3009 10.955 12.3587 10.9225 12.4086 10.8798L14.742 8.87976C14.7968 8.83282 14.8409 8.77454 14.8711 8.70895C14.9013 8.64335 14.917 8.57198 14.917 8.49976C14.917 8.42753 14.9013 8.35617 14.8711 8.29057C14.8409 8.22497 14.7968 8.1667 14.742 8.11976L12.4086 6.11976C12.3078 6.03347 12.1769 5.99076 12.0446 6.00101C11.9124 6.01126 11.7896 6.07364 11.7033 6.17443C11.617 6.27521 11.5743 6.40614 11.5845 6.53841C11.5948 6.67069 11.6572 6.79347 11.758 6.87976L13.0646 7.99976H5.74995C5.61734 7.99976 5.49017 8.05244 5.3964 8.14621C5.30263 8.23997 5.24995 8.36715 5.24995 8.49976Z" fill="#7F7F7F"/>
              <path d="M9.75 11.168C9.75 10.7 9.75 10.466 9.63733 10.2973C9.58881 10.2248 9.52652 10.1625 9.454 10.114C9.28533 10.0013 9.05133 10.0013 8.58333 10.0013H5.75C5.35218 10.0013 4.97064 9.84327 4.68934 9.56196C4.40804 9.28066 4.25 8.89913 4.25 8.5013C4.25 8.10348 4.40804 7.72195 4.68934 7.44064C4.97064 7.15934 5.35218 7.0013 5.75 7.0013H8.58333C9.05133 7.0013 9.28533 7.0013 9.454 6.8893C9.52658 6.84058 9.58888 6.77806 9.63733 6.7053C9.75 6.53664 9.75 6.30264 9.75 5.83464C9.75 3.9493 9.75 3.00597 9.164 2.42064C8.57867 1.83464 7.636 1.83464 5.75067 1.83464H5.084C3.19733 1.83464 2.25533 1.83464 1.66933 2.42064C1.08333 3.00597 1.08333 3.9493 1.08333 5.83464V11.168C1.08333 13.0533 1.08333 13.9966 1.66933 14.582C2.25533 15.168 3.198 15.168 5.08333 15.168H5.75C7.636 15.168 8.57867 15.168 9.164 14.582C9.75 13.9966 9.75 13.0533 9.75 11.168Z" fill="#7F7F7F"/>
              </svg>
              </ListItemIcon>}Logout
            <ListItemIcon style={{position:"absolute",right:"5px"}} >
               
            </ListItemIcon>
          </MenuItem>
         

        </Menu>
      </React.Fragment>
    );

  }

const mapStateToProps = (state)=>{
  return{
    lang:state.lang,
    notification_info_redux:state.notification,
    conversation_id_redux:state.conversation_id,
    message_redux:state.message
  }
}
const mapDispatchToState = (dispatch)=>{
  return {
    logout : (name,photo) => {dispatch(login(name,photo))},
    notification_redux : (notification, conversation_id,message) =>{dispatch(echo_info(notification, conversation_id,message))}
    
  }
}
export default connect(mapStateToProps,mapDispatchToState)(AccountMenu)