import styled, { keyframes } from "styled-components";
import { Box } from "@mui/material";
export const BasicContainer = styled.div`
& h6{
    font-size:clamp(0.7rem, 0.29vw + 0.56rem, 0.9rem) !important;

}
& .content{
    font-size:clamp(0.7rem, 0.29vw + 0.56rem, 0.8rem) !important;   
}
`
export const Input = styled.input`
    width:100%;
    height:100%;
    border-radius:14px;
    outline:unset;
    background:rgb(159 159 159 / 24%);
    border:unset;
    padding:10px
`
export const Continer = styled.div`
    padding:40px 0;
    justify-content:center;

& h2{
    color:${props=> props.theme.colors.main.default};
    font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
    font-weight:600;
    font-size:20px;
    text-align:center;
}
& h6{
    color:black;
    font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
    font-weight:500;
    font-size:clamp(0.7rem, 0.29vw + 0.56rem, 0.9rem) !important;
    text-align:center;
    margin-bottom:10px;
}
& img{
    object-fit:contain;
    height:250px;

}
& span{
    display: flex;
    margin-right: 10px;
}
`

export const spin = keyframes`
from { transform: rotate(0deg) }
to { transform: rotate(360deg)}
`
export const BoxCustom = styled(Box)`
&  .content .grid_div{
    
    position: relative;
}
& .content .grid_div img{
    width:100%;
    object-fit: contain;
    max-height:200px;
    filter: grayscale(0.8);
}
& .content .grid_div .first-col {
    width: 50%;
}
& .content .grid_div .second-col {
    width: 50%
}
& .content .grid_div .container_loading{
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    left:0;
    bottom: 0;
    background: rgba(255, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}
& .content .grid_div .one-col{
    width: 100%
}
& .content .grid_div .container_col{
    display: flex;
    background: #7c7575
}
& .content .grid_div .container_loading .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #d80621;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite
    
  }
`