const Host = "https://api.winshakes.com/api"
// const Host = "https://staging-api.winshakes.com/api"

export default {
    auth :{
        login: Host + "/auth",
        register: Host + "/auth", 
        withSocial:(provider)=>(`${Host}/auth/social/${provider}`),
    },
    user:{
        topVendor:`${Host}/users/topVendors`,
        view: Host + "/users",
        store: Host + "/users",
        update:(id)=>(`${Host}/users/${id}`),
        getOne:(id)=>(`${Host}/users/${id}`),
        // getOne:(id)=>(`${Host}/vendors/${id}`),
        setPhone:`${Host}/auth/setPhone`,
        verification: Host + "/verification/send",
        verificationByPhone : Host + "/verification/sendByPhone",
        verficationConfirm : Host + "/verification/confirm",
        verficationForPhone : Host + "/verification/sendVerificationForPhone",
        verficationConfirmByPhone : Host + "/verification/confirmVerificationForPhone",
        setPassword : Host + "/auth/forgottenPassword",
        forgettenPassword : `${Host}/auth/forgottenPassword`,
        changePassword : `${Host}/auth/changePassword`,
        address:`${Host}/userAddresses`,
        getOne_address:(id)=>(`${Host}/userAddresses/${id}`),
        shippingOption : `${Host}/shippingOptions` ,
        new_address:`${Host}/userAddresses`,
        update_address : (id)=>(`${Host}/userAddresses/${id}`),
        addresses_byPostalcode:`${Host}/userAddresses/byPostalcode`,
        addresses_byId:`${Host}/userAddresses/byId`,
        view_shakes:`${Host}/userShakes`,
        getOne_shakes:(id)=>(`${Host}/userShakes/${id}`),
    },
    vendor:{
        friend : `${Host}/friendRequests/sendFriendRequest`,
        sendFriendRequests : `${Host}/friendRequests/sentFriendRequests`,
        unFriend: (id)=>(`${Host}/friends/${id}`),
        cancelRequest: (id)=>(`${Host}/friendRequests/cancelFrienRequest/${id}`),
        follow : `${Host}/followers`,
        unFollow: (id)=>(`${Host}/followers/${id}`),
        viewRequests:`${Host}/friendRequests`,
        acceptRequest: (id)=>(`${Host}/friendRequests/acceptFriendRequest/${id}`),
        rejectRequest: (id)=>(`${Host}/friendRequests/rejectFriendRequest/${id}`),
        allFriends :`${Host}/friends`,
        allFollowers :`${Host}/followers/followers`,
        block: (id)=>(`${Host}/blockedUsers/blockUser/${id}`),
        unBlock: (id)=>(`${Host}/blockedUsers/unBlockUser/${id}`),
        allBlockedUsers:`${Host}/blockedUsers`
    },
    invitation:{
        invitation:`${Host}/invitation/send`
    },
    search:{
        byUser : `${Host}/searchHistory`
    },
    notification:{
        view: `${Host}/notifications/byUser` ,
        read: `${Host}/notifications/byUser`,
        getOne_Notification:(id)=>(`${Host}/notifications/${id}`),
    },
    balance:{
        view : `${Host}/balances` ,
        viewByUser : `${Host}/balances/byUser` ,
        addPoints:`${Host}/balances`,
        sendPoints :`${Host}/balances/transfer`,
        redeem:`${Host}/redeemRequests`
    },
    shakes:{
        start : `${Host}/shake/start` ,
        device: `${Host}/shake` ,
        balance:`${Host}/shake/balance`,
        open:`${Host}/shake/open`,
        deliver:`${Host}/shake/deliver`,
        ship:`${Host}/shake/ship`,
        sell :`${Host}/shake/sell`,
       
    },
    box:{
        getOne :(id)=>(`${Host}/boxCategories/${id}`)
    },
    order:{
        view: `${Host}/orders/byUser`,
        getOne:(id)=>(`${Host}/orders/${id}`), 
        view_AllOrderByUser : `${Host}/orders/allOrdersbyVendor`,
        oneOne_OrderbyVendor: (id)=>(`${Host}/orders/oneOrderbyVendor/${id}`)  
    },
    checkout:{
        view_cart:`${Host}/cartItems`,
        store_cart:`${Host}/cartItems`,
        update_cart:(id)=>(`${Host}/cartItems/${id}`),
        update_delete:(id)=>(`${Host}/cartItems/${id}`),
        address_order:`${Host}/orders`,
        option_order:`${Host}/orders`,
        confirm_order:`${Host}/orders/confirm`,
        sell_shake:`${Host}/orders/sell`,
        storeOrder:`${Host}/orders`
    },
    setting:{
        view:`${Host}/settings`,
        countriesView:`${Host}/countries`,
        language_view : `${Host}/languages`
    },
    page:{
        view:`${Host}/pages`,
        getOne:(id)=>(`${Host}/pages/${id}`)
    },
    section:{
        view:`${Host}/pagesSections`,
        getOne:(id)=>(`${Host}/pagesSections/${id}`),
       
    },
    marketplace:{
        view:`${Host}/products/marketplace`,
    },
    product:{
        all:`${Host}/products/all`,
        view:`${Host}/products`,
        byUser:`${Host}/products/byUser`,
        getOne:(id)=>(`${Host}/products/${id}`),
        update:(id)=>(`${Host}/products/${id}`)
    },
    category:{
        view:`${Host}/categories`,
        getOne:(id)=>(`${Host}/categories/${id}`)
    },
    blog:{
        view:`${Host}/blogs`,
        getOne:(id)=>(`${Host}/blogs/${id}`)
    },
    photos:{
        bulk:`${Host}/photos/bulk`,
    },
    category:{
        view:`${Host}/categories`,
        getOne:(id)=>(`${Host}/categories/${id}`)
    },
    brand:{
        view:`${Host}/brands`,
        getOne:(id)=>(`${Host}/brands/${id}`)
    },
    messages:{
        sendMessage:`${Host}/messages/send`,
        authBrodcast:`${Host}/broadcastingAuth`,
        view:`${Host}/messages`,
        conversation:`${Host}/conversations/ByAuthenticatedUser`,
        support:`${Host}/conversations/support`,
        conversation_getOne:(id)=>(`${Host}/conversations/${id}`),
        message_getOne:(id)=>(`${Host}/messages/${id}`),
        messages_by_conversation:(id)=>(`${Host}/messages/byConversationId/${id}`)
    },
    wishlist:{
        add:(id)=>(`${Host}/wishlists/${id}`),
        // view:`${Host}/wishlists`,
        view:`${Host}/products/wishlistByUser`,
        remove:`${Host}/wishlists/clear`
         
    },
    subscribe:{
        send_mail:`${Host}/mailLists`,
        contact_us:`${Host}/contact-us/send`
    }

}