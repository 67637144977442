import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay,Pagination,FreeMode, Navigation } from 'swiper/modules';

import { useBreakpoint } from "react-use-size";
import { Box, Button, ButtonBase, ButtonGroup, Skeleton,Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { grey } from '@mui/material/colors';
import { Category } from '@mui/icons-material';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';
import { connect } from 'react-redux';

const Container = styled.div`
    padding: 10px 20px;
    @media(min-width:1420px){
        padding: 10px 30px;  
    }


`
const Title = styled.h3`
font-size : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontSize};
font-family : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontFamily};
font-weight : ${props => props.theme.typography.Montserrat_SemiBold_2xl.weight};
line-height :  ${props => props.theme.typography.Montserrat_SemiBold_2xl.lineHeight}


`
const Card = styled.div`
    margin-top:20px;
    overflow:hidden;
    position:relative;
    border-radius:4px;
    border:2px solid rgba(239, 239, 239, 1)

`

const ImgCard = styled.div`
    height:199px;

    // @media(max-width:768px){
    //     height:30%;
    // }
    // @media(min-width:769px) and (max-width:992px){
    //     height:45%;
        
    // }
 
`
const Img = styled.img`
   width:100%;
   height:100%;
   object-fit:contain

`
const SubTitle= styled.div`
    text-align:center;
    color:black;
    display:flex;
    // text-transform:capitalize;
    justify-content:center;
    font-size : 12px;
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_lg.weight};
    // line-height :unset !important
    `
const CategoryCard = styled.div`
    margin-top:20px;
    height:300px;
    // width:200px;
    position:relative;
    border-radius:4px;
    position:relative;
`

const animationMove = keyframes`
from{
  margin-left:-500px;
}to{
  margin-left:0
}
`





function Trends({title,lang}) {
    const isSmall = useBreakpoint(993)
    const navigate = useNavigate()
    const [info,setInfo] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [data,setData] = useState([])
    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
        get_Data(signal)
        return()=>{
          controller.abort()
        }
    },[])
    const get_Data = async (signal) =>{
      setIsLoading(true)
      const {response , message} = await Helper.Get_Abort({
          url:apiRoutes.user.topVendor,
          signal:signal,
          data:{role_id:4,results:20}
      })
      if(response){
          if(response.data.length>0){
            setData(response.data)
            setIsLoading(false)
          }
         
      }

  }
    return ( <Container id="trends">
        <Title>Trending stores</Title>
        <Swiper style={{display:"flex",justifyContent:"center"}}

        // pagination={false}
        spaceBetween={40}
        slidesPerView={4}
        grabCursor={true}    
        breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              centeredSlides:true,

            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
 
            //   spaceBetween: 24,
            },
            // when window width is >= 640px
            500: {
                slidesPerView: 3,
              //   spaceBetween: 24,
              },
            800: {
              slidesPerView: 4,
            //   spaceBetween: 24,
            },
            992:{
                slidesPerView: 4,
            },
            1235: {
              slidesPerView: 6,
            //   spaceBetween: 32,
            //   slidesPerGroup: 1,
            },
            1336: {
              slidesPerView: 7,
            //   spaceBetween: 32,
            },
          }}
        // effect={'fade'}
        // navigation={!isSmall&&true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {(!isLoading && data.length>0 )? data.map((e,i)=>(<SwiperSlide  key={e.name?e.name:`trends_${i}`}  >

        <Card style={{position:"relative",width:"100%"}}>
                                <Box  sx={{
                                    position:"absolute",
                                    zIndex:"-1",
                                    top:"0",
                                    right:"0",
                                    left:"0",
                                    background:e.cover?`url(${e.cover})`:"#80808038",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height:"90px"
                                }}></Box>
                                <Box sx={{background: "#ffffff00", marginTop: "60px"}} >
                                    <Box  >
                                        <Box display="flex" justifyContent="center">
                                        <Avatar alt={e.name?e.name:""} src={e.photo} sx={{borderRadius:"50% !importnant", width:55, height:55}}/>
                                        </Box>
                                        <SubTitle >
                                            {e.username}
                                        </SubTitle>
                                        {(e.badge && e.badge.photo && e.badge.translations) ?<SubTitle style={{display:"flex",alignItems:"center"}} >
                                            <img style={{width:'12px',height:"12px",marginRight:"5px",objectFit:"contain"}} src={e.badge.photo?e.badge.photo:<LocalPoliceIcon/>} />
                                            {e.badge.translations?e.badge.translations[lang].name:"Golden Vendor"}
                                        </SubTitle>:<SubTitle style={{display:"flex",alignItems:"center"}} >
                                            <div style={{width:'12px',height:"12px",marginRight:"5px",objectFit:"contain"}}  ><LocalPoliceIcon style={{color:"#d80621"}}/></div>
                                            {"Star Vendor"}
                                        </SubTitle>}
                                        
                                        <Typography sx={{fontSize:"11px"}} display="flex" justifyContent="center">
                                            {e.products_count} products
                                        </Typography>
                                        {/* <Box sx={{padding:"10px 0"}} display="flex" justifyContent="center">
                                        {[0,1,2,3,4].map(()=><StarIcon key={`stars_${}`} style={{color:"#FFC669",fontSize:"0.8em"}} />)}
                                        </Box> */}
                                        <Box sx={{paddingTop:"10px"}} display="flex" justifyContent="center">
                                            <Button sx={{
                                                width:"100%",
                                                    background:"#d80621",
                                                    borderRadius:"unset !important",
                                                    border:"1px solid  #d80621",
                                                    color:"white",
                                                    transition:"0.5s",
                                                    textTransform:"capitalize !important",
                                                    fontSize:"12px !important",
                                                    padding:"5px 20px",
                                                    "@media(max-width:600px)":{
                                                    padding:"5px 8px"
                                                    },
                                                    "&:hover":{
                                                        background:"white",
                                                        border:"1px solid  #d80621",
                                                        color:"#d80621"
                                                    }
                                                }} onClick={()=>{
                                                    if(localStorage.hasOwnProperty("user")&& JSON.parse(localStorage.getItem("user")).id == e.id){
                                                        navigate(`/profile/${e.username}/profile`)
                                                    }else{
                                                        navigate(`/vendor/${e.username}/${e.id}`)
                                                    }
                                                   }}>
                                                    {localStorage.hasOwnProperty("user") && JSON.parse(localStorage.getItem("user")).id == e.id?"My Profile":"Visit Store"}
                                            
                                            </Button>

                                        </Box>
                                    </Box>
                                        
                                    
                                </Box>
                
           

                 
               

                                  </Card>
        </SwiperSlide>)):[1,2,3,4,5,6,7,8].map((e,index)=><SwiperSlide key={`loading_trend_${index}`} >
            <div>
            <Skeleton animation="wave" width={120} height={200}  />
            {title =="trending stores"&&<Skeleton animation="wave" width={120} height={60}  />}
            </div>
        </SwiperSlide>)}
        
      </Swiper>
        

    </Container> );
}
const mapStateToProps = (state)=>{
  return{
    lang:state.lang
  }
}
export default connect(mapStateToProps,null)(Trends);