const startTime = localStorage.getItem("start")
export const clear_localStorage_after_two_hours = () => {
        if(startTime){
            if((Date.now()-startTime)>3600*5*1000){
                localStorage.removeItem("start")
                localStorage.clear()
                localStorage.setItem("start",Date.now())
                window.location.href = "/"
            }
        }else{
            localStorage.setItem("start",Date.now())
        }
   
} 