import React from 'react';
import { Button } from '@mui/material';
import { useSwiper } from 'swiper/react';
import styled from 'styled-components';
const Arrows = styled.div`
    display:flex;
    position:absolute;
    bottom:0;
    z-index:10;
    gap:20px;
    @media(max-width:895px){
        padding:10px 30px;
        position:absolute;
        bottom:300px;
        right:0;
        left:0;
        bottom:0;
        display:flex;
        justify-content:space-between;
        align-items:center;
        z-index:20
    }
`
const BottonControllerSwiper = ({bla})=>{
    const swiper = useSwiper()
    return (<Arrows>
                <Button id="prev-step" style={{display:"none"}}  variant="contained" color="secondary"  onClick={()=>swiper.slidePrev()}> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.29303 12.7083C8.10556 12.5208 8.00024 12.2665 8.00024 12.0013C8.00024 11.7362 8.10556 11.4818 8.29303 11.2943L13.95 5.63732C14.0423 5.54181 14.1526 5.46563 14.2746 5.41322C14.3966 5.36081 14.5279 5.33322 14.6606 5.33207C14.7934 5.33092 14.9251 5.35622 15.048 5.4065C15.1709 5.45678 15.2825 5.53103 15.3764 5.62492C15.4703 5.71882 15.5446 5.83047 15.5949 5.95337C15.6451 6.07626 15.6704 6.20794 15.6693 6.34072C15.6681 6.4735 15.6405 6.60472 15.5881 6.72672C15.5357 6.84873 15.4595 6.95907 15.364 7.05132L10.414 12.0013L15.364 16.9513C15.5462 17.1399 15.647 17.3925 15.6447 17.6547C15.6424 17.9169 15.5373 18.1677 15.3518 18.3531C15.1664 18.5385 14.9156 18.6437 14.6534 18.646C14.3912 18.6483 14.1386 18.5475 13.95 18.3653L8.29303 12.7083Z" fill="white"/>
                    </svg> 
                </Button>
                <Button id="next-step" style={{display:"none"}} variant="contained" color="secondary" onClick={()=>swiper.slideNext()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.707 12.7083C15.8944 12.5208 15.9998 12.2665 15.9998 12.0013C15.9998 11.7362 15.8944 11.4818 15.707 11.2943L10.05 5.63732C9.95772 5.54181 9.84738 5.46563 9.72537 5.41322C9.60337 5.36081 9.47215 5.33322 9.33937 5.33207C9.20659 5.33092 9.07491 5.35622 8.95202 5.4065C8.82912 5.45678 8.71747 5.53103 8.62357 5.62492C8.52968 5.71882 8.45543 5.83047 8.40515 5.95337C8.35487 6.07626 8.32957 6.20794 8.33072 6.34072C8.33187 6.4735 8.35946 6.60472 8.41187 6.72672C8.46428 6.84873 8.54046 6.95907 8.63597 7.05132L13.586 12.0013L8.63597 16.9513C8.45381 17.1399 8.35302 17.3925 8.3553 17.6547C8.35757 17.9169 8.46274 18.1677 8.64815 18.3531C8.83356 18.5385 9.08437 18.6437 9.34657 18.646C9.60876 18.6483 9.86137 18.5475 10.05 18.3653L15.707 12.7083Z" fill="white"/>
                    </svg>
                </Button>
            </Arrows>
    )
}

export default BottonControllerSwiper;