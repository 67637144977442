import React , { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonApp from '../../dynamic-pages/components/button_application';
// animation 
import { Zoom } from "react-awesome-reveal";
import { useSnackbar } from "notistack";
import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
//axios
import axios from 'axios';
import { Helper } from "../../../tools/helper";
import apiRoutes from "../../../apiRoutes";
import LoadingPage from '../../../componentExternal/loadingPage';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';



const Container = styled.div`
padding:30px 70px 0 70px ;
overflow:hidden;
@media(max-width:500px){
  padding:30px 10px;  
}
@media(min-width:501px) and (max-width:992px){
    padding:10px 30px;
}
`
const FlexDivReverse = styled.div`
display:flex;

@media(max-width:500px){
    flex-direction:column-reverse;
    margin: 30px 0px !important;
}
@media(min-width:500px) and (max-width:992px){
    flex-direction:column-reverse;
    margin: 30px 20px !important;
}


`
const First = styled.div`
    width:50%;
    display:flex;
    overflow:hidden;
    align-items:center;
    @media(max-width:992px){
        width:100%;
        justify-content:center 
    }
`
const SecondImage = styled.div`
width:50%;
display:flex;
justify-content:end;
@media(max-width:992px){
    width:100%;
    justify-content:center
}
`
const Title = styled.h1`
    text-transform:capitalize;
    color:${props => props.theme.colors.main.default} ;
    font-size:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_SemiBold_5xl.weight};
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    line-height:unset !important;
    @media(max-width:992px){
        font-size:27px
    }
`
const Paragraph = styled.p`
    margin-top:5px;
    padding:0 20px 0 0 ;
    color:black;
    font-size:${props => props.theme.typography.Montserrat_Regular_lg.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Regular_lg.weight};
    font-family:${props => props.theme.typography.Montserrat_Regular_lg.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_lg.lineHeight};
    @media(max-width:992px){
        font-size:12px;
        padding: 0 ;
        width:100% !important
    }
`
const Div = styled.div`




`
const SonDiv = styled.div`
@media(max-width:768px){
    text-align:center;
    margin:auto;
    width:80%;
}
@media(min-width:768px) and (max-width:992px){
    text-align:center;
    margin:auto;
    width:100%
}


`
const CardImgShake = styled.div`
height:400px;
width:100%;
display:flex;

@media(max-width:992px){
    transform:scale(1);
    height:300px;
}

`

const Img = styled.img`
width:100%;
height:100%;
border-radius:8px;
object-fit:contain;
@media(max-width:992px){
    // width:90%;
    
}

` 
const Application = styled.div`
display:flex;
margin-top:50px;
gap:20px;
@media(max-width:992px){
    justify-content:center
}


`
const Icon = styled.span`
    margin-right:4px;

`
function Introduction({lang}) {
    const navigate = useNavigate()
    const isSmall = useBreakpoint(769)
    const { enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(false)
    const [ page, setPage ]= useState({})
    // AOS.init()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPages(signal)
        return()=>{
           controller.abort()
        }
        
    },[])
    const getPages = async(signal)=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            signal:signal,
            hasToken:true,
            data:{name:"shake_hero"}
        })
        if(response){
            if(response.data.length > 0){
                setPage(response.data[0])

                // enqueueSnackbar("Successfully Register",{variant:"success"}) 
                setIsLoading(false)
            }
            
          
        }
    }
    return (isLoading?<LoadingPage/>:<Container id="home">
        <FlexDivReverse >
        <First>
        <Div>
            <SonDiv>
                {/* <Title > */}
                    
                
                <TypeAnimation sequence={[
        
                        1000, // wait 1s before replacing "Mice" with "Hamsters"
                        page.translations&&page.translations[lang].name
                    ]}
                    wrapper="h1"
                    speed={10}
                    style={{  display: 'inline-block' }}
                    repeat={Infinity}
                
                    />
                
                {/* </Title> */}
                <Paragraph 
                //  data-aos="fade-up"
                //         data-aos-delay="2000"
                //         data-aos-duration="1000"
                        dangerouslySetInnerHTML={{__html:page.translations&&page.translations[lang].description}}
                />
                           
                
            </SonDiv>
            <Application >
                <ButtonApp isHero={true}  />
            </Application>
        </Div>

    </First>
    <SecondImage>
        <Zoom>
        <CardImgShake>
            <Img alt={page.name?page.name:""} src={page.photo?page.photo:""} />
        </CardImgShake>
        </Zoom>

    </SecondImage>

</FlexDivReverse>
    </Container> );
}

export default Introduction;