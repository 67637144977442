import React , { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box,Skeleton } from '@mui/material';
// animation 
import { useSnackbar } from "notistack";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useBreakpoint } from 'react-use-size';
//axios
import { Helper } from "../../../tools/helper";
import apiRoutes from "../../../apiRoutes";
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const Container = styled.div`
padding:0px 70px;
overflow:hidden;
@media(max-width:500px){
  padding:30px 10px;  
}
@media(min-width:501px) and (max-width:768px){
    padding:10px 30px;
}
@media(min-width:769px) and (max-width:992px){
    padding:10px 10px;
}


`
const FlexDiv = styled.div`
    text-align:center;
    margin:20px 0;
   


`

const Title = styled.h2`
    text-transform:capitalize;
    color:black;
    font-size:25px;
    font-weight:${props => props.theme.typography.Montserrat_SemiBold_5xl.weight};
    font-family:${props => props.theme.typography.Montserrat_SemiBold_5xl.fontFamily};
    line-height:unset !important;
    @media(max-width:992px){
        font-size:25px
    }
`
const Paragraph = styled.p`
    margin-top:5px;
    padding:0 20px 0 0 ;
    color:black;
    min-height:150px;
    display:flex;
    align-items:center;
    font-size:${props => props.theme.typography.Montserrat_Regular_lg.fontSize};
    font-weight:${props => props.theme.typography.Montserrat_Regular_lg.weight};
    font-family:${props => props.theme.typography.Montserrat_Regular_lg.fontFamily};
    line-height:${props => props.theme.typography.Montserrat_Regular_lg.lineHeight};
    @media(max-width:768px){
        min-height:130px;
        font-size:12px;
        padding: 0 ;
        width:100% !important
    }
`

const Buttons = styled.div`
display:flex;
margin-top:50px;
gap:20px;
justify-content:center


`
function Thirll({lang}) {
    const navigate = useNavigate()
    const isSmall = useBreakpoint(769)
    const { enqueueSnackbar} = useSnackbar()
    const [isLoading, setIsLoading] = useState(true)
    const [ page, setPage ]= useState({})
    // AOS.init()
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        getPages(signal)
        return ()=>{
            controller.abort()
        }
    },[])
    const getPages = async(signal)=>{
        setIsLoading(true)
        const {response,message } = await Helper.Get_Abort({
            url:apiRoutes.page.view,
            hasToken:true,
            signal:signal,
            data:{name:"more_thrill"}
        })
        if(response){
            if(response.data.length>0){
                setPage(response.data[0])
                setIsLoading(false)
            }
            
          
        }
    }
    return (isLoading?<Box sx={{width:"100%"}}>
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <Skeleton animation="wave" height={20} sx={{width:"70%"}} />
        </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <Skeleton animation="wave" height={30} sx={{width:"80%"}} />
           
        </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <Skeleton animation="wave" height={30} sx={{width:"70%"}} />
           
        </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
            <Skeleton animation="wave" height={30} sx={{width:"60%"}} />
           
        </Box>

    </Box>:<Container>
        <FlexDiv>
                <div>
                    <Title
                    //  data-aos="fade-up"
                    //     data-aos-delay="1000"
                    //         data-aos-duration="1000"
                            >
                    {((lang in page.translations) && page.translations[lang].name) ? page.translations[lang].name :"" }
                    </Title>
                    <Paragraph 
                    // data-aos="fade-up"
                    //         data-aos-delay="1500"
                    //         data-aos-duration="1000"
                             dangerouslySetInnerHTML={{__html:((lang in page.translations) && page.translations[lang].description)?page.translations[lang].description:""}}>                      
                     </Paragraph>
                   
                    
                </div>
                <Buttons>
                   <Link to="/page/win-and-earn">
                   <Button 
                //    data-aos="fade-up"
                //         data-aos-delay="1500"
                //         data-aos-duration="1000"
                         sx={{
                        background:"#d80621",
                        borderRadius:"4px",
                        border:"1px solid  #d80621",
                        color:"white",
                        transition:"0.5s",
                        textTransform:"capitalize !important",
                        fontSize:"16px !important",
                        padding:"5px 40px",
                        "&:hover":{
                            background:"white",
                            border:"1px solid  #d80621",
                            color:"#d80621"
                        }
                    }} >Learn More</Button>
                   </Link>
                    
                    
                </Buttons>

            

        </FlexDiv>
    </Container> );
}

export default Thirll;