

export const login = (username,photo)=>{
    return {
        type:"LOGIN-USER",
        username: username,
        photo:photo
    }

}
export const Language = (lang)=>{
    return{
        type:"LANGUAGE",
        lang:lang
    }
}
export const setting = (google_play,app_store,conversion_rate,charge_fee,open_box_fee,
    telegram_number,facebook_link,youtube_link,whatsapp_number,main_email)=>{

    return{
        type:"SETTING",
        google_play: google_play,
        app_store:app_store,
        conversion_rate:conversion_rate,
        charge_fee:charge_fee,
        open_box_fee:open_box_fee,
        telegram_number:telegram_number,
        facebook_link:facebook_link,
        youtube_link:youtube_link,
        whatsapp_number:whatsapp_number,
        main_email:main_email
    }
} 
export const search_value = (search)=>{
    
    return{
        type:"SEARCH",
        search: search,

    }
} 
export const send_msg_dir = (messageDir,urlProduct)=>{
    
    return{
        type:"DIRECT-MESSAGE",
        messageDir: messageDir,
        urlProduct:urlProduct
    }
} 
export const url = (url,nextUrl)=>{
    return{
        type:"PATH-NAME-LOCATION",
        url: url,
        nextUrl:nextUrl

    }
} 
export const chat_open_popup =(isOpenPopup,isOpenConversation,userOther )=>{
    return{
        type:"CHAT-POPUP",
        isOpenPopup:isOpenPopup,
        userOther:userOther,
        isOpenConversation:isOpenConversation
    }
}
export const get_cart = (cart)=>{
    return{
        type:"GET-CART",
        cart:cart,
    }
}
export const change_balance = (balance)=>{
    return{
        type:"CHANGE-BALANCE",
        balance:balance
    }
}
export const notification_Cart = (number,isOpen)=>{
    return{
        type:"GET-NOTIFICATION_CART",
        number:number,
        isOpen:isOpen
    }
}
export const echo_info = (notification,conversation_id)=>{
    return{
        type:"ECHO_NOTIFICATIONS",
        notification : notification,
        conversation_id : conversation_id,
    }
}
export const echo_message= (message)=>{
    return{
        type:"ECHO_MESSAGE",
        message:message
    }
}