import React, { useEffect, useRef, useState } from 'react'
import { TypeAnimation } from 'react-type-animation';
import LoadingBar from 'react-top-loading-bar'
import { CircularProgress } from '@mui/material';
import styled from 'styled-components'



function LoadingPage() {
    const [progress, setProgress] = useState(0)
    const ref = useRef()
    useEffect(()=>{
            ref.current.continuousStart()

    },[])
    const Container = styled.div`
        display:flex;
        justify-content:center;
        height:400px;
        width:100%;
        align-items:center
    `
    
    



    return (  <div>
        <LoadingBar height={"5px"} color="#d80621" progress={progress} ref={ref}  />
        <br/>
        <Container>
            <CircularProgress fontSize="large" color="primary" />
        </Container>
      </div> );
}

export default LoadingPage;