import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import imgProduct from "../../../assets/images/product-photo.png"
import imgTop from "../../../assets/images/Rectangle 1058.png"
import imgCat from "../../../assets/images/Rectangle 3771.png"
import MDButton from '../../../components/MDButton';
import profile from "../../../assets/images/profile.png"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay,Pagination,FreeMode, Navigation } from 'swiper/modules';

import { useBreakpoint } from "react-use-size";
import { ButtonBase, ButtonGroup, Skeleton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Category } from '@mui/icons-material';
//api request
import { Helper } from '../../../tools/helper';
import apiRoutes from '../../../apiRoutes';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LoadingPage from '../../../componentExternal/loadingPage';

const Container = styled.div`
    padding: 10px 2%;
    @media(min-width:1420px){
        padding: 10px 4%;  
    }


`
const Title = styled.h2`
font-size : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontSize};
font-family : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontFamily};
font-weight : ${props => props.theme.typography.Montserrat_SemiBold_2xl.weight};
line-height :  ${props => props.theme.typography.Montserrat_SemiBold_2xl.lineHeight}


`
const Card = styled.div`
    margin-top:20px;
    height:300px;
    width:200px;
    overflow:hidden;
    position:relative;
    border-radius:4px;

`

const ImgCard = styled.div`
    height:199px;

    // @media(max-width:768px){
    //     height:30%;
    // }
    // @media(min-width:769px) and (max-width:992px){
    //     height:45%;
        
    // }
 
`
const Img = styled.img`
   width:100%;
   height:100%;
   object-fit:contain

`
const SubTitle= styled.div`
    text-align:center;
    padding-top:10px;
    font-size : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontSize};
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_lg.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_lg.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_lg.lineHeight}
    `
const CategoryCard = styled.div`
    margin-top:20px;
    height:300px;
    // width:200px;
    position:relative;
    border-radius:4px;
    position:relative;
`
const ImgCat = styled.img`
  width:100%;
  height:100%;
  object-fit:contain;
  transition:0.2s;
  &:hover{
    filter:grayScale(1)
  }

`
const animationMove = keyframes`
from{
  margin-left:-500px;
}to{
  margin-left:0
}
`
const NameCat = styled.div`
    height:90%;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    position:absolute;
    top:0;
    right:0;
    left:0px;
    bottom:0;
    z-index:1;
    background:inherit;
    opacity:0;
    transition:0.5s;
    color:white;
    // background:#0000007a;
    border-radius:20px;
    font-size : 20px;
    font-family : ${props => props.theme.typography.Montserrat_SemiBold_2xl.fontFamily};
    font-weight : ${props => props.theme.typography.Montserrat_SemiBold_2xl.weight};
    line-height :  ${props => props.theme.typography.Montserrat_SemiBold_2xl.lineHeight};
    &:hover{
      opacity:1;
     
    }
    &:hover .title{
      margin-left:0px;
    
      width:200px;
      background:#000000a3;
      animation : ${animationMove} 0.7s ease;
    }


`




function GroupSection({title,index,isCat,lang}) {
    const isSmall = useBreakpoint(993)
    const navigate = useNavigate()
    const [info,setInfo] = useState([])
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true)
    const [data,setData] = useState([])
    useEffect(()=>{
        get_Data()
    },[])
    const get_Data = async () =>{
      setIsLoading(true)
      const {response , message} = await Helper.Get({
          url:title =="trending stores"?apiRoutes.user.view:apiRoutes.category.view,
          data:title =="trending stores"?{role_id:4}:{results:20,parent:0}
      })
      if(response){
          response.data.forEach(ele=>{
              setData(prev=>[...prev,{
                  name:ele.translations[lang]?ele.translations[lang].name:"",
                  photo:ele.photo?ele.photo:imgCat,
                  id:ele.id
              }])
          })
          setIsLoading(false)
      }else{
          // if(typeof(message) === "string"){
          //     enqueueSnackbar(message,{variant:"error"}) 
          // }else{
          //     Object.keys(message).map((key)=>{
          //         enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error"}) 
          //     })
          // }
         
          // setIsLoading(false)
      }

  }
    return ( <Container id="products">
        <Title >{title}</Title>
        
        <Swiper style={{display:"flex",justifyContent:"center"}}

        pagination={false}
        spaceBetween={40}
        slidesPerView={4}
        grabCursor={true}    
        breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              centeredSlides:true,

            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
 
            //   spaceBetween: 24,
            },
            // when window width is >= 640px
            500: {
                slidesPerView: 3,
              //   spaceBetween: 24,
              },
            992:{
                slidesPerView: 3,
            },
            1235: {
              slidesPerView: 4,
            //   spaceBetween: 32,
            //   slidesPerGroup: 1,
            },
            1336: {
              slidesPerView: 4,
            //   spaceBetween: 32,
            },
          }}
        // effect={'fade'}
        navigation={!isSmall&&true}
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        {(!isLoading && data.length>0 )? data.map((e)=>(<SwiperSlide  >
            <Card>
              <CategoryCard  style={{cursor:"pointer"}} onClick={()=>{
                    navigate(`/products/category/${e.id}`)
                }}>
                <ImgCat alt={e.name} src={e.photo}  />
                <NameCat>
                  <div className='title'>
                  {e.name}
                  </div>
                </NameCat>
              </CategoryCard>
                 
               

            </Card>
        </SwiperSlide>)):[1,2,3,4].map(e=><SwiperSlide  >
            <div>
            <Skeleton animation="wave" width={120} height={200}  />
            {title =="trending stores"&&<Skeleton animation="wave" width={120} height={60}  />}
            </div>
        </SwiperSlide>)}
        
      </Swiper>
        

    </Container> );
}

export default GroupSection;