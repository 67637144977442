import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { BrowserRouter , Route, Routes, HashRouter } from "react-router-dom";
import { useLocation ,useNavigate} from 'react-router-dom';
import {allRoutes} from './Routes';
//api request
import { connect } from "react-redux";
import { setting, url } from "./redux/action";
import apiRoutes from './apiRoutes';
import { Helper } from './tools/helper';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { clear_localStorage_after_two_hours } from './functions/clearLocalStorage';

// components
import Home from "./pages/home";
import MDFooter from "./components/MDFooter";
import SubNav from "./components/MDNavbar/subNavbar";
import MDNavbar from "./components/MDNavbar";
import ChatFixed from './pages/chat/chatFixed';
import LoadingPage from './componentExternal/loadingPage';
// styles and themes
import RedTheme from "./theme/redTheme";
import styled from "styled-components";
import { LightTheme ,RedThemeMui} from './theme/muiTheme';
import { TextField, ThemeProvider ,Button, Grid,Box, IconButton,Tooltip} from '@mui/material';
import Modal from '@mui/material/Modal';
import Close from '@mui/icons-material/Close';

const Page_404 = lazy(() => import('./pages/error/404_Page'));



const DivHelp = styled.div`
height:90px;
@media(max-width:992px){
    height:70px
}
`
const NavWithScroll = ()=>{
    const [ isScroll, setIsScroll ] = useState(false)

    const eventScroll =() =>{
        let windowHeight = window.scrollY
        if(windowHeight >145){
            setIsScroll(true)
        }else{
            setIsScroll(false)
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll",eventScroll)
        clear_localStorage_after_two_hours()
        
    })
    return(<>
            <MDNavbar isScroll={isScroll} className={isScroll?"":""}  />
            {/* <MDNavbar style={isScroll?{display:"none"}:{transition:"all 0.5s ease 0s"}} /> */}
            <SubNav isScroll={isScroll} className={isScroll?"fixed-nav":"nav"} />
            {isScroll&&<DivHelp/>}
    </>)

}
const ComponentCatchUrlToShowMessages = ({url,changeUrl,nextUrl})=>{
    const location = useLocation()
    const [viewModal, setViewModal] = useState(false)
    const [action, setAction] = useState("")
    const navigate = useNavigate()

    useEffect(() =>{
        const path = window.location.origin
        if(path+url==`${path}/shakes/pay` && path+location.pathname != `${path}/shakes/select`){
            setAction("after-select-shake")
            setViewModal(true)
        }else if(path+url==`${path}/shakes/select` && (path+location.pathname != `${path}/shakes/select` && path+location.pathname != `${path}/shake/pay/address`&& path+location.pathname != `${path}/shakes/box/products`) ){
            setAction("after-pay-shake")
            setViewModal(true)
        }else if(path+url==`${path}/pay` && (path+location.pathname != `${path}/order/pay/address`&& path+location.pathname != `${path}/pay/items` &&path+location.pathname != `${path}/pay`)){
            setAction("after-pay-order")
            setViewModal(true)
        }
      },[location])
      return<div id="app">
      <Modal 
                    open={viewModal }
                    onClose={()=>{setViewModal(false)}}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute' ,
                        top: '50%',
                        left: '50%',
                        borderRadius:"8px",
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        "@media(max-width:300px)":{
                            width:"280px",
                            padding:"10px 5px !important"
                        },
                        "@media(min-width:301px) and (max-width:400px)":{
                            width:"350px",
                            padding:"10px 5px !important"
                        },
                        "@media(min-width:401px) and (max-width:600px) ":{
                            width:"400px"
                        },
                        "@media(min-width:601px)":{
                            width:"550px"
                        },
                }}>
                        <Box sx={{position:"relative",height:"50px"}}>
                            <IconButton onClick={()=>{setViewModal(false)}} sx={{color:"black",position:"absolute",right:"0px",top:"0px"}}>
                                <Close/>
                            </IconButton>
                        </Box>
                        <Box sx={{textAlign :"center",marginBottom:"10px"}}>
                            <h6 style={{color:"black",fontSize:"18px"}}>
                                {action == "after-pay-shake" && "Payment completed successfully, click continue for completing deliver items or open box" }
                                {action == "after-select-shake" && "Complete to pay for this selected box and have a good attempt." }
                                {action == "after-pay-order" && "Payment completed successfully, click continue for completing the process" }
                            </h6>
                           

                        </Box>
                        <Grid   container>
                            <Grid item sx={{display:"flex",justifyContent:"center",padding:"10px 0"}} lg={6} md={6} sm={12} xs={12} xl={6}>
                            <Button onClick={()=>{
                                // navigate(nextUrl)
                                changeUrl("")
                                setViewModal(false)}} sx={{
                                width:'80%',
                                background:"white",
                                borderRadius:"4px",
                                border:"1px solid #d80621",
                                color:" #d80621",
                                transition:"0.5s",
                                textTransform:"capitalize !important",
                                fontSize:"16px !important",
                                padding:"5px 40px ",
                                "&:hover":{
                                    background:"#d80621",
                                    border:"1px solid white ",
                                    color:"white"
                                }
                            }}
                            >Cancel</Button>
                            </Grid>
                            <Grid item sx={{display:"flex",justifyContent:"center",padding:"10px 0"}} lg={6} md={6} sm={12} xs={12} xl={6}>
                            <Button onClick={()=>{
                                navigate(url)
                                changeUrl("")
                                setViewModal(false)}} sx={{  width:'80%',
                                            background:"#d80621",
                                            borderRadius:"4px",
                                            border:"1px solid  #d80621",
                                            color:"white",
                                            transition:"0.5s",
                                            textTransform:"capitalize !important",
                                            fontSize:"16px !important",
                                            padding:"5px 40px",
                                            "&:hover":{
                                                background:"white",
                                                border:"1px solid  #d80621",
                                                color:"#d80621"
                                            }
                                        }}
                            >{"continue"}
                                
                                </Button>
                            </Grid>
                        </Grid>
                            
                    </Box>
                </Modal>
      </div>
}
function App({setting,url,changeUrl,nextPath}) {
    
    const [isLoading, setIsLoading] = useState(false)
   
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        get_settings(signal)
        return ()=>{
            controller.abort()
        }
       
      },[])

    const get_settings = async(signal)=>{
        const {response , message} = await Helper.Get_Abort({
            url:apiRoutes.setting.view,
            signal:signal,
            hasToken:true,
        })
        if(response){
            setting(response.data.google_play_link,
                    response.data.app_store_link,
                    response.data.conversion_rate,
                    response.data.charge_fee,
                    response.data.open_box_fee,
                    response.data.telegram_number,
                    response.data.facebook_link,
                    response.data.youtube_link,
                    response.data.whatsapp_number,
                    response.data.main_email
                )
        }
    }
   
  return(
    
    <RedTheme>
      <ThemeProvider theme={RedThemeMui}>
        
          <BrowserRouter basename="/">
              <SnackbarProvider autoHideDuration={2000} maxSnack={3}>
                {/* Component to catch Url and handle pending status in shake-order proccess to show messages to user */}
                    <ComponentCatchUrlToShowMessages nextUrl={nextPath} url={url} changeUrl={changeUrl} />
                {/* Component to catch Url and handle pending status in shake-order proccess to show messages to user */}
                    <NavWithScroll/>
                {/* Note in any change in this route you do , you must change it in site map from backend team.. */}
                    <Routes>
                    {allRoutes.map(e=>{
                        if(e.children){
                            return<Route key={e.key} path={e.route} element={
                                <Suspense fallback={<LoadingPage />}>
                                    {e.component}
                                </Suspense>}>
                                   
                                    {e.children.map(child=>{
                                        if(child.children){
                                            return <Route key={child.key} path={child.route} element={
                                                <Suspense fallback={<LoadingPage />}>
                                                    {child.component}
                                                </Suspense>}>
                                                   { child.children.map(subChild=><Route key={subChild.key} path={subChild.route} element={
                                                <Suspense fallback={<LoadingPage />}>
                                                    {subChild.component}
                                                </Suspense>}/>)}
                                                </Route>
                                           
                                        }else{
                                            return<Route key={child.key} path={child.route} element={
                                                <Suspense fallback={<LoadingPage />}>
                                                    {child.component}
                                                </Suspense>}/>
                                        }
                                    })}
                                </Route>
                        }else{
                            return <Route key={e.key} path={e.route} element={
                                <Suspense fallback={<LoadingPage />}>
                                    {e.component}
                                </Suspense>}/>
                        }
                    })}
                    <Route path="/*" element={
                        <Suspense fallback={<LoadingPage />}>
                            <Page_404/>
                        </Suspense>}/>
                    </Routes>

                  <MDFooter/>

                  {/* Support Chat */}
                        <ChatFixed/>
                  
              </SnackbarProvider>
          </BrowserRouter>

    </ThemeProvider>  
  </RedTheme> 
    )

}
const mapStateToProps = (state)=>{
    return{
        url:state.url,
        nextPath:state.nextUrl
    }
}
const mapDispatchToState = (dispatch)=>{
    return{
        setting: (google_play,app_store,conversion_rate,charge_fee,open_box_fee,telegram_number,facebook_link,youtube_link,whatsapp_number,main_email)=>{dispatch(setting(google_play,app_store,conversion_rate,charge_fee,open_box_fee,telegram_number,facebook_link,youtube_link,whatsapp_number,main_email))},
        changeUrl : (path,nextPath)=>{dispatch(url(path,nextPath))}
    }
}
export default connect(mapStateToProps,mapDispatchToState)(App);
