import { Theme, createTheme,  } from "@mui/material";
// import 'https://fonts.googleapis.com/css2?family=Croissant+One&family=Montserrat:wght@400;600;700&family=Oswald&family=Playfair+Display:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@400;600;700&display=swap'
// import 'https://fonts.googleapis.com/css2?family=Croissant+One&family=Montserrat:wght@400;600;700&family=Oswald&family=Playfair+Display:ital,wght@0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@600&family=Roboto+Slab:wght@400;600;700&display=swap'

export const LightTheme = createTheme({
    palette: {
        primary: {
          main: '#7934be'
        },
        secondary:{
            main:"#9f6abb"
        },
        error:{
            main:"#be0d0d"
        },
        



      },
      breakpoints :{
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
          xxl: 1400,
        },
      },
    typography:{
        //Montserrat_Bold_5xl
        h1:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"48px",
            lineHeight:"48px"
        },
        //Montserrat_SemiBold_base
        h2:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"16px",
            lineHeight:"24px"  
        },
        h3:{},
        h4:{},
        h5:{},
        h6:{},
        ///botton
        button:{

        },
        Montserrat_Bold_xl:{
        fontFamily:"Montserrat",
        weight:700,
        fontSize:"20px",
        lineHeight:"30px"
        
        },
        Montserrat_Bold_2xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"24px",
            lineHeight:"60px"
            
        },
        Montserrat_Bold_3xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"30px",
            lineHeight:"37.5px"
            
        },
        Montserrat_Bold_4xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"36px",
            lineHeight:"45px"
        },
        Montserrat_Bold_5xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"48px",
            lineHeight:"48px"
            
        },
        Montserrat_Regular_sm:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"14px",
            lineHeight:"17.5px"
        },
        Montserrat_Regular_base:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"16px",
            lineHeight:"24px" 
        },
        Montserrat_Regular_2xl:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"24px",
            lineHeight:"36px" 
        },
        Montserrat_SemiBold_base:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"16px",
            lineHeight:"24px"  
        },
        Montserrat_SemiBold_sm:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"14px",
            lineHeight:"17.5px"  
        },
            Montserrat_SemiBold_lg:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"18px",
            lineHeight:"27px"  
        }
    }

})
export const RedThemeMui = createTheme({
    palette: {
        primary: {
          main: '#d80621'
        },
        secondary:{
            main:"#7f7f7f"
        },
        error:{
            main:"#be0d0d"
        },
        warning:{
            main:"rgba(212, 141, 59, 1)"
        },



      },
      breakpoints :{
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
          xxl: 1400,
        },
      },
    typography:{
        //Montserrat_Bold_5xl
        h1:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"48px",
            lineHeight:"48px"
        },
        //Montserrat_SemiBold_base
        h2:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"16px",
            lineHeight:"24px"  
        },
        h3:{},
        h4:{},
        h5:{},
        h6:{},
        breakpoints :{
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
          xxl: 1400,
        },
      },
        ///botton
        button:{

        },
        Montserrat_Bold_xl:{
        fontFamily:"Montserrat",
        weight:700,
        fontSize:"20px",
        lineHeight:"30px"
        
        },
        Montserrat_Bold_2xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"24px",
            lineHeight:"60px"
            
        },
        Montserrat_Bold_3xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"30px",
            lineHeight:"37.5px"
            
        },
        Montserrat_Bold_4xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"36px",
            lineHeight:"45px"
        },
        Montserrat_Bold_5xl:{
            fontFamily:"Montserrat",
            weight:700,
            fontSize:"48px",
            lineHeight:"48px"
            
        },
        Montserrat_Regular_sm:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"14px",
            lineHeight:"17.5px"
        },
        Montserrat_Regular_base:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"16px",
            lineHeight:"24px" 
        },
        Montserrat_Regular_2xl:{
            fontFamily:"Montserrat",
            weight:400,
            fontSize:"24px",
            lineHeight:"36px" 
        },
        Montserrat_SemiBold_base:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"16px",
            lineHeight:"24px"  
        },
        Montserrat_SemiBold_sm:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"14px",
            lineHeight:"17.5px"  
        },
            Montserrat_SemiBold_lg:{
            fontFamily:"Montserrat",
            weight:600,
            fontSize:"18px",
            lineHeight:"27px"  
        }
    }

})