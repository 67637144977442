import React ,{useEffect, useRef, useState} from 'react';
import { Box, Grid } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import img from "../assets/images/product (1).webp"
//api request
import { Helper } from '../tools/helper';
import apiRoutes from '../apiRoutes';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { CircularProgress, IconButton, Skeleton ,Tooltip } from '@mui/material';
import lottie from 'lottie-web';


const Card = styled.div`
font-family : "Montserrat";
position:relative;
& h3{
    color:grey;
    font-size:14px;
    margin-top:5px;
    font-weight:unset !important;
    line-height:unset !important;
}
& span{
    color:black;
    font-size:12px;
    font-weight:700
}
& p{
    color:${props=>props.theme.colors.main.default};
    font-size:12px;
    margin-top:5px;
    font-weight:600;
    line-height:unset !important;
}
& a{
    font-weight:600;
}
& a:hover{
    color:${props=>props.theme.colors.main.default}
}

& img{
    border-radius:4px;
    // width: 100%;
    height: 100%;
    object-fit: contain;
    transition:0.5s;
}
&: hover{
    cursor:pointer;
    img{
       
        transform:scale(1.1);
    }
    h3{
        color:#e3a404;
    }
    // h5{
    //     color:#e3a404;
    // }
}
`

const LinkVendor = styled.a`
    color: ${props=>props.theme.colors.main.default};
    transition:0.5s;
    & :hover{
        color:silver 
    }
`
function Simple_Card_Product({fromWishlistPage,returnedRemove,wishlist,sellprice,photo,vendor,name,id,quantity_Selected=null,key,vendor_id}) {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading,setIsLoading] = useState(false)
    const [wishlistIcon,setWishlistIcon] = useState("")
    const heart_ref = useRef(null)
    useEffect(()=>{
        if(wishlist=="0"){
            setWishlistIcon(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.4495 4.19355C3.5925 2.93805 5.2245 2.24805 7.14 2.24805C8.886 2.24805 10.7505 3.07305 12 4.68705C13.242 3.07455 15.099 2.24805 16.86 2.24805C18.771 2.24805 20.4 2.93505 21.546 4.18755C22.68 5.42955 23.25 7.13055 23.25 9.01755C23.25 12.2665 21.5805 14.881 19.467 16.9135C17.3595 18.9415 14.712 20.488 12.5175 21.6235C12.3568 21.7067 12.1783 21.7498 11.9974 21.7493C11.8164 21.7488 11.6383 21.7046 11.478 21.6205C9.2835 20.473 6.636 18.9385 4.5285 16.921C2.415 14.8945 0.75 12.289 0.75 9.01905C0.75 7.13655 1.317 5.43555 2.4495 4.19355ZM4.113 5.70855C3.4305 6.45855 3 7.57905 3 9.01905C3 11.455 4.224 13.513 6.0855 15.295C7.8045 16.942 9.9855 18.2725 12.003 19.3525C14.0055 18.2875 16.188 16.9465 17.9085 15.292C19.773 13.4965 21 11.4295 21 9.01905C21 7.57455 20.568 6.45405 19.884 5.70405C19.212 4.97055 18.21 4.49955 16.86 4.49955C15.396 4.49955 13.7205 5.44155 13.0695 7.42455C12.9959 7.65017 12.8528 7.84674 12.6607 7.98612C12.4686 8.1255 12.2373 8.20056 12 8.20056C11.7627 8.20056 11.5314 8.1255 11.3393 7.98612C11.1472 7.84674 11.0041 7.65017 10.9305 7.42455C10.281 5.44455 8.5875 4.49955 7.14 4.49955C5.7855 4.49955 4.785 4.97055 4.113 5.70855Z" fill="#D80621"/>
            </svg>)
        }else if(wishlist=="1"){
            setWishlistIcon(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.6875 3C14.7516 3 13.0566 3.8325 12 5.23969C10.9434 3.8325 9.24844 3 7.3125 3C5.77146 3.00174 4.29404 3.61468 3.20436 4.70436C2.11468 5.79404 1.50174 7.27146 1.5 8.8125C1.5 15.375 11.2303 20.6869 11.6447 20.9062C11.7539 20.965 11.876 20.9958 12 20.9958C12.124 20.9958 12.2461 20.965 12.3553 20.9062C12.7697 20.6869 22.5 15.375 22.5 8.8125C22.4983 7.27146 21.8853 5.79404 20.7956 4.70436C19.706 3.61468 18.2285 3.00174 16.6875 3ZM12 19.3875C10.2881 18.39 3 13.8459 3 8.8125C3.00149 7.66921 3.45632 6.57317 4.26475 5.76475C5.07317 4.95632 6.16921 4.50149 7.3125 4.5C9.13594 4.5 10.6669 5.47125 11.3062 7.03125C11.3628 7.16881 11.4589 7.28646 11.5824 7.36926C11.7059 7.45207 11.8513 7.49627 12 7.49627C12.1487 7.49627 12.2941 7.45207 12.4176 7.36926C12.5411 7.28646 12.6372 7.16881 12.6937 7.03125C13.3331 5.46844 14.8641 4.5 16.6875 4.5C17.8308 4.50149 18.9268 4.95632 19.7353 5.76475C20.5437 6.57317 20.9985 7.66921 21 8.8125C21 13.8384 13.71 18.3891 12 19.3875Z" fill="#D80621"/>
            <path d="M16.4643 4C14.6205 4 13.0063 4.74017 12 5.9913C10.9938 4.74017 9.37946 4 7.53571 4C6.06806 4.00154 4.66099 4.54651 3.6232 5.51534C2.58541 6.48418 2.00165 7.79775 2 9.16789C2 15.0026 11.267 19.7254 11.6616 19.9204C11.7656 19.9727 11.8819 20 12 20C12.1181 20 12.2344 19.9727 12.3384 19.9204C12.733 19.7254 22 15.0026 22 9.16789C21.9983 7.79775 21.4146 6.48418 20.3768 5.51534C19.339 4.54651 17.9319 4.00154 16.4643 4ZM12 18.5701C10.3696 17.6832 3.42857 13.6431 3.42857 9.16789C3.42999 8.15139 3.86316 7.1769 4.63309 6.45813C5.40302 5.73936 6.44687 5.33497 7.53571 5.33365C9.27232 5.33365 10.7304 6.19718 11.3393 7.58418C11.3931 7.70648 11.4846 7.81109 11.6023 7.88471C11.7199 7.95832 11.8584 7.99763 12 7.99763C12.1416 7.99763 12.2801 7.95832 12.3977 7.88471C12.5154 7.81109 12.6069 7.70648 12.6607 7.58418C13.2696 6.19468 14.7277 5.33365 16.4643 5.33365C17.5531 5.33497 18.597 5.73936 19.3669 6.45813C20.1368 7.1769 20.57 8.15139 20.5714 9.16789C20.5714 13.6364 13.6286 17.6824 12 18.5701Z" fill="#D80621"/>
            <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
            <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
            <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
            <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
            <path d="M15.2946 5C13.7275 5 12.3553 5.60139 11.5 6.61793C10.6447 5.60139 9.27254 5 7.70536 5C6.45785 5.00125 5.26184 5.44404 4.37972 6.23122C3.4976 7.01839 3.00141 8.08567 3 9.19891C3 13.9396 10.8769 17.7769 11.2124 17.9353C11.3008 17.9778 11.3996 18 11.5 18C11.6004 18 11.6992 17.9778 11.7876 17.9353C12.1231 17.7769 20 13.9396 20 9.19891C19.9986 8.08567 19.5024 7.01839 18.6203 6.23122C17.7382 5.44404 16.5421 5.00125 15.2946 5ZM11.5 16.8382C10.1142 16.1176 4.21429 12.835 4.21429 9.19891C4.21549 8.373 4.58369 7.58123 5.23813 6.99723C5.89257 6.41323 6.77984 6.08466 7.70536 6.08359C9.18147 6.08359 10.4208 6.78521 10.9384 7.91214C10.9841 8.01152 11.0619 8.09651 11.1619 8.15632C11.2619 8.21614 11.3796 8.24808 11.5 8.24808C11.6204 8.24808 11.7381 8.21614 11.8381 8.15632C11.9381 8.09651 12.0159 8.01152 12.0616 7.91214C12.5792 6.78318 13.8185 6.08359 15.2946 6.08359C16.2202 6.08466 17.1074 6.41323 17.7619 6.99723C18.4163 7.58123 18.7845 8.373 18.7857 9.19891C18.7857 12.8296 12.8843 16.1169 11.5 16.8382Z" fill="#D80621"/>
            <path d="M14.8482 5C13.4654 5 12.2547 5.55513 11.5 6.49348C10.7453 5.55513 9.5346 5 8.15179 5C7.05104 5.00116 5.99574 5.40988 5.2174 6.13651C4.43906 6.86313 4.00124 7.84831 4 8.87591C4 13.2519 10.9502 16.794 11.2462 16.9403C11.3242 16.9795 11.4114 17 11.5 17C11.5886 17 11.6758 16.9795 11.7538 16.9403C12.0498 16.794 19 13.2519 19 8.87591C18.9988 7.84831 18.5609 6.86313 17.7826 6.13651C17.0043 5.40988 15.949 5.00116 14.8482 5ZM11.5 15.9276C10.2772 15.2624 5.07143 12.2323 5.07143 8.87591C5.07249 8.11354 5.39737 7.38268 5.97482 6.8436C6.55227 6.30452 7.33515 6.00123 8.15179 6.00024C9.45424 6.00024 10.5478 6.64789 11.0045 7.68813C11.0448 7.77986 11.1135 7.85832 11.2017 7.91353C11.29 7.96874 11.3938 7.99822 11.5 7.99822C11.6062 7.99822 11.71 7.96874 11.7983 7.91353C11.8865 7.85832 11.9552 7.77986 11.9955 7.68813C12.4522 6.64601 13.5458 6.00024 14.8482 6.00024C15.6648 6.00123 16.4477 6.30452 17.0252 6.8436C17.6026 7.38268 17.9275 8.11354 17.9286 8.87591C17.9286 12.2273 12.7214 15.2618 11.5 15.9276Z" fill="#D80621"/>
            <path d="M14.8482 6C13.4654 6 12.2547 6.46261 11.5 7.24456C10.7453 6.46261 9.5346 6 8.15179 6C7.05104 6.00097 5.99574 6.34157 5.2174 6.94709C4.43906 7.55261 4.00124 8.37359 4 9.22993C4 12.8766 10.9502 15.8284 11.2462 15.9503C11.3242 15.9829 11.4114 16 11.5 16C11.5886 16 11.6758 15.9829 11.7538 15.9503C12.0498 15.8284 19 12.8766 19 9.22993C18.9988 8.37359 18.5609 7.55261 17.7826 6.94709C17.0043 6.34157 15.949 6.00097 14.8482 6ZM11.5 15.1063C10.2772 14.552 5.07143 12.0269 5.07143 9.22993C5.07249 8.59462 5.39737 7.98556 5.97482 7.53633C6.55227 7.0871 7.33515 6.83436 8.15179 6.83353C9.45424 6.83353 10.5478 7.37324 11.0045 8.24011C11.0448 8.31655 11.1135 8.38193 11.2017 8.42794C11.29 8.47395 11.3938 8.49852 11.5 8.49852C11.6062 8.49852 11.71 8.47395 11.7983 8.42794C11.8865 8.38193 11.9552 8.31655 11.9955 8.24011C12.4522 7.37168 13.5458 6.83353 14.8482 6.83353C15.6648 6.83436 16.4477 7.0871 17.0252 7.53633C17.6026 7.98556 17.9275 8.59462 17.9286 9.22993C17.9286 12.0228 12.7214 14.5515 11.5 15.1063Z" fill="#D80621"/>
            <path d="M14.8482 6C13.4654 6 12.2547 6.46261 11.5 7.24456C10.7453 6.46261 9.5346 6 8.15179 6C7.05104 6.00097 5.99574 6.34157 5.2174 6.94709C4.43906 7.55261 4.00124 8.37359 4 9.22993C4 12.8766 10.9502 15.8284 11.2462 15.9503C11.3242 15.9829 11.4114 16 11.5 16C11.5886 16 11.6758 15.9829 11.7538 15.9503C12.0498 15.8284 19 12.8766 19 9.22993C18.9988 8.37359 18.5609 7.55261 17.7826 6.94709C17.0043 6.34157 15.949 6.00097 14.8482 6ZM11.5 15.1063C10.2772 14.552 5.07143 12.0269 5.07143 9.22993C5.07249 8.59462 5.39737 7.98556 5.97482 7.53633C6.55227 7.0871 7.33515 6.83436 8.15179 6.83353C9.45424 6.83353 10.5478 7.37324 11.0045 8.24011C11.0448 8.31655 11.1135 8.38193 11.2017 8.42794C11.29 8.47395 11.3938 8.49852 11.5 8.49852C11.6062 8.49852 11.71 8.47395 11.7983 8.42794C11.8865 8.38193 11.9552 8.31655 11.9955 8.24011C12.4522 7.37168 13.5458 6.83353 14.8482 6.83353C15.6648 6.83436 16.4477 7.0871 17.0252 7.53633C17.6026 7.98556 17.9275 8.59462 17.9286 9.22993C17.9286 12.0228 12.7214 14.5515 11.5 15.1063Z" fill="#D80621"/>
            <path d="M14.8482 5C13.4654 5 12.2547 5.50887 11.5 6.36902C10.7453 5.50887 9.5346 5 8.15179 5C7.05104 5.00106 5.99574 5.37573 5.2174 6.0418C4.43906 6.70787 4.00124 7.61095 4 8.55292C4 12.5643 10.9502 15.8112 11.2462 15.9453C11.3242 15.9812 11.4114 16 11.5 16C11.5886 16 11.6758 15.9812 11.7538 15.9453C12.0498 15.8112 19 12.5643 19 8.55292C18.9988 7.61095 18.5609 6.70787 17.7826 6.0418C17.0043 5.37573 15.949 5.00106 14.8482 5ZM11.5 15.0169C10.2772 14.4072 5.07143 11.6296 5.07143 8.55292C5.07249 7.85408 5.39737 7.18412 5.97482 6.68997C6.55227 6.19581 7.33515 5.91779 8.15179 5.91688C9.45424 5.91688 10.5478 6.51056 11.0045 7.46412C11.0448 7.54821 11.1135 7.62012 11.2017 7.67074C11.29 7.72135 11.3938 7.74837 11.5 7.74837C11.6062 7.74837 11.71 7.72135 11.7983 7.67074C11.8865 7.62012 11.9552 7.54821 11.9955 7.46412C12.4522 6.50885 13.5458 5.91688 14.8482 5.91688C15.6648 5.91779 16.4477 6.19581 17.0252 6.68997C17.6026 7.18412 17.9275 7.85408 17.9286 8.55292C17.9286 11.6251 12.7214 14.4066 11.5 15.0169Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.46261 11.5 7.24456C10.8459 6.46261 9.79665 6 8.59821 6C7.64424 6.00097 6.72964 6.34157 6.05508 6.94709C5.38052 7.55261 5.00108 8.37359 5 9.22993C5 12.8766 11.0235 15.8284 11.28 15.9503C11.3477 15.9829 11.4232 16 11.5 16C11.5768 16 11.6523 15.9829 11.72 15.9503C11.9765 15.8284 18 12.8766 18 9.22993C17.9989 8.37359 17.6195 7.55261 16.9449 6.94709C16.2704 6.34157 15.3558 6.00097 14.4018 6ZM11.5 15.1063C10.4403 14.552 5.92857 12.0269 5.92857 9.22993C5.92949 8.59462 6.21105 7.98556 6.71151 7.53633C7.21196 7.0871 7.89046 6.83436 8.59821 6.83353C9.72701 6.83353 10.6747 7.37324 11.0705 8.24011C11.1055 8.31655 11.165 8.38193 11.2415 8.42794C11.318 8.47395 11.4079 8.49852 11.5 8.49852C11.5921 8.49852 11.682 8.47395 11.7585 8.42794C11.835 8.38193 11.8945 8.31655 11.9295 8.24011C12.3253 7.37168 13.273 6.83353 14.4018 6.83353C15.1095 6.83436 15.788 7.0871 16.2885 7.53633C16.7889 7.98556 17.0705 8.59462 17.0714 9.22993C17.0714 12.0228 12.5586 14.5515 11.5 15.1063Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.46261 11.5 7.24456C10.8459 6.46261 9.79665 6 8.59821 6C7.64424 6.00097 6.72964 6.34157 6.05508 6.94709C5.38052 7.55261 5.00108 8.37359 5 9.22993C5 12.8766 11.0235 15.8284 11.28 15.9503C11.3477 15.9829 11.4232 16 11.5 16C11.5768 16 11.6523 15.9829 11.72 15.9503C11.9765 15.8284 18 12.8766 18 9.22993C17.9989 8.37359 17.6195 7.55261 16.9449 6.94709C16.2704 6.34157 15.3558 6.00097 14.4018 6ZM11.5 15.1063C10.4403 14.552 5.92857 12.0269 5.92857 9.22993C5.92949 8.59462 6.21105 7.98556 6.71151 7.53633C7.21196 7.0871 7.89046 6.83436 8.59821 6.83353C9.72701 6.83353 10.6747 7.37324 11.0705 8.24011C11.1055 8.31655 11.165 8.38193 11.2415 8.42794C11.318 8.47395 11.4079 8.49852 11.5 8.49852C11.5921 8.49852 11.682 8.47395 11.7585 8.42794C11.835 8.38193 11.8945 8.31655 11.9295 8.24011C12.3253 7.37168 13.273 6.83353 14.4018 6.83353C15.1095 6.83436 15.788 7.0871 16.2885 7.53633C16.7889 7.98556 17.0705 8.59462 17.0714 9.22993C17.0714 12.0228 12.5586 14.5515 11.5 15.1063Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.43058 11.5 7.1584C10.8459 6.43058 9.79665 6 8.59821 6C7.64424 6.0009 6.72964 6.31792 6.05508 6.88152C5.38052 7.44512 5.00108 8.20927 5 9.00632C5 12.4005 11.0235 15.1479 11.28 15.2614C11.3477 15.2918 11.4232 15.3077 11.5 15.3077C11.5768 15.3077 11.6523 15.2918 11.72 15.2614C11.9765 15.1479 18 12.4005 18 9.00632C17.9989 8.20927 17.6195 7.44512 16.9449 6.88152C16.2704 6.31792 15.3558 6.0009 14.4018 6ZM11.5 14.4759C10.4403 13.96 5.92857 11.6097 5.92857 9.00632C5.92949 8.41499 6.21105 7.8481 6.71151 7.42997C7.21196 7.01184 7.89046 6.77659 8.59821 6.77582C9.72701 6.77582 10.6747 7.27817 11.0705 8.08503C11.1055 8.15617 11.165 8.21703 11.2415 8.25985C11.318 8.30268 11.4079 8.32555 11.5 8.32555C11.5921 8.32555 11.682 8.30268 11.7585 8.25985C11.835 8.21703 11.8945 8.15617 11.9295 8.08503C12.3253 7.27672 13.273 6.77582 14.4018 6.77582C15.1095 6.77659 15.788 7.01184 16.2885 7.42997C16.7889 7.8481 17.0705 8.41499 17.0714 9.00632C17.0714 11.6058 12.5586 13.9595 11.5 14.4759Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.43058 11.5 7.1584C10.8459 6.43058 9.79665 6 8.59821 6C7.64424 6.0009 6.72964 6.31792 6.05508 6.88152C5.38052 7.44512 5.00108 8.20927 5 9.00632C5 12.4005 11.0235 15.1479 11.28 15.2614C11.3477 15.2918 11.4232 15.3077 11.5 15.3077C11.5768 15.3077 11.6523 15.2918 11.72 15.2614C11.9765 15.1479 18 12.4005 18 9.00632C17.9989 8.20927 17.6195 7.44512 16.9449 6.88152C16.2704 6.31792 15.3558 6.0009 14.4018 6ZM11.5 14.4759C10.4403 13.96 5.92857 11.6097 5.92857 9.00632C5.92949 8.41499 6.21105 7.8481 6.71151 7.42997C7.21196 7.01184 7.89046 6.77659 8.59821 6.77582C9.72701 6.77582 10.6747 7.27817 11.0705 8.08503C11.1055 8.15617 11.165 8.21703 11.2415 8.25985C11.318 8.30268 11.4079 8.32555 11.5 8.32555C11.5921 8.32555 11.682 8.30268 11.7585 8.25985C11.835 8.21703 11.8945 8.15617 11.9295 8.08503C12.3253 7.27672 13.273 6.77582 14.4018 6.77582C15.1095 6.77659 15.788 7.01184 16.2885 7.42997C16.7889 7.8481 17.0705 8.41499 17.0714 9.00632C17.0714 11.6058 12.5586 13.9595 11.5 14.4759Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.50887 11.5 7.36902C10.8459 6.50887 9.79665 6 8.59821 6C7.64424 6.00106 6.72964 6.37573 6.05508 7.0418C5.38052 7.70787 5.00108 8.61095 5 9.55292C5 13.5643 11.0235 16.8112 11.28 16.9453C11.3477 16.9812 11.4232 17 11.5 17C11.5768 17 11.6523 16.9812 11.72 16.9453C11.9765 16.8112 18 13.5643 18 9.55292C17.9989 8.61095 17.6195 7.70787 16.9449 7.0418C16.2704 6.37573 15.3558 6.00106 14.4018 6ZM11.5 16.0169C10.4403 15.4072 5.92857 12.6296 5.92857 9.55292C5.92949 8.85408 6.21105 8.18412 6.71151 7.68997C7.21196 7.19581 7.89046 6.91779 8.59821 6.91688C9.72701 6.91688 10.6747 7.51056 11.0705 8.46412C11.1055 8.54821 11.165 8.62012 11.2415 8.67074C11.318 8.72135 11.4079 8.74837 11.5 8.74837C11.5921 8.74837 11.682 8.72135 11.7585 8.67074C11.835 8.62012 11.8945 8.54821 11.9295 8.46412C12.3253 7.50885 13.273 6.91688 14.4018 6.91688C15.1095 6.91779 15.788 7.19581 16.2885 7.68997C16.7889 8.18412 17.0705 8.85408 17.0714 9.55292C17.0714 12.6251 12.5586 15.4066 11.5 16.0169Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.50887 11.5 7.36902C10.8459 6.50887 9.79665 6 8.59821 6C7.64424 6.00106 6.72964 6.37573 6.05508 7.0418C5.38052 7.70787 5.00108 8.61095 5 9.55292C5 13.5643 11.0235 16.8112 11.28 16.9453C11.3477 16.9812 11.4232 17 11.5 17C11.5768 17 11.6523 16.9812 11.72 16.9453C11.9765 16.8112 18 13.5643 18 9.55292C17.9989 8.61095 17.6195 7.70787 16.9449 7.0418C16.2704 6.37573 15.3558 6.00106 14.4018 6ZM11.5 16.0169C10.4403 15.4072 5.92857 12.6296 5.92857 9.55292C5.92949 8.85408 6.21105 8.18412 6.71151 7.68997C7.21196 7.19581 7.89046 6.91779 8.59821 6.91688C9.72701 6.91688 10.6747 7.51056 11.0705 8.46412C11.1055 8.54821 11.165 8.62012 11.2415 8.67074C11.318 8.72135 11.4079 8.74837 11.5 8.74837C11.5921 8.74837 11.682 8.72135 11.7585 8.67074C11.835 8.62012 11.8945 8.54821 11.9295 8.46412C12.3253 7.50885 13.273 6.91688 14.4018 6.91688C15.1095 6.91779 15.788 7.19581 16.2885 7.68997C16.7889 8.18412 17.0705 8.85408 17.0714 9.55292C17.0714 12.6251 12.5586 15.4066 11.5 16.0169Z" fill="#D80621"/>
            <path d="M14.4018 6C13.2033 6 12.1541 6.55513 11.5 7.49348C10.8459 6.55513 9.79665 6 8.59821 6C7.64424 6.00116 6.72964 6.40988 6.05508 7.13651C5.38052 7.86313 5.00108 8.84831 5 9.87591C5 14.2519 11.0235 17.794 11.28 17.9403C11.3477 17.9795 11.4232 18 11.5 18C11.5768 18 11.6523 17.9795 11.72 17.9403C11.9765 17.794 18 14.2519 18 9.87591C17.9989 8.84831 17.6195 7.86313 16.9449 7.13651C16.2704 6.40988 15.3558 6.00116 14.4018 6ZM11.5 16.9276C10.4403 16.2624 5.92857 13.2323 5.92857 9.87591C5.92949 9.11354 6.21105 8.38268 6.71151 7.8436C7.21196 7.30452 7.89046 7.00123 8.59821 7.00024C9.72701 7.00024 10.6747 7.64789 11.0705 8.68813C11.1055 8.77986 11.165 8.85832 11.2415 8.91353C11.318 8.96874 11.4079 8.99822 11.5 8.99822C11.5921 8.99822 11.682 8.96874 11.7585 8.91353C11.835 8.85832 11.8945 8.77986 11.9295 8.68813C12.3253 7.64601 13.273 7.00024 14.4018 7.00024C15.1095 7.00123 15.788 7.30452 16.2885 7.8436C16.7889 8.38268 17.0705 9.11354 17.0714 9.87591C17.0714 13.2273 12.5586 16.2618 11.5 16.9276Z" fill="#D80621"/>
            <path d="M14.4018 7C13.2033 7 12.1541 7.55513 11.5 8.49348C10.8459 7.55513 9.79665 7 8.59821 7C7.64424 7.00116 6.72964 7.40988 6.05508 8.13651C5.38052 8.86313 5.00108 9.84831 5 10.8759C5 15.2519 11.0235 18.794 11.28 18.9403C11.3477 18.9795 11.4232 19 11.5 19C11.5768 19 11.6523 18.9795 11.72 18.9403C11.9765 18.794 18 15.2519 18 10.8759C17.9989 9.84831 17.6195 8.86313 16.9449 8.13651C16.2704 7.40988 15.3558 7.00116 14.4018 7ZM11.5 17.9276C10.4403 17.2624 5.92857 14.2323 5.92857 10.8759C5.92949 10.1135 6.21105 9.38268 6.71151 8.8436C7.21196 8.30452 7.89046 8.00123 8.59821 8.00024C9.72701 8.00024 10.6747 8.64789 11.0705 9.68813C11.1055 9.77986 11.165 9.85832 11.2415 9.91353C11.318 9.96874 11.4079 9.99822 11.5 9.99822C11.5921 9.99822 11.682 9.96874 11.7585 9.91353C11.835 9.85832 11.8945 9.77986 11.9295 9.68813C12.3253 8.64601 13.273 8.00024 14.4018 8.00024C15.1095 8.00123 15.788 8.30452 16.2885 8.8436C16.7889 9.38268 17.0705 10.1135 17.0714 10.8759C17.0714 14.2273 12.5586 17.2618 11.5 17.9276Z" fill="#D80621"/>
            <path d="M14.4018 8C13.2033 8 12.1541 8.55513 11.5 9.49348C10.8459 8.55513 9.79665 8 8.59821 8C7.64424 8.00116 6.72964 8.40988 6.05508 9.13651C5.38052 9.86313 5.00108 10.8483 5 11.8759C5 16.2519 11.0235 19.794 11.28 19.9403C11.3477 19.9795 11.4232 20 11.5 20C11.5768 20 11.6523 19.9795 11.72 19.9403C11.9765 19.794 18 16.2519 18 11.8759C17.9989 10.8483 17.6195 9.86313 16.9449 9.13651C16.2704 8.40988 15.3558 8.00116 14.4018 8ZM11.5 18.9276C10.4403 18.2624 5.92857 15.2323 5.92857 11.8759C5.92949 11.1135 6.21105 10.3827 6.71151 9.8436C7.21196 9.30452 7.89046 9.00123 8.59821 9.00024C9.72701 9.00024 10.6747 9.64789 11.0705 10.6881C11.1055 10.7799 11.165 10.8583 11.2415 10.9135C11.318 10.9687 11.4079 10.9982 11.5 10.9982C11.5921 10.9982 11.682 10.9687 11.7585 10.9135C11.835 10.8583 11.8945 10.7799 11.9295 10.6881C12.3253 9.64601 13.273 9.00024 14.4018 9.00024C15.1095 9.00123 15.788 9.30452 16.2885 9.8436C16.7889 10.3827 17.0705 11.1135 17.0714 11.8759C17.0714 15.2273 12.5586 18.2618 11.5 18.9276Z" fill="#D80621"/>
            <path d="M14.4018 9C13.2033 9 12.1541 9.32383 11.5 9.8712C10.8459 9.32383 9.79665 9 8.59821 9C7.64424 9.00068 6.72964 9.2391 6.05508 9.66296C5.38052 10.0868 5.00108 10.6615 5 11.2609C5 13.8136 11.0235 15.8799 11.28 15.9652C11.3477 15.988 11.4232 16 11.5 16C11.5768 16 11.6523 15.988 11.72 15.9652C11.9765 15.8799 18 13.8136 18 11.2609C17.9989 10.6615 17.6195 10.0868 16.9449 9.66296C16.2704 9.2391 15.3558 9.00068 14.4018 9ZM11.5 15.3744C10.4403 14.9864 5.92857 13.2189 5.92857 11.2609C5.92949 10.8162 6.21105 10.3899 6.71151 10.0754C7.21196 9.76097 7.89046 9.58405 8.59821 9.58347C9.72701 9.58347 10.6747 9.96127 11.0705 10.5681C11.1055 10.6216 11.165 10.6674 11.2415 10.6996C11.318 10.7318 11.4079 10.749 11.5 10.749C11.5921 10.749 11.682 10.7318 11.7585 10.6996C11.835 10.6674 11.8945 10.6216 11.9295 10.5681C12.3253 9.96017 13.273 9.58347 14.4018 9.58347C15.1095 9.58405 15.788 9.76097 16.2885 10.0754C16.7889 10.3899 17.0705 10.8162 17.0714 11.2609C17.0714 13.2159 12.5586 14.986 11.5 15.3744Z" fill="#D80621"/>
            <path d="M14.4018 10C13.2033 10 12.1541 10.3701 11.5 10.9957C10.8459 10.3701 9.79665 10 8.59821 10C7.64424 10.0008 6.72964 10.2733 6.05508 10.7577C5.38052 11.2421 5.00108 11.8989 5 12.5839C5 15.5013 11.0235 17.8627 11.28 17.9602C11.3477 17.9863 11.4232 18 11.5 18C11.5768 18 11.6523 17.9863 11.72 17.9602C11.9765 17.8627 18 15.5013 18 12.5839C17.9989 11.8989 17.6195 11.2421 16.9449 10.7577C16.2704 10.2733 15.3558 10.0008 14.4018 10ZM11.5 17.2851C10.4403 16.8416 5.92857 14.8216 5.92857 12.5839C5.92949 12.0757 6.21105 11.5885 6.71151 11.2291C7.21196 10.8697 7.89046 10.6675 8.59821 10.6668C9.72701 10.6668 10.6747 11.0986 11.0705 11.7921C11.1055 11.8532 11.165 11.9055 11.2415 11.9424C11.318 11.9792 11.4079 11.9988 11.5 11.9988C11.5921 11.9988 11.682 11.9792 11.7585 11.9424C11.835 11.9055 11.8945 11.8532 11.9295 11.7921C12.3253 11.0973 13.273 10.6668 14.4018 10.6668C15.1095 10.6675 15.788 10.8697 16.2885 11.2291C16.7889 11.5885 17.0705 12.0757 17.0714 12.5839C17.0714 14.8182 12.5586 16.8412 11.5 17.2851Z" fill="#D80621"/>
            <path d="M17.4996 8.00023C16.3013 8.00023 12.8736 6.87641 12.2197 7.50197C11.5657 6.87641 10.2963 8.00214 9.09797 8.00214C8.14411 8.00291 6.17418 8.51949 5.4997 9.00391C4.82522 9.48832 5.50088 12.901 5.4998 13.5861C5.4998 16.5034 11.5233 18.8648 11.7798 18.9624C11.8474 18.9885 11.9229 19.0021 11.9997 19.0021C12.0765 19.0021 12.1521 18.9885 12.2197 18.9624C12.4762 18.8648 18.4996 16.5034 18.4996 13.5861C18.4985 12.901 18.119 12.2442 17.4446 11.7598C16.7701 11.2754 18.4535 8.001 17.4996 8.00023ZM11.9997 18.2872C10.9401 17.8438 6.42836 15.8237 6.42836 13.5861C6.42928 13.0778 6.7109 12.5906 7.21129 12.2312C7.71168 11.8718 13.2919 16.0046 13.9996 16.0039C15.1282 16.0039 11.1745 12.1007 11.5703 12.7942C11.6052 12.8554 11.6647 12.9077 11.7412 12.9445C11.8177 12.9813 12.1276 15.2167 12.2197 15.2167C12.3117 15.2167 13.4235 13.5368 13.5 13.5C13.5765 13.4632 12.3942 12.8554 12.4292 12.7942C12.8249 12.0995 10.371 15.5039 11.4996 15.5039C12.2073 15.5046 16.2877 11.8718 16.7881 12.2312C17.2885 12.5906 17.5701 13.0778 17.5711 13.5861C17.5711 15.8204 13.0582 17.8433 11.9997 18.2872Z" fill="#D80621"/>
            <path d="M14.4018 12C13.2033 12 12.1541 12.1388 11.5 12.3734C10.8459 12.1388 9.79665 12 8.59821 12C7.64424 12.0003 6.72964 12.1025 6.05508 12.2841C5.38052 12.4658 5.00108 12.7121 5 12.969C5 14.063 11.0235 14.9485 11.28 14.9851C11.3477 14.9949 11.4232 15 11.5 15C11.5768 15 11.6523 14.9949 11.72 14.9851C11.9765 14.9485 18 14.063 18 12.969C17.9989 12.7121 17.6195 12.4658 16.9449 12.2841C16.2704 12.1025 15.3558 12.0003 14.4018 12ZM11.5 14.7319C10.4403 14.5656 5.92857 13.8081 5.92857 12.969C5.92949 12.7784 6.21105 12.5957 6.71151 12.4609C7.21196 12.3261 7.89046 12.2503 8.59821 12.2501C9.72701 12.2501 10.6747 12.412 11.0705 12.672C11.1055 12.695 11.165 12.7146 11.2415 12.7284C11.318 12.7422 11.4079 12.7496 11.5 12.7496C11.5921 12.7496 11.682 12.7422 11.7585 12.7284C11.835 12.7146 11.8945 12.695 11.9295 12.672C12.3253 12.4115 13.273 12.2501 14.4018 12.2501C15.1095 12.2503 15.788 12.3261 16.2885 12.4609C16.7889 12.5957 17.0705 12.7784 17.0714 12.969C17.0714 13.8068 12.5586 14.5654 11.5 14.7319Z" fill="#D80621"/>
            <path d="M14.4018 13C13.2033 13 12.1541 13.185 11.5 13.4978C10.8459 13.185 9.79665 13 8.59821 13C7.64424 13.0004 6.72964 13.1366 6.05508 13.3788C5.38052 13.621 5.00108 13.9494 5 14.292C5 15.7506 11.0235 16.9313 11.28 16.9801C11.3477 16.9932 11.4232 17 11.5 17C11.5768 17 11.6523 16.9932 11.72 16.9801C11.9765 16.9313 18 15.7506 18 14.292C17.9989 13.9494 17.6195 13.621 16.9449 13.3788C16.2704 13.1366 15.3558 13.0004 14.4018 13ZM11.5 16.6425C10.4403 16.4208 5.92857 15.4108 5.92857 14.292C5.92949 14.0378 6.21105 13.7942 6.71151 13.6145C7.21196 13.4348 7.89046 13.3337 8.59821 13.3334C9.72701 13.3334 10.6747 13.5493 11.0705 13.896C11.1055 13.9266 11.165 13.9528 11.2415 13.9712C11.318 13.9896 11.4079 13.9994 11.5 13.9994C11.5921 13.9994 11.682 13.9896 11.7585 13.9712C11.835 13.9528 11.8945 13.9266 11.9295 13.896C12.3253 13.5487 13.273 13.3334 14.4018 13.3334C15.1095 13.3337 15.788 13.4348 16.2885 13.6145C16.7889 13.7942 17.0705 14.0378 17.0714 14.292C17.0714 15.4091 12.5586 16.4206 11.5 16.6425Z" fill="#D80621"/>
            </svg>)
            
        }else{
            setWishlistIcon("")
        }
    },[wishlist])

    const change_status_wishlist = async ()=>{

        setIsLoading(true)
        lottie.loadAnimation({
            animationData: require("../data/fade-heart-loading.json"),
            autoplay: true,
            container: heart_ref.current,
            loop: true,
            renderer: "svg",
          })
        lottie.play()
        if(fromWishlistPage){
            setTimeout(()=>{
                returnedRemove(true)
            },1000)
            
        }else{
            const {response,message} = await Helper.Post({
                url:apiRoutes.wishlist.add(id),
                hasToken:true,
            })
            if(response){
               //  enqueueSnackbar(`successfully add `, {variant:"success"} );
               lottie.destroy()
               setIsLoading(false)
               if(response.data=="Deleted"){
                setWishlistIcon(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.4495 4.19355C3.5925 2.93805 5.2245 2.24805 7.14 2.24805C8.886 2.24805 10.7505 3.07305 12 4.68705C13.242 3.07455 15.099 2.24805 16.86 2.24805C18.771 2.24805 20.4 2.93505 21.546 4.18755C22.68 5.42955 23.25 7.13055 23.25 9.01755C23.25 12.2665 21.5805 14.881 19.467 16.9135C17.3595 18.9415 14.712 20.488 12.5175 21.6235C12.3568 21.7067 12.1783 21.7498 11.9974 21.7493C11.8164 21.7488 11.6383 21.7046 11.478 21.6205C9.2835 20.473 6.636 18.9385 4.5285 16.921C2.415 14.8945 0.75 12.289 0.75 9.01905C0.75 7.13655 1.317 5.43555 2.4495 4.19355ZM4.113 5.70855C3.4305 6.45855 3 7.57905 3 9.01905C3 11.455 4.224 13.513 6.0855 15.295C7.8045 16.942 9.9855 18.2725 12.003 19.3525C14.0055 18.2875 16.188 16.9465 17.9085 15.292C19.773 13.4965 21 11.4295 21 9.01905C21 7.57455 20.568 6.45405 19.884 5.70405C19.212 4.97055 18.21 4.49955 16.86 4.49955C15.396 4.49955 13.7205 5.44155 13.0695 7.42455C12.9959 7.65017 12.8528 7.84674 12.6607 7.98612C12.4686 8.1255 12.2373 8.20056 12 8.20056C11.7627 8.20056 11.5314 8.1255 11.3393 7.98612C11.1472 7.84674 11.0041 7.65017 10.9305 7.42455C10.281 5.44455 8.5875 4.49955 7.14 4.49955C5.7855 4.49955 4.785 4.97055 4.113 5.70855Z" fill="#D80621"/>
                </svg>)
                    }else if(response.data == "Added"){
                        setWishlistIcon(<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.6875 3C14.7516 3 13.0566 3.8325 12 5.23969C10.9434 3.8325 9.24844 3 7.3125 3C5.77146 3.00174 4.29404 3.61468 3.20436 4.70436C2.11468 5.79404 1.50174 7.27146 1.5 8.8125C1.5 15.375 11.2303 20.6869 11.6447 20.9062C11.7539 20.965 11.876 20.9958 12 20.9958C12.124 20.9958 12.2461 20.965 12.3553 20.9062C12.7697 20.6869 22.5 15.375 22.5 8.8125C22.4983 7.27146 21.8853 5.79404 20.7956 4.70436C19.706 3.61468 18.2285 3.00174 16.6875 3ZM12 19.3875C10.2881 18.39 3 13.8459 3 8.8125C3.00149 7.66921 3.45632 6.57317 4.26475 5.76475C5.07317 4.95632 6.16921 4.50149 7.3125 4.5C9.13594 4.5 10.6669 5.47125 11.3062 7.03125C11.3628 7.16881 11.4589 7.28646 11.5824 7.36926C11.7059 7.45207 11.8513 7.49627 12 7.49627C12.1487 7.49627 12.2941 7.45207 12.4176 7.36926C12.5411 7.28646 12.6372 7.16881 12.6937 7.03125C13.3331 5.46844 14.8641 4.5 16.6875 4.5C17.8308 4.50149 18.9268 4.95632 19.7353 5.76475C20.5437 6.57317 20.9985 7.66921 21 8.8125C21 13.8384 13.71 18.3891 12 19.3875Z" fill="#D80621"/>
                        <path d="M16.4643 4C14.6205 4 13.0063 4.74017 12 5.9913C10.9938 4.74017 9.37946 4 7.53571 4C6.06806 4.00154 4.66099 4.54651 3.6232 5.51534C2.58541 6.48418 2.00165 7.79775 2 9.16789C2 15.0026 11.267 19.7254 11.6616 19.9204C11.7656 19.9727 11.8819 20 12 20C12.1181 20 12.2344 19.9727 12.3384 19.9204C12.733 19.7254 22 15.0026 22 9.16789C21.9983 7.79775 21.4146 6.48418 20.3768 5.51534C19.339 4.54651 17.9319 4.00154 16.4643 4ZM12 18.5701C10.3696 17.6832 3.42857 13.6431 3.42857 9.16789C3.42999 8.15139 3.86316 7.1769 4.63309 6.45813C5.40302 5.73936 6.44687 5.33497 7.53571 5.33365C9.27232 5.33365 10.7304 6.19718 11.3393 7.58418C11.3931 7.70648 11.4846 7.81109 11.6023 7.88471C11.7199 7.95832 11.8584 7.99763 12 7.99763C12.1416 7.99763 12.2801 7.95832 12.3977 7.88471C12.5154 7.81109 12.6069 7.70648 12.6607 7.58418C13.2696 6.19468 14.7277 5.33365 16.4643 5.33365C17.5531 5.33497 18.597 5.73936 19.3669 6.45813C20.1368 7.1769 20.57 8.15139 20.5714 9.16789C20.5714 13.6364 13.6286 17.6824 12 18.5701Z" fill="#D80621"/>
                        <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
                        <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
                        <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
                        <path d="M16.0179 5C14.3585 5 12.9056 5.64765 12 6.74239C11.0944 5.64765 9.64152 5 7.98214 5C6.66125 5.00135 5.39489 5.4782 4.46088 6.32593C3.52687 7.17365 3.00149 8.32303 3 9.5219C3 14.6273 11.3403 18.7597 11.6954 18.9304C11.7891 18.9761 11.8937 19 12 19C12.1063 19 12.2109 18.9761 12.3046 18.9304C12.6597 18.7597 21 14.6273 21 9.5219C20.9985 8.32303 20.4731 7.17365 19.5391 6.32593C18.6051 5.4782 17.3387 5.00135 16.0179 5ZM12 17.7488C10.5327 16.9728 4.28571 13.4377 4.28571 9.5219C4.28699 8.63247 4.67684 7.77979 5.36978 7.15087C6.06272 6.52194 7.00218 6.1681 7.98214 6.16694C9.54509 6.16694 10.8573 6.92254 11.4054 8.13616C11.4538 8.24317 11.5362 8.3347 11.6421 8.39912C11.7479 8.46353 11.8725 8.49793 12 8.49793C12.1275 8.49793 12.2521 8.46353 12.3579 8.39912C12.4638 8.3347 12.5462 8.24317 12.5946 8.13616C13.1427 6.92035 14.4549 6.16694 16.0179 6.16694C16.9978 6.1681 17.9373 6.52194 18.6302 7.15087C19.3232 7.77979 19.713 8.63247 19.7143 9.5219C19.7143 13.4319 13.4657 16.9721 12 17.7488Z" fill="#D80621"/>
                        <path d="M15.2946 5C13.7275 5 12.3553 5.60139 11.5 6.61793C10.6447 5.60139 9.27254 5 7.70536 5C6.45785 5.00125 5.26184 5.44404 4.37972 6.23122C3.4976 7.01839 3.00141 8.08567 3 9.19891C3 13.9396 10.8769 17.7769 11.2124 17.9353C11.3008 17.9778 11.3996 18 11.5 18C11.6004 18 11.6992 17.9778 11.7876 17.9353C12.1231 17.7769 20 13.9396 20 9.19891C19.9986 8.08567 19.5024 7.01839 18.6203 6.23122C17.7382 5.44404 16.5421 5.00125 15.2946 5ZM11.5 16.8382C10.1142 16.1176 4.21429 12.835 4.21429 9.19891C4.21549 8.373 4.58369 7.58123 5.23813 6.99723C5.89257 6.41323 6.77984 6.08466 7.70536 6.08359C9.18147 6.08359 10.4208 6.78521 10.9384 7.91214C10.9841 8.01152 11.0619 8.09651 11.1619 8.15632C11.2619 8.21614 11.3796 8.24808 11.5 8.24808C11.6204 8.24808 11.7381 8.21614 11.8381 8.15632C11.9381 8.09651 12.0159 8.01152 12.0616 7.91214C12.5792 6.78318 13.8185 6.08359 15.2946 6.08359C16.2202 6.08466 17.1074 6.41323 17.7619 6.99723C18.4163 7.58123 18.7845 8.373 18.7857 9.19891C18.7857 12.8296 12.8843 16.1169 11.5 16.8382Z" fill="#D80621"/>
                        <path d="M14.8482 5C13.4654 5 12.2547 5.55513 11.5 6.49348C10.7453 5.55513 9.5346 5 8.15179 5C7.05104 5.00116 5.99574 5.40988 5.2174 6.13651C4.43906 6.86313 4.00124 7.84831 4 8.87591C4 13.2519 10.9502 16.794 11.2462 16.9403C11.3242 16.9795 11.4114 17 11.5 17C11.5886 17 11.6758 16.9795 11.7538 16.9403C12.0498 16.794 19 13.2519 19 8.87591C18.9988 7.84831 18.5609 6.86313 17.7826 6.13651C17.0043 5.40988 15.949 5.00116 14.8482 5ZM11.5 15.9276C10.2772 15.2624 5.07143 12.2323 5.07143 8.87591C5.07249 8.11354 5.39737 7.38268 5.97482 6.8436C6.55227 6.30452 7.33515 6.00123 8.15179 6.00024C9.45424 6.00024 10.5478 6.64789 11.0045 7.68813C11.0448 7.77986 11.1135 7.85832 11.2017 7.91353C11.29 7.96874 11.3938 7.99822 11.5 7.99822C11.6062 7.99822 11.71 7.96874 11.7983 7.91353C11.8865 7.85832 11.9552 7.77986 11.9955 7.68813C12.4522 6.64601 13.5458 6.00024 14.8482 6.00024C15.6648 6.00123 16.4477 6.30452 17.0252 6.8436C17.6026 7.38268 17.9275 8.11354 17.9286 8.87591C17.9286 12.2273 12.7214 15.2618 11.5 15.9276Z" fill="#D80621"/>
                        <path d="M14.8482 6C13.4654 6 12.2547 6.46261 11.5 7.24456C10.7453 6.46261 9.5346 6 8.15179 6C7.05104 6.00097 5.99574 6.34157 5.2174 6.94709C4.43906 7.55261 4.00124 8.37359 4 9.22993C4 12.8766 10.9502 15.8284 11.2462 15.9503C11.3242 15.9829 11.4114 16 11.5 16C11.5886 16 11.6758 15.9829 11.7538 15.9503C12.0498 15.8284 19 12.8766 19 9.22993C18.9988 8.37359 18.5609 7.55261 17.7826 6.94709C17.0043 6.34157 15.949 6.00097 14.8482 6ZM11.5 15.1063C10.2772 14.552 5.07143 12.0269 5.07143 9.22993C5.07249 8.59462 5.39737 7.98556 5.97482 7.53633C6.55227 7.0871 7.33515 6.83436 8.15179 6.83353C9.45424 6.83353 10.5478 7.37324 11.0045 8.24011C11.0448 8.31655 11.1135 8.38193 11.2017 8.42794C11.29 8.47395 11.3938 8.49852 11.5 8.49852C11.6062 8.49852 11.71 8.47395 11.7983 8.42794C11.8865 8.38193 11.9552 8.31655 11.9955 8.24011C12.4522 7.37168 13.5458 6.83353 14.8482 6.83353C15.6648 6.83436 16.4477 7.0871 17.0252 7.53633C17.6026 7.98556 17.9275 8.59462 17.9286 9.22993C17.9286 12.0228 12.7214 14.5515 11.5 15.1063Z" fill="#D80621"/>
                        <path d="M14.8482 6C13.4654 6 12.2547 6.46261 11.5 7.24456C10.7453 6.46261 9.5346 6 8.15179 6C7.05104 6.00097 5.99574 6.34157 5.2174 6.94709C4.43906 7.55261 4.00124 8.37359 4 9.22993C4 12.8766 10.9502 15.8284 11.2462 15.9503C11.3242 15.9829 11.4114 16 11.5 16C11.5886 16 11.6758 15.9829 11.7538 15.9503C12.0498 15.8284 19 12.8766 19 9.22993C18.9988 8.37359 18.5609 7.55261 17.7826 6.94709C17.0043 6.34157 15.949 6.00097 14.8482 6ZM11.5 15.1063C10.2772 14.552 5.07143 12.0269 5.07143 9.22993C5.07249 8.59462 5.39737 7.98556 5.97482 7.53633C6.55227 7.0871 7.33515 6.83436 8.15179 6.83353C9.45424 6.83353 10.5478 7.37324 11.0045 8.24011C11.0448 8.31655 11.1135 8.38193 11.2017 8.42794C11.29 8.47395 11.3938 8.49852 11.5 8.49852C11.6062 8.49852 11.71 8.47395 11.7983 8.42794C11.8865 8.38193 11.9552 8.31655 11.9955 8.24011C12.4522 7.37168 13.5458 6.83353 14.8482 6.83353C15.6648 6.83436 16.4477 7.0871 17.0252 7.53633C17.6026 7.98556 17.9275 8.59462 17.9286 9.22993C17.9286 12.0228 12.7214 14.5515 11.5 15.1063Z" fill="#D80621"/>
                        <path d="M14.8482 5C13.4654 5 12.2547 5.50887 11.5 6.36902C10.7453 5.50887 9.5346 5 8.15179 5C7.05104 5.00106 5.99574 5.37573 5.2174 6.0418C4.43906 6.70787 4.00124 7.61095 4 8.55292C4 12.5643 10.9502 15.8112 11.2462 15.9453C11.3242 15.9812 11.4114 16 11.5 16C11.5886 16 11.6758 15.9812 11.7538 15.9453C12.0498 15.8112 19 12.5643 19 8.55292C18.9988 7.61095 18.5609 6.70787 17.7826 6.0418C17.0043 5.37573 15.949 5.00106 14.8482 5ZM11.5 15.0169C10.2772 14.4072 5.07143 11.6296 5.07143 8.55292C5.07249 7.85408 5.39737 7.18412 5.97482 6.68997C6.55227 6.19581 7.33515 5.91779 8.15179 5.91688C9.45424 5.91688 10.5478 6.51056 11.0045 7.46412C11.0448 7.54821 11.1135 7.62012 11.2017 7.67074C11.29 7.72135 11.3938 7.74837 11.5 7.74837C11.6062 7.74837 11.71 7.72135 11.7983 7.67074C11.8865 7.62012 11.9552 7.54821 11.9955 7.46412C12.4522 6.50885 13.5458 5.91688 14.8482 5.91688C15.6648 5.91779 16.4477 6.19581 17.0252 6.68997C17.6026 7.18412 17.9275 7.85408 17.9286 8.55292C17.9286 11.6251 12.7214 14.4066 11.5 15.0169Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.46261 11.5 7.24456C10.8459 6.46261 9.79665 6 8.59821 6C7.64424 6.00097 6.72964 6.34157 6.05508 6.94709C5.38052 7.55261 5.00108 8.37359 5 9.22993C5 12.8766 11.0235 15.8284 11.28 15.9503C11.3477 15.9829 11.4232 16 11.5 16C11.5768 16 11.6523 15.9829 11.72 15.9503C11.9765 15.8284 18 12.8766 18 9.22993C17.9989 8.37359 17.6195 7.55261 16.9449 6.94709C16.2704 6.34157 15.3558 6.00097 14.4018 6ZM11.5 15.1063C10.4403 14.552 5.92857 12.0269 5.92857 9.22993C5.92949 8.59462 6.21105 7.98556 6.71151 7.53633C7.21196 7.0871 7.89046 6.83436 8.59821 6.83353C9.72701 6.83353 10.6747 7.37324 11.0705 8.24011C11.1055 8.31655 11.165 8.38193 11.2415 8.42794C11.318 8.47395 11.4079 8.49852 11.5 8.49852C11.5921 8.49852 11.682 8.47395 11.7585 8.42794C11.835 8.38193 11.8945 8.31655 11.9295 8.24011C12.3253 7.37168 13.273 6.83353 14.4018 6.83353C15.1095 6.83436 15.788 7.0871 16.2885 7.53633C16.7889 7.98556 17.0705 8.59462 17.0714 9.22993C17.0714 12.0228 12.5586 14.5515 11.5 15.1063Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.41635 11.5 7.12011C10.8459 6.41635 9.79665 6 8.59821 6C7.64424 6.00087 6.72964 6.30741 6.05508 6.85238C5.38052 7.39735 5.00108 8.13623 5 8.90694C5 12.189 11.0235 14.8455 11.28 14.9552C11.3477 14.9846 11.4232 15 11.5 15C11.5768 15 11.6523 14.9846 11.72 14.9552C11.9765 14.8455 18 12.189 18 8.90694C17.9989 8.13623 17.6195 7.39735 16.9449 6.85238C16.2704 6.30741 15.3558 6.00087 14.4018 6ZM11.5 14.1957C10.4403 13.6968 5.92857 11.4242 5.92857 8.90694C5.92949 8.33516 6.21105 7.78701 6.71151 7.3827C7.21196 6.97839 7.89046 6.75092 8.59821 6.75018C9.72701 6.75018 10.6747 7.23592 11.0705 8.0161C11.1055 8.0849 11.165 8.14374 11.2415 8.18515C11.318 8.22656 11.4079 8.24867 11.5 8.24867C11.5921 8.24867 11.682 8.22656 11.7585 8.18515C11.835 8.14374 11.8945 8.0849 11.9295 8.0161C12.3253 7.23451 13.273 6.75018 14.4018 6.75018C15.1095 6.75092 15.788 6.97839 16.2885 7.3827C16.7889 7.78701 17.0705 8.33516 17.0714 8.90694C17.0714 11.4205 12.5586 13.6963 11.5 14.1957Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.46261 11.5 7.24456C10.8459 6.46261 9.79665 6 8.59821 6C7.64424 6.00097 6.72964 6.34157 6.05508 6.94709C5.38052 7.55261 5.00108 8.37359 5 9.22993C5 12.8766 11.0235 15.8284 11.28 15.9503C11.3477 15.9829 11.4232 16 11.5 16C11.5768 16 11.6523 15.9829 11.72 15.9503C11.9765 15.8284 18 12.8766 18 9.22993C17.9989 8.37359 17.6195 7.55261 16.9449 6.94709C16.2704 6.34157 15.3558 6.00097 14.4018 6ZM11.5 15.1063C10.4403 14.552 5.92857 12.0269 5.92857 9.22993C5.92949 8.59462 6.21105 7.98556 6.71151 7.53633C7.21196 7.0871 7.89046 6.83436 8.59821 6.83353C9.72701 6.83353 10.6747 7.37324 11.0705 8.24011C11.1055 8.31655 11.165 8.38193 11.2415 8.42794C11.318 8.47395 11.4079 8.49852 11.5 8.49852C11.5921 8.49852 11.682 8.47395 11.7585 8.42794C11.835 8.38193 11.8945 8.31655 11.9295 8.24011C12.3253 7.37168 13.273 6.83353 14.4018 6.83353C15.1095 6.83436 15.788 7.0871 16.2885 7.53633C16.7889 7.98556 17.0705 8.59462 17.0714 9.22993C17.0714 12.0228 12.5586 14.5515 11.5 15.1063Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.43058 11.5 7.1584C10.8459 6.43058 9.79665 6 8.59821 6C7.64424 6.0009 6.72964 6.31792 6.05508 6.88152C5.38052 7.44512 5.00108 8.20927 5 9.00632C5 12.4005 11.0235 15.1479 11.28 15.2614C11.3477 15.2918 11.4232 15.3077 11.5 15.3077C11.5768 15.3077 11.6523 15.2918 11.72 15.2614C11.9765 15.1479 18 12.4005 18 9.00632C17.9989 8.20927 17.6195 7.44512 16.9449 6.88152C16.2704 6.31792 15.3558 6.0009 14.4018 6ZM11.5 14.4759C10.4403 13.96 5.92857 11.6097 5.92857 9.00632C5.92949 8.41499 6.21105 7.8481 6.71151 7.42997C7.21196 7.01184 7.89046 6.77659 8.59821 6.77582C9.72701 6.77582 10.6747 7.27817 11.0705 8.08503C11.1055 8.15617 11.165 8.21703 11.2415 8.25985C11.318 8.30268 11.4079 8.32555 11.5 8.32555C11.5921 8.32555 11.682 8.30268 11.7585 8.25985C11.835 8.21703 11.8945 8.15617 11.9295 8.08503C12.3253 7.27672 13.273 6.77582 14.4018 6.77582C15.1095 6.77659 15.788 7.01184 16.2885 7.42997C16.7889 7.8481 17.0705 8.41499 17.0714 9.00632C17.0714 11.6058 12.5586 13.9595 11.5 14.4759Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.43058 11.5 7.1584C10.8459 6.43058 9.79665 6 8.59821 6C7.64424 6.0009 6.72964 6.31792 6.05508 6.88152C5.38052 7.44512 5.00108 8.20927 5 9.00632C5 12.4005 11.0235 15.1479 11.28 15.2614C11.3477 15.2918 11.4232 15.3077 11.5 15.3077C11.5768 15.3077 11.6523 15.2918 11.72 15.2614C11.9765 15.1479 18 12.4005 18 9.00632C17.9989 8.20927 17.6195 7.44512 16.9449 6.88152C16.2704 6.31792 15.3558 6.0009 14.4018 6ZM11.5 14.4759C10.4403 13.96 5.92857 11.6097 5.92857 9.00632C5.92949 8.41499 6.21105 7.8481 6.71151 7.42997C7.21196 7.01184 7.89046 6.77659 8.59821 6.77582C9.72701 6.77582 10.6747 7.27817 11.0705 8.08503C11.1055 8.15617 11.165 8.21703 11.2415 8.25985C11.318 8.30268 11.4079 8.32555 11.5 8.32555C11.5921 8.32555 11.682 8.30268 11.7585 8.25985C11.835 8.21703 11.8945 8.15617 11.9295 8.08503C12.3253 7.27672 13.273 6.77582 14.4018 6.77582C15.1095 6.77659 15.788 7.01184 16.2885 7.42997C16.7889 7.8481 17.0705 8.41499 17.0714 9.00632C17.0714 11.6058 12.5586 13.9595 11.5 14.4759Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.50887 11.5 7.36902C10.8459 6.50887 9.79665 6 8.59821 6C7.64424 6.00106 6.72964 6.37573 6.05508 7.0418C5.38052 7.70787 5.00108 8.61095 5 9.55292C5 13.5643 11.0235 16.8112 11.28 16.9453C11.3477 16.9812 11.4232 17 11.5 17C11.5768 17 11.6523 16.9812 11.72 16.9453C11.9765 16.8112 18 13.5643 18 9.55292C17.9989 8.61095 17.6195 7.70787 16.9449 7.0418C16.2704 6.37573 15.3558 6.00106 14.4018 6ZM11.5 16.0169C10.4403 15.4072 5.92857 12.6296 5.92857 9.55292C5.92949 8.85408 6.21105 8.18412 6.71151 7.68997C7.21196 7.19581 7.89046 6.91779 8.59821 6.91688C9.72701 6.91688 10.6747 7.51056 11.0705 8.46412C11.1055 8.54821 11.165 8.62012 11.2415 8.67074C11.318 8.72135 11.4079 8.74837 11.5 8.74837C11.5921 8.74837 11.682 8.72135 11.7585 8.67074C11.835 8.62012 11.8945 8.54821 11.9295 8.46412C12.3253 7.50885 13.273 6.91688 14.4018 6.91688C15.1095 6.91779 15.788 7.19581 16.2885 7.68997C16.7889 8.18412 17.0705 8.85408 17.0714 9.55292C17.0714 12.6251 12.5586 15.4066 11.5 16.0169Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.50887 11.5 7.36902C10.8459 6.50887 9.79665 6 8.59821 6C7.64424 6.00106 6.72964 6.37573 6.05508 7.0418C5.38052 7.70787 5.00108 8.61095 5 9.55292C5 13.5643 11.0235 16.8112 11.28 16.9453C11.3477 16.9812 11.4232 17 11.5 17C11.5768 17 11.6523 16.9812 11.72 16.9453C11.9765 16.8112 18 13.5643 18 9.55292C17.9989 8.61095 17.6195 7.70787 16.9449 7.0418C16.2704 6.37573 15.3558 6.00106 14.4018 6ZM11.5 16.0169C10.4403 15.4072 5.92857 12.6296 5.92857 9.55292C5.92949 8.85408 6.21105 8.18412 6.71151 7.68997C7.21196 7.19581 7.89046 6.91779 8.59821 6.91688C9.72701 6.91688 10.6747 7.51056 11.0705 8.46412C11.1055 8.54821 11.165 8.62012 11.2415 8.67074C11.318 8.72135 11.4079 8.74837 11.5 8.74837C11.5921 8.74837 11.682 8.72135 11.7585 8.67074C11.835 8.62012 11.8945 8.54821 11.9295 8.46412C12.3253 7.50885 13.273 6.91688 14.4018 6.91688C15.1095 6.91779 15.788 7.19581 16.2885 7.68997C16.7889 8.18412 17.0705 8.85408 17.0714 9.55292C17.0714 12.6251 12.5586 15.4066 11.5 16.0169Z" fill="#D80621"/>
                        <path d="M14.4018 6C13.2033 6 12.1541 6.55513 11.5 7.49348C10.8459 6.55513 9.79665 6 8.59821 6C7.64424 6.00116 6.72964 6.40988 6.05508 7.13651C5.38052 7.86313 5.00108 8.84831 5 9.87591C5 14.2519 11.0235 17.794 11.28 17.9403C11.3477 17.9795 11.4232 18 11.5 18C11.5768 18 11.6523 17.9795 11.72 17.9403C11.9765 17.794 18 14.2519 18 9.87591C17.9989 8.84831 17.6195 7.86313 16.9449 7.13651C16.2704 6.40988 15.3558 6.00116 14.4018 6ZM11.5 16.9276C10.4403 16.2624 5.92857 13.2323 5.92857 9.87591C5.92949 9.11354 6.21105 8.38268 6.71151 7.8436C7.21196 7.30452 7.89046 7.00123 8.59821 7.00024C9.72701 7.00024 10.6747 7.64789 11.0705 8.68813C11.1055 8.77986 11.165 8.85832 11.2415 8.91353C11.318 8.96874 11.4079 8.99822 11.5 8.99822C11.5921 8.99822 11.682 8.96874 11.7585 8.91353C11.835 8.85832 11.8945 8.77986 11.9295 8.68813C12.3253 7.64601 13.273 7.00024 14.4018 7.00024C15.1095 7.00123 15.788 7.30452 16.2885 7.8436C16.7889 8.38268 17.0705 9.11354 17.0714 9.87591C17.0714 13.2273 12.5586 16.2618 11.5 16.9276Z" fill="#D80621"/>
                        <path d="M14.4018 7C13.2033 7 12.1541 7.55513 11.5 8.49348C10.8459 7.55513 9.79665 7 8.59821 7C7.64424 7.00116 6.72964 7.40988 6.05508 8.13651C5.38052 8.86313 5.00108 9.84831 5 10.8759C5 15.2519 11.0235 18.794 11.28 18.9403C11.3477 18.9795 11.4232 19 11.5 19C11.5768 19 11.6523 18.9795 11.72 18.9403C11.9765 18.794 18 15.2519 18 10.8759C17.9989 9.84831 17.6195 8.86313 16.9449 8.13651C16.2704 7.40988 15.3558 7.00116 14.4018 7ZM11.5 17.9276C10.4403 17.2624 5.92857 14.2323 5.92857 10.8759C5.92949 10.1135 6.21105 9.38268 6.71151 8.8436C7.21196 8.30452 7.89046 8.00123 8.59821 8.00024C9.72701 8.00024 10.6747 8.64789 11.0705 9.68813C11.1055 9.77986 11.165 9.85832 11.2415 9.91353C11.318 9.96874 11.4079 9.99822 11.5 9.99822C11.5921 9.99822 11.682 9.96874 11.7585 9.91353C11.835 9.85832 11.8945 9.77986 11.9295 9.68813C12.3253 8.64601 13.273 8.00024 14.4018 8.00024C15.1095 8.00123 15.788 8.30452 16.2885 8.8436C16.7889 9.38268 17.0705 10.1135 17.0714 10.8759C17.0714 14.2273 12.5586 17.2618 11.5 17.9276Z" fill="#D80621"/>
                        <path d="M14.4018 8C13.2033 8 12.1541 8.55513 11.5 9.49348C10.8459 8.55513 9.79665 8 8.59821 8C7.64424 8.00116 6.72964 8.40988 6.05508 9.13651C5.38052 9.86313 5.00108 10.8483 5 11.8759C5 16.2519 11.0235 19.794 11.28 19.9403C11.3477 19.9795 11.4232 20 11.5 20C11.5768 20 11.6523 19.9795 11.72 19.9403C11.9765 19.794 18 16.2519 18 11.8759C17.9989 10.8483 17.6195 9.86313 16.9449 9.13651C16.2704 8.40988 15.3558 8.00116 14.4018 8ZM11.5 18.9276C10.4403 18.2624 5.92857 15.2323 5.92857 11.8759C5.92949 11.1135 6.21105 10.3827 6.71151 9.8436C7.21196 9.30452 7.89046 9.00123 8.59821 9.00024C9.72701 9.00024 10.6747 9.64789 11.0705 10.6881C11.1055 10.7799 11.165 10.8583 11.2415 10.9135C11.318 10.9687 11.4079 10.9982 11.5 10.9982C11.5921 10.9982 11.682 10.9687 11.7585 10.9135C11.835 10.8583 11.8945 10.7799 11.9295 10.6881C12.3253 9.64601 13.273 9.00024 14.4018 9.00024C15.1095 9.00123 15.788 9.30452 16.2885 9.8436C16.7889 10.3827 17.0705 11.1135 17.0714 11.8759C17.0714 15.2273 12.5586 18.2618 11.5 18.9276Z" fill="#D80621"/>
                        <path d="M14.4018 9C13.2033 9 12.1541 9.32383 11.5 9.8712C10.8459 9.32383 9.79665 9 8.59821 9C7.64424 9.00068 6.72964 9.2391 6.05508 9.66296C5.38052 10.0868 5.00108 10.6615 5 11.2609C5 13.8136 11.0235 15.8799 11.28 15.9652C11.3477 15.988 11.4232 16 11.5 16C11.5768 16 11.6523 15.988 11.72 15.9652C11.9765 15.8799 18 13.8136 18 11.2609C17.9989 10.6615 17.6195 10.0868 16.9449 9.66296C16.2704 9.2391 15.3558 9.00068 14.4018 9ZM11.5 15.3744C10.4403 14.9864 5.92857 13.2189 5.92857 11.2609C5.92949 10.8162 6.21105 10.3899 6.71151 10.0754C7.21196 9.76097 7.89046 9.58405 8.59821 9.58347C9.72701 9.58347 10.6747 9.96127 11.0705 10.5681C11.1055 10.6216 11.165 10.6674 11.2415 10.6996C11.318 10.7318 11.4079 10.749 11.5 10.749C11.5921 10.749 11.682 10.7318 11.7585 10.6996C11.835 10.6674 11.8945 10.6216 11.9295 10.5681C12.3253 9.96017 13.273 9.58347 14.4018 9.58347C15.1095 9.58405 15.788 9.76097 16.2885 10.0754C16.7889 10.3899 17.0705 10.8162 17.0714 11.2609C17.0714 13.2159 12.5586 14.986 11.5 15.3744Z" fill="#D80621"/>
                        <path d="M14.4018 10C13.2033 10 12.1541 10.3701 11.5 10.9957C10.8459 10.3701 9.79665 10 8.59821 10C7.64424 10.0008 6.72964 10.2733 6.05508 10.7577C5.38052 11.2421 5.00108 11.8989 5 12.5839C5 15.5013 11.0235 17.8627 11.28 17.9602C11.3477 17.9863 11.4232 18 11.5 18C11.5768 18 11.6523 17.9863 11.72 17.9602C11.9765 17.8627 18 15.5013 18 12.5839C17.9989 11.8989 17.6195 11.2421 16.9449 10.7577C16.2704 10.2733 15.3558 10.0008 14.4018 10ZM11.5 17.2851C10.4403 16.8416 5.92857 14.8216 5.92857 12.5839C5.92949 12.0757 6.21105 11.5885 6.71151 11.2291C7.21196 10.8697 7.89046 10.6675 8.59821 10.6668C9.72701 10.6668 10.6747 11.0986 11.0705 11.7921C11.1055 11.8532 11.165 11.9055 11.2415 11.9424C11.318 11.9792 11.4079 11.9988 11.5 11.9988C11.5921 11.9988 11.682 11.9792 11.7585 11.9424C11.835 11.9055 11.8945 11.8532 11.9295 11.7921C12.3253 11.0973 13.273 10.6668 14.4018 10.6668C15.1095 10.6675 15.788 10.8697 16.2885 11.2291C16.7889 11.5885 17.0705 12.0757 17.0714 12.5839C17.0714 14.8182 12.5586 16.8412 11.5 17.2851Z" fill="#D80621"/>
                        <path d="M17.4996 8.00023C16.3013 8.00023 12.8736 6.87641 12.2197 7.50197C11.5657 6.87641 10.2963 8.00214 9.09797 8.00214C8.14411 8.00291 6.17418 8.51949 5.4997 9.00391C4.82522 9.48832 5.50088 12.901 5.4998 13.5861C5.4998 16.5034 11.5233 18.8648 11.7798 18.9624C11.8474 18.9885 11.9229 19.0021 11.9997 19.0021C12.0765 19.0021 12.1521 18.9885 12.2197 18.9624C12.4762 18.8648 18.4996 16.5034 18.4996 13.5861C18.4985 12.901 18.119 12.2442 17.4446 11.7598C16.7701 11.2754 18.4535 8.001 17.4996 8.00023ZM11.9997 18.2872C10.9401 17.8438 6.42836 15.8237 6.42836 13.5861C6.42928 13.0778 6.7109 12.5906 7.21129 12.2312C7.71168 11.8718 13.2919 16.0046 13.9996 16.0039C15.1282 16.0039 11.1745 12.1007 11.5703 12.7942C11.6052 12.8554 11.6647 12.9077 11.7412 12.9445C11.8177 12.9813 12.1276 15.2167 12.2197 15.2167C12.3117 15.2167 13.4235 13.5368 13.5 13.5C13.5765 13.4632 12.3942 12.8554 12.4292 12.7942C12.8249 12.0995 10.371 15.5039 11.4996 15.5039C12.2073 15.5046 16.2877 11.8718 16.7881 12.2312C17.2885 12.5906 17.5701 13.0778 17.5711 13.5861C17.5711 15.8204 13.0582 17.8433 11.9997 18.2872Z" fill="#D80621"/>
                        <path d="M14.4018 12C13.2033 12 12.1541 12.1388 11.5 12.3734C10.8459 12.1388 9.79665 12 8.59821 12C7.64424 12.0003 6.72964 12.1025 6.05508 12.2841C5.38052 12.4658 5.00108 12.7121 5 12.969C5 14.063 11.0235 14.9485 11.28 14.9851C11.3477 14.9949 11.4232 15 11.5 15C11.5768 15 11.6523 14.9949 11.72 14.9851C11.9765 14.9485 18 14.063 18 12.969C17.9989 12.7121 17.6195 12.4658 16.9449 12.2841C16.2704 12.1025 15.3558 12.0003 14.4018 12ZM11.5 14.7319C10.4403 14.5656 5.92857 13.8081 5.92857 12.969C5.92949 12.7784 6.21105 12.5957 6.71151 12.4609C7.21196 12.3261 7.89046 12.2503 8.59821 12.2501C9.72701 12.2501 10.6747 12.412 11.0705 12.672C11.1055 12.695 11.165 12.7146 11.2415 12.7284C11.318 12.7422 11.4079 12.7496 11.5 12.7496C11.5921 12.7496 11.682 12.7422 11.7585 12.7284C11.835 12.7146 11.8945 12.695 11.9295 12.672C12.3253 12.4115 13.273 12.2501 14.4018 12.2501C15.1095 12.2503 15.788 12.3261 16.2885 12.4609C16.7889 12.5957 17.0705 12.7784 17.0714 12.969C17.0714 13.8068 12.5586 14.5654 11.5 14.7319Z" fill="#D80621"/>
                        <path d="M14.4018 13C13.2033 13 12.1541 13.185 11.5 13.4978C10.8459 13.185 9.79665 13 8.59821 13C7.64424 13.0004 6.72964 13.1366 6.05508 13.3788C5.38052 13.621 5.00108 13.9494 5 14.292C5 15.7506 11.0235 16.9313 11.28 16.9801C11.3477 16.9932 11.4232 17 11.5 17C11.5768 17 11.6523 16.9932 11.72 16.9801C11.9765 16.9313 18 15.7506 18 14.292C17.9989 13.9494 17.6195 13.621 16.9449 13.3788C16.2704 13.1366 15.3558 13.0004 14.4018 13ZM11.5 16.6425C10.4403 16.4208 5.92857 15.4108 5.92857 14.292C5.92949 14.0378 6.21105 13.7942 6.71151 13.6145C7.21196 13.4348 7.89046 13.3337 8.59821 13.3334C9.72701 13.3334 10.6747 13.5493 11.0705 13.896C11.1055 13.9266 11.165 13.9528 11.2415 13.9712C11.318 13.9896 11.4079 13.9994 11.5 13.9994C11.5921 13.9994 11.682 13.9896 11.7585 13.9712C11.835 13.9528 11.8945 13.9266 11.9295 13.896C12.3253 13.5487 13.273 13.3334 14.4018 13.3334C15.1095 13.3337 15.788 13.4348 16.2885 13.6145C16.7889 13.7942 17.0705 14.0378 17.0714 14.292C17.0714 15.4091 12.5586 16.4206 11.5 16.6425Z" fill="#D80621"/>
                        </svg>)
                        
                    }
            }else{ 
                 if(typeof(message) === "string"){
                    enqueueSnackbar(message,{variant:"error",anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                      }}) 
                }else{
                    Object.keys(message).map((key)=>{
                        enqueueSnackbar(`${key} : ${message[key][0]}`,{variant:"error",anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                          }}) 
                    })
                }
                lottie.destroy()
                setIsLoading(false)
               
            }
        }
        
    }

   
    return (
            <Card key={key} >
                { localStorage.getItem("user") &&
                <>
                
                <Box ref={heart_ref}  style={{position:"absolute",top:"0",right:"0",zIndex:"8"}}>

                    </Box>
                {!isLoading && <Box onClick={change_status_wishlist} sx={{position:"absolute",top:"0",right:"0",zIndex:"8",cursor:"pointer"}}>
                    {wishlistIcon}
                    </Box>}
                </>
                 }
                
                    <a href={`/product/${id}`}>
                   <Box>
                   <Box  sx={{overflow:"hidden",height:"150px",background:"white",display:"flex",justifyContent:'center',padding:"10px"}}>
                        <img src={photo?photo:img}  alt={name} />
                    </Box>
                    <Box>
                        <Grid container >
                           <Grid item sx={{display:"flex",alignItems:"center"}} lg={6}  md={6} sm={6} xs={6}>
                                <span >
                                    {sellprice} CAD
                                </span>
                            </Grid>
                            {quantity_Selected && <Grid item sx={{display:"flex",justifyContent:"end"}} lg={6}  md={6} sm={6} xs={6}>
                                <Box sx={{borderRadius:"4px",border:"1px solid #e4e4e4",padding:"5px"}}>
                                    <span style={{color:"grey"}}>
                                        Quantity : 
                                    </span>
                                    &nbsp;{quantity_Selected}
                                </Box>
                            </Grid> }
                        </Grid>
                        
                        <h3 onClick={()=>{navigate(`/product/${id}`)}} >
                            {/* <a href = {`/product/${e.id}`}> */}
                            {name}
                            {/* </a> */}
                        </h3>
                        
                        
                        { vendor&& <p  style={{textTransform:"capitalize"}}>
                            By : <LinkVendor href={`/vendor/${vendor}/${vendor_id}`}>{vendor}</LinkVendor>
                        </p>}
                    </Box>
                   </Box>
                   </a>
                </Card> 

    );
}

export default Simple_Card_Product;