import React,{useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useBreakpoint} from 'react-use-size';
//mui
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Button, Grid, Skeleton } from '@mui/material';
import Badge from '@mui/material/Badge';
//api request 
import MDButton from '../components/MDButton';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { echo_info, login } from '../redux/action';
//pages
import no_messages from "../assets/images/messages.png"
import profile from "../assets/images/profile.png"
import { Helper } from '../tools/helper';
import apiRoutes from '../apiRoutes';
import dateFormat from 'dateformat';
import axios, { Axios } from 'axios';
import { listen_message_channel } from '../echo/echo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Head = styled.h1`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Bold_xl.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Bold_xl.weight};
  font-family:${props => props.theme.typography.Montserrat_Bold_xl.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Bold_xl.lineHeight};
  text-align:center
`
const Des = styled.p`
  color: ${props => props.theme.colors.main.hint};
  font-size:${props => props.theme.typography.Montserrat_Regular_base.fontSize};
  font-weight:${props => props.theme.typography.Montserrat_Regular_base.weight};
  font-family:${props => props.theme.typography.Montserrat_Regular_base.fontFamily};
  line-height:${props => props.theme.typography.Montserrat_Regular_base.lineHeight};
  text-align:center
`

const TitleMenu = styled.h2`
font-size:14px;
font-weight:${props => props.theme.typography.Montserrat_SemiBold_base.weight};
font-family:${props => props.theme.typography.Montserrat_SemiBold_base.fontFamily};
line-height:${props => props.theme.typography.Montserrat_SemiBold_base.lineHeight};
padding:0 10px;
`
const PathBasic = styled.path`
fill:${props=> props.theme.colors.main.complemently}
`

function MessageMenu({userName,logout,name,photo,lang,cart_with_api,notification_info_redux,message_redux,notification_redux,conversation_id_redux}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSoSmall = useBreakpoint(600)
    const forMessage = useBreakpoint(769)
    const [ nameMenu, setNameMenu ] = React.useState("")
    const navigate = useNavigate()
    const [Notification,setNotification] = React.useState([])
    const [New,  setNew] = React.useState(null)
    const [count,setCount] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(()=>{
      const controller = new AbortController();
      const signal = controller.signal;
      setIsLoading(true)
      const get_LatestMessages=  async(signal)=>{
        setIsLoading(true)
        const {response , message} = await Helper.Get_Abort({
          url:apiRoutes.messages.conversation,
          data:{results:50},
          signal:signal,
          hasToken:true
        })
        if(response){
          setNotification(response.data)
          setIsLoading(false)
        }else{
          // console.log(message);
        }
      }
      if(localStorage.hasOwnProperty("user")){
        listen_message_channel()
        get_LatestMessages(signal)
        if(notification_info_redux.receiver == JSON.parse(localStorage.getItem("user")).id){
            setNew(prev=>prev+1)
        }
      }
     
      return () => {
        // cancel the request before component unmounts
        controller.abort();
    };
   
    },[notification_info_redux])

 

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setNew(0)
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
      setAnchorEl(null);
    };
    const handleNavigate = (e)=>{
      setAnchorEl(null);
      navigate(e)
    }




    return (
      <React.Fragment>
        <li className='menu_li'>
          {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
          <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
          <Tooltip title={"Messages" }>
            <Box
              onClick={handleClick}
              
              size="small"
              sx={{ ml:0,
              padding:"0 !important",
              display:"flex",
              alignItems:"center",
              "& .path-nav-stroke":{
                stroke:"grey",
                transition:"0.3s"
              },
              "&:hover":{
                cursor:"pointer",
                  "& .path-nav-stroke":{
                    
                    stroke:"#D80621"
                  }
                }
                }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {/* <Avatar onClick={handleClose} sx={{background:"white", width: 32, height: 32 }}> */}
                <Badge color="error" badgeContent={New}>
                <svg xmlns="http://www.w3.org/2000/svg" width={isSoSmall?"18":"24"} height={isSoSmall?"18":"24"} viewBox="0 0 24 24" fill="none">
                  <path className='path-nav-stroke' d="M19.5 4H4.5C3.11929 4 2 5.11929 2 6.5V16.5C2 17.8807 3.11929 19 4.5 19H19.5C20.8807 19 22 17.8807 22 16.5V6.5C22 5.11929 20.8807 4 19.5 4Z"  strokeWidth="1.3"/>
                  <path className='path-nav-stroke' d="M2 7.75L10.8825 12.1913C11.2295 12.3646 11.6121 12.4549 12 12.4549C12.3879 12.4549 12.7705 12.3646 13.1175 12.1913L22 7.75"  strokeWidth="1.3"/>
                  </svg>
                </Badge>
            
              {/* </Avatar> */}
            </Box>
          </Tooltip>
          
        </li>
      {(localStorage.hasOwnProperty("user"))&&<Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          
          PaperProps={{
            elevation: 0,
            sx: {
              width:"400px",
              overflow: 'visible',
              overflowY:"",
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 40,
                height: 40,
                // ml: -0.5,
                // mr: 1,
              },
           
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {(localStorage.hasOwnProperty("user"))?
          
          (isLoading?<Box className="scroll-div">
             <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",borderBottom:"1px solid #8080802e",}}>
                     <TitleMenu>
                       Chat
                   </TitleMenu> 
                    <Button onClick={()=>{
                      navigate(`/messages`,{
                        state:{
                          message:forMessage?`Menu_Conversation_Samll_Size`:`Menu_Conversation`,
                      }
                      })
                      handleClose()
                  }} disabled>View all</Button>
                  </Box>
              {[1,2,3,4,5].map((e,index)=>{
               return <Box style={{position:"relative"}} key={`Mesaages_Loading_${index+e}`} >
                 
                 
                    <Box sx={{display:"flex",
                    padding:"4px ",
                    // borderBottom:"1px solid #8080802e",
                    transition:"0.2s ease-in",
                         "&:hover":{
                           cursor:"pointer",
                           background:"rgb(192 192 192 / 39%)"
                         }
                     }}>
                       <Box sx={{width:"100px",padding:"5px"}}>
                           <div style={{display:"flex",justifyContent:"center"}}><IconButton>
                             <Skeleton animation="wave"  width={20} />
                             
                           </IconButton></div> 
                           
                       </Box>
                       <Box sx={{padding:"5px"}}>
                         <Typography >
                         <Skeleton animation="wave"  width={120} />
                         
                       </Typography>
                       <Box sx={{color:"grey"}} >
                       <Skeleton animation="wave"  width={250} />
                       </Box>
                     
                       </Box>
                    </Box>
                 
                
            </Box>
            })}
           </Box>:(Notification.length<=0?<Box style={{position:"relative"}} >
           <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",borderBottom:"1px solid #8080802e",}}>
                     <TitleMenu>
                       Chat
                   </TitleMenu> 
                    <Button onClick={()=>{
                      // navigate(`/messages`,{
                      //   state:{
                      //     message:forMessage?`Menu_Conversation_Samll_Size`:`Menu_Conversation`,
                      // }
                      // })
                      handleClose()
                  }} disabled>View all</Button>
                  </Box>
      
             <Box sx={{display:"flex",justifyContent:"center"}}>
               <img style={{width:"50%"}} src={no_messages}/>
             </Box> 
             <Box >
               <Head>No Messages</Head>
               <Des>
               You don’t have any message.
               </Des>
             </Box>
          </Box>:<Box>
             <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",borderBottom:"1px solid #8080802e",}}>
                     <TitleMenu>
                       Chat
                   </TitleMenu> 
                    <Button onClick={()=>{
                      navigate(`/messages`,{
                        state:{
                          message:forMessage?`Menu_Conversation_Samll_Size`:`Menu_Conversation`,
                      }
                      })
                      handleClose()
                  }}>View all</Button>
                  </Box>
             <Box  className="scroll-div-dropdown" sx={{borderLeft:"4px solid #e3a404"}}>
                {Notification.map((e,index)=>{
                      const object ={}
                              
                      if(e.first_user != JSON.parse(localStorage.getItem("user")).id){
                          object["image"]=e.first_photo
                          object["name"]=e.first_name
                          object["id"]=e.first_user
                          object["conversation_id"]=e.id 
                          object["message"]=e.lastMessage?e.lastMessage.message:"attatchment"
                          object["date"]=dateFormat(e.created_at,"dd mm yyyy")
                      
                      }else{
                          object["image"]=e.second_photo
                          object["name"]=e.second_name
                          object["id"]=e.second_user
                          object["conversation_id"]=e.id 
                          object["message"]=e.lastMessage?e.lastMessage.message:"attatchment"
                          object["date"]=dateFormat(e.created_at,"dd mm yyyy")
                        
                      }
                      return <>
                      
                      {<Box style={{position:"relative"}} key={object.name+"_Notification"} >    
                          <Box sx={{display:"flex",
                          //  padding:"4px 10px 8px 10px",
                          
                          fontSize:"12px",
                          transition:"0.2s ease-in",
                                "&:hover":{
                                  cursor:"pointer",
                                  background:"rgb(192 192 192 / 39%)"
                                }
                            }} onClick={()=>{
                                
                                navigate(`/messages/${object.id}`,{
                                  state:{
                                    message:`Out`,
                                }
                                })
                                handleClose()
                            }}>
                              <Box sx={{minWidth:"20%",maxWidth:"20%",width:"20%",padding:"5px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                  <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}><Box>
                                    <Avatar alt={object.name} sx={{width:50,height:50}} src={object.image} />
                                    
                                  </Box></div> 
                                  
                              </Box>
                              <Box sx={{minWidth:"80%",maxWidth:"80%",width:"80%",padding:"5px"}}>
                                <Typography sx={{fontSize:"15px"}}>
                                {object.name}
                                
                              </Typography>
                            
                              
                              <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                <Box sx={{color:"grey",fontSize:"12px"}} >

                                    {(JSON.parse(localStorage.getItem("user")).id == e.first_user) && "you: "}
                                    {object.message?<span dangerouslySetInnerHTML={{__html:object.message.substring(40,0)+"..."}}></span>:"attachment"}
                                </Box>
                                <Box >
                                  <div style={{display:"flex",padding:"3px"}}>
                                    <div style={{display:"flex",justifyContent:"center",fontSize:"10px",color:"#d80621",fontWeight:"500"}}>{object.date}</div>
                                    {/* <div style={{display:"flex",justifyContent:"center",fontSize:"11px",color:"#d80621"}}> 	&nbsp; at	&nbsp; </div>
                                    <div style={{display:"flex",justifyContent:"center",fontSize:"11px",color:"#d80621"}}>{object.date}</div> */}
                                    
                                  </div>
                                </Box>
                              </Box>
                              </Box>
                          </Box>
                        
                      
                  </Box>}
                      </>
                      
                  })}
             </Box>
              </Box>))
          :""}
          
         


        </Menu>}
      </React.Fragment>
    );

  }

const mapStateToProps = (state)=>{
  return{
    lang:state.lang,
    notification_info_redux:state.notification,
    conversation_id_redux:state.conversation_id,
    message_redux:state.message
  }
}
const mapDispatchToState = (dispatch)=>{
  return {
    logout : (name,photo) => {dispatch(login(name,photo))},
    notification_redux : (notification, conversation_id,message) =>{dispatch(echo_info(notification, conversation_id,message))}
    
  }
}
export default connect(mapStateToProps,mapDispatchToState)(MessageMenu)